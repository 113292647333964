import React, { useContext, useState } from "react";

import {
  AiFillYoutube,
  AiOutlineHome,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";
import { HiMenuAlt3 } from "react-icons/hi";
import { IoLogoGameControllerA } from "react-icons/io";
import { MdAdminPanelSettings, MdOutlineRateReview } from "react-icons/md";
import { Link, NavLink, useNavigate } from "react-router-dom";
import images from "../../../assets/images";
import {
  GiBallPyramid,
  GiCharacter,
  GiConsoleController,
} from "react-icons/gi";
import {
  BiCategory,
  BiHistory,
  BiLogOutCircle,
  BiNews,
  BiTimeFive,
  BiUserCheck,
} from "react-icons/bi";
import { FaRegBuilding } from "react-icons/fa";
import { TbBoxMultiple8 } from "react-icons/tb";
import { BsCalendar4Event } from "react-icons/bs";
import { AdminContext } from "../../AdminAuth/AdminAuth";
import SendData from "../../../hooks/useSendData";
import Cookies from "js-cookie";
import { FiVideo } from "react-icons/fi";

const Sidebar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const activeLink =
    "flex justify-start items-center gap-3 text-xl text-primary mx-5 link_hover relative after:absolute after:w-[3px] after:h-full after:-right-2 after:top-0 after:bg-primary";
  const normalLink =
    "flex justify-start items-center gap-3 text-xl mx-5 link_hover";

  const { createData, createError, createLoading, post } = SendData();
  const { dispatch } = useContext(AdminContext);
  const handleLogOut = async () => {
    try {
      // Send a request to the API to log out the admin
      // await post("https://gamewebsite.xyz/api/admin/logout", null);

      // Clear the token cookie
      Cookies.remove("token");

      // Dispatch the "LOGOUT" action
      dispatch({ type: "LOGOUT" });
      navigate("/");
    } catch (error) {
      // Handle any errors that occur during the logout process
      console.error("Logout error:", error);
    }
  };
  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className="fixed top-12 z-50 md:right-96 right-[5%] -translate-x-[50%] md:-translate-x-[0] text-2xl xl:hidden block"
      >
        <HiMenuAlt3 />
      </button>
      <div
        className={
          isOpen
            ? "top-0 fixed block xl:hidden duration-300 left-0 w-full h-full bg-backgroundTerinary bg-opacity-50  bg-clip-padding blur__effect z-30"
            : ""
        }
        onClick={handleClick}
      ></div>

      <div
        className={
          isOpen
            ? "flex__start flex-col h-[100vh] overflow-y-auto bg-backgroundSecondary flex-[0.2] gap-4 border-l-secondary border-r-[1px] xl:sticky fixed top-0 w-[280px]  z-50 right-0  duration-500"
            : "flex__start flex-col bg-backgroundSecondary flex-[0.2] gap-4 border-l-secondary border-r-[1px] fixed xl:sticky top-0 z-50 w-[250px] h-[100vh] overflow-y-auto -right-[100%] duration-500"
        }
      >
        <div className=" border-b-[1px] w-full flex__center border-b-primary py-3">
          <Link to="/" className="w-[90px] lg:w-[140px] h-auto block">
            <img src={images.logo} alt="w-full h-full" />
          </Link>
        </div>
        <ul className="flex__start flex-col gap-4 w-full">
          <li className="w-full">
            <NavLink
              onClick={handleClick}
              title="main page"
              to="/admin/dashboard"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <AiOutlineHome />
              <span>سەرەکی</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/games"
              title="games"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <IoLogoGameControllerA />
              <span>یاریەکان</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              title="staff"
              to="/admin/staff"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <AiOutlineUsergroupAdd />
              <span>ستاف</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/characters"
              title="characters"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <GiCharacter />
              <span>کەسایەتیەکان</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              title="details and top"
              to="/admin/detail"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <GiBallPyramid />
              <span>وردەکاری و بەرزترین</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/review"
              title="review and dive"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <MdOutlineRateReview />
              <span>قوڵبوونەوە و پێداچوونەوە</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/news"
              title="News"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <BiNews />
              <span>هەواڵ</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/genre"
              title="genre"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <BiCategory />
              <span>ژانرا</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/console"
              title="console"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <GiConsoleController />
              <span>کونسوڵ</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/company"
              title="company"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <FaRegBuilding />
              <span>کۆمپانیا</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/youtuber"
              title="youtuber"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <AiFillYoutube />
              <span>یوتوبەر</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/coming-soon"
              title="coming-soon"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <BiTimeFive />
              <span>بەمزوانە</span>
            </NavLink>
          </li>
          {/* <li className="w-full">
            <NavLink
              to="/admin/games-history"
              title="games-history"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <BiHistory />
              <span>مێژووی یاری</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <TbBoxMultiple8 />
              <span>زنجیرە یاری</span>
            </NavLink>
          </li> */}
          <li className="w-full">
            <NavLink
              to="/admin/events"
              title="events"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <BsCalendar4Event />
              <span>بۆنەکان</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/game-videos"
              title="game videos"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <FiVideo />
              <span>ڤیدیۆی یاریەکان</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/users"
              title="users"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <BiUserCheck />
              <span>بەکارهێنەر</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/admins"
              title="admins"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <MdAdminPanelSettings />
              <span>ئەدمین</span>
            </NavLink>
          </li>
          <li className="w-full">
            <button
              type="button"
              onClick={handleLogOut}
              className="flex justify-start items-center w-full gap-3 text-xl mx-5 link_hover pb-2"
            >
              <BiLogOutCircle />
              <span>چوونە دەرەوە</span>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
