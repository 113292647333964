import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const SendData = () => {
  const [createData, setData] = useState(null);
  const [createError, setCreateError] = useState(null);
  const [createLoading, setLoading] = useState(false);
  const token = Cookies.get("token");
  const post = async (url, formData, headers = {}) => {
    try {
      setLoading(true);

      const config = {
        headers: {
          ...headers,
          // Set the Content-Type header for FormData
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };

      const res = await axios.post(url, formData, config);

      setData(res.data);
      setCreateError(null);
    } catch (error) {
      setCreateError(error);
      throw error; // Re-throw the error so it's caught in the handleSubmit function
    } finally {
      setLoading(false);
    }
  };

  return { createData, createError, createLoading, post };
};

export default SendData;
