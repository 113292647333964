import React from "react";
import { Link } from "react-router-dom";

const StaffCard = ({
  role,
  img,
  name,
  link,
  roleText,
  sum,
  roleSecondary,
  key,
}) => {
  return (
    <>
      <div
        className="flex__center flex-col w-[280px] rounded-md relative text-black"
        key={key}
      >
        <Link
          to={link}
          className="w-32 h-32 rounded-full overflow-hidden z-10 bg-backgroundSecondary"
        >
          <img src={img} alt={name} className="w-full h-full object-cover" />
        </Link>
        <div className="flex__center flex-col gap-2 bg-primary w-full px-0 pb-4 pt-16 absolute rounded-xl top-16">
          <Link to={link} className="change_font text-xl">
            {name}
          </Link>
          <span>{roleText}</span>
          <span>{roleSecondary}</span>
          {/* <div className="flex__center gap-1">
            <span>کۆی بابەتەکان:</span>
            <span className="change_font">{sum}</span>
          </div> */}
          <Link
            to={link}
            className="border border-black px-4 py-1 sm:text-base text-sm rounded"
          >
            زیاتر بخوێنەوە
          </Link>
        </div>
      </div>
    </>
  );
};

export default StaffCard;
