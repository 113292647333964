import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import SendData from "../../../hooks/useSendData";
import { CreatedWarning, Navbar } from "../../admin-components";
import { MdReportGmailerrorred } from "react-icons/md";
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
import images from "../../../assets/images";
import { ButtonLoading, Loading } from "../../../components";
import { API_URL, IMG_URL } from "../../../env";

const UpdateYoutuber = () => {
  const { id } = useParams();
  const {
    data: youtuberData,
    loading: youtuberLoading,
    error: youtuberError,
  } = useFetch(`${API_URL}youtubers/view/${id}`);

  const { createData, createError, createLoading, post } = SendData();

  const { data, loading, error, refetch } = useFetch(`${API_URL}staff/list`);
  const [getData, setGetData] = useState([]);
  useEffect(() => {
    if (!loading && data?.data?.length > 0) {
      setGetData(data.data);
    }
  }, [loading, getData, data]);

  const [img, setImg] = useState("");
  const [imgBg, setImgBg] = useState("");

  const [isSent, setIsSent] = useState(false);

  const defaultFormData = {
    name: "",
    description: "",
    background: "",
    channel: "",
    facebook_link: "",
    youtube_link: "",
    started_at: "",
    popularity: "",
    logo: "",
    staff_id: "",
  };

  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    if (!youtuberLoading && youtuberData?.data) {
      // console.log(youtuberData.data);
      // Update formData with data when available
      setFormData({
        name: youtuberData?.data.name || "", // Default name value
        description: youtuberData?.data.description || "", // Default description value
        channel: youtuberData?.data.channel || "", // Default channel value
        background: "", // Leave empty for the image field, or set it to a default value if needed
        logo: "", // Leave empty for the image field, or set it to a default value if needed
        started_at: youtuberData?.data.started_at || "", // Default started_at value
        facebook_link: youtuberData?.data.facebook_link || "", // Default facebook_link value
        youtube_link: youtuberData?.data.youtube_link || "", // Default youtube_link value
        popularity: youtuberData?.data.popularity || "", // Default popularity value
        staff_id: youtuberData?.data?.staff?.id || "", // Default staff value
      });
    }
  }, [youtuberData, youtuberLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("name", formData.name);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("started_at", formData.started_at);

    formDataToSend.append("background", formData.background);
    formDataToSend.append("logo", formData.logo);
    formDataToSend.append("channel", formData.channel);
    formDataToSend.append("youtube_link", formData.youtube_link);

    formDataToSend.append("popularity", formData.popularity);
    formDataToSend.append("facebook_link", formData.facebook_link);
    formDataToSend.append("staff_id", formData.staff_id);

    try {
      await post(`${API_URL}youtubers/update/${id}`, formDataToSend);
    } catch (error) {}
    setIsSent(true);
  };
  useEffect(() => {
    if (createError) {
    }
  }, [createError]);
  if (youtuberLoading || !youtuberData.data) {
    return <Loading />;
  } else {
    return (
      <>
        <Navbar
          exactPlace="نوێکردنەوەی یوتوبەر"
          secondPlace="یوتوبەر"
          secondPlaceLink="/admin/youtuber"
        />

        <div className="flex__center flex-col w-full xl:flex-[0.8] mb-10 flex-1 mt-28 gap-8">
          <div className="flex__center md:flex-row flex-col md:gap-10 gap-5 w-full">
            <div className="flex__start flex-col gap-3 sm:w-auto w-full">
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="name" className=" text-lg">
                  ناو
                </label>
                <input
                  type="text"
                  id="name"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formData.name}
                  onChange={handleChange}
                  name="name"
                />
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="channel" className=" text-lg">
                  ناوی چەناڵ
                </label>
                <input
                  type="text"
                  id="channel"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formData.channel}
                  onChange={handleChange}
                  name="channel"
                />
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="youtube_link" className=" text-lg">
                  لینکی چەناڵ
                </label>
                <input
                  type="text"
                  id="youtube_link"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formData.youtube_link}
                  onChange={handleChange}
                  name="youtube_link"
                />
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="img" className=" text-lg">
                  لۆگۆ
                </label>
                <input
                  type="file"
                  id="img"
                  disabled={false}
                  className="hidden"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      logo: e.target.files[0],
                    });
                    setImg(e.target.files[0]);
                  }}
                  name="logo"
                />
                <div className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent flex items-center justify-between">
                  <div className="mx-3">
                    {img.name || youtuberData.data.logo}
                  </div>
                  <label
                    htmlFor="img"
                    className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                  >
                    <AiOutlineCloudUpload />
                  </label>
                </div>
              </div>
            </div>
            <label
              htmlFor="img"
              className="w-[200px] h-[200px] bg-backgroundSecondary rounded-full overflow-hidden"
            >
              <img
                src={
                  img
                    ? URL.createObjectURL(img)
                    : `${IMG_URL}youtubers/logo/${youtuberData.data.logo}`
                }
                alt="img"
                className="w-full h-full object-cover"
              />
            </label>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="popularity">ڕیزبەندی</label>
              <input
                type="text"
                id="popularity"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[350px] w-full bg-transparent px-2"
                value={formData.popularity}
                onChange={handleChange}
                name="popularity"
              />
            </div>
            <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="background">وێنەی باکگراوند</label>
              <input
                type="file"
                id="background"
                name="background"
                onChange={(e) => {
                  setFormData({ ...formData, background: e.target.files[0] });
                  setImgBg(e.target.files[0]);
                }}
                className="hidden"
              />
              <div className="outline-none border-slate-300 border rounded h-11 sm:w-[350px] w-full bg-transparent flex items-center justify-between">
                <div className="mx-3 whitespace-nowrap text-ellipsis w-full overflow-hidden">
                  {imgBg.name || youtuberData.data.background}
                </div>
                <label
                  htmlFor="background"
                  className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                >
                  <AiOutlineCloudUpload />
                </label>
              </div>
            </div>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="facebook_link">فەیسبووک</label>
              <input
                id="facebook_link"
                name="facebook_link"
                value={formData.facebook_link}
                onChange={handleChange}
                className="outline-none border-slate-300 border rounded h-11 sm:w-[230px] w-full bg-transparent flex items-center justify-between px-2"
              />
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="started_at">کاتی دەستپێکردنی</label>
              <input
                type="date"
                id="started_at"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
                value={formData.started_at}
                onChange={handleChange}
                name="started_at"
              />
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="staff_id">لەلایەن</label>
              <select
                name="staff_id"
                id="staff_id"
                onChange={handleChange}
                className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[230px] w-full p-2.5 bg-backgroundSecondary"
                value={formData.staff_id}
              >
                {getData.map((staff) => (
                  <option value={staff.id}>{staff.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="textarea">پێناسەکردنی</label>
            <textarea
              name="description"
              id="textarea"
              value={formData.description}
              onChange={handleChange}
              className="outline-none border-slate-300 border rounded h-32 md:w-[720px] sm:w-[450px] w-full bg-transparent px-2"
            />
          </div>
          <button
            onClick={handleSubmit}
            type="submit"
            disabled={createLoading}
            className={`sm:w-[450px] w-[90%] relative h-[45px] bg-backgroundSecondary text-xl py-2 hover:bg-primary hover:text-black duration-300 rounded-md ${
              createLoading ? "disabled" : ""
            }`}
          >
            {!createLoading && <span>ناردن</span>}
            {createLoading && <ButtonLoading />}
          </button>
        </div>
        {isSent === true && !createError && (
          <CreatedWarning
            icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
            message={"بابەتەکە بەسەرکەوتوویی نوێکراوە"}
          />
        )}
        {createError && createError.response && (
          <CreatedWarning
            icon={<MdReportGmailerrorred className="text-9xl text-red" />}
            message={"تکایە لە دڵنیاببەوە هەموو زانیاریەکانت بەڕاستی داناوە"}
            moreInfo={
              createError && createError.response
                ? createError.response.data.message
                : "An error occurred"
            }
          />
        )}
      </>
    );
  }
};

export default UpdateYoutuber;
