import React from "react";
import { Link } from "react-router-dom";

import images from "../../assets/images";

const NotFound = () => {
  return (
    <>
      <div className="flex__center w-full sm:mt-0 mt-20 max-w-[1250px] xl:px-0 px-3 h-[100vh]">
        <div className="flex__center sm:flex-row flex-col sm:w-[90%] w-full flex-shrink-0 lg:gap-20 gap-5">
          <div className="lg:w-[400px] md:w-[300px] sm:w-[400px] w-[300px] h-auto overflow-hidden rounded-md">
            <img src={images.ghost} className="w-full h-full" alt="Ghost" />
          </div>
          <div className="flex__start sm:auto w-[300px] flex-col gap-5">
            <h1 className="text-4xl">هەڵەی 404</h1>
            <p className="lg:w-[350px] w-auto text-lg">
              بۆ شوێنێکی هەڵە هاتووی ئەم پەڕە نەدۆزرایەوە لە وێبسایتدا تکایە
              دڵنیا ببەوە لە لینکی وێبسایت
            </p>
            <Link
              to="/"
              className="bg-gradient-to-r from-[#ffae00af] to-[#fff200] text-black p-3 rounded-md"
            >
              گەڕانەوە بۆ پەرەی سەرەتا
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
