import React from "react";

const KurdGameEvent = () => {
  return (
    <section className="section">
      <div className="w-full max-w-[1250px] xl:px-0 px-3 flex__center flex-col gap-10 mb-10">
        <div className="">Kg awards</div>
      </div>
    </section>
  );
};

export default KurdGameEvent;
