import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import animation from "../../assets/animation/animation.json";

const Loading = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animation,
      renderer: "svg", // or 'canvas'
      loop: true,
      autoplay: true,
    });

    return () => {
      // Clean up the animation when the component unmounts
      anim.destroy();
    };
  }, []); // Empty dependency array ensures the effect runs only once
  return (
    <>
      <div className="fixed w-full bg-black h-full left-0 top-0 z-50">
        <div
          className="absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]"
          ref={animationContainer}
          style={{ width: "200px", height: "200px" }}
        />
      </div>
    </>
  );
};

export default Loading;
