import React from "react";
import { Link } from "react-router-dom";

const GenreCard = ({ link, title, img, desc }) => {
  return (
    <>
      <Link
        title={title}
        to={link}
        className="sm:w-[390px] w-full flex__center gap-4 rounded-2xl relative  p-3 backdrop-filter backdrop-blur-lg h-[175px]"
      >
        <div
          title="title"
          className="w-full absolute top-0 left-0 rounded-md overflow-hidden h-full flex-shrink-0"
        >
          <img src={img} alt="joel" className="w-full h-full" />
        </div>
        <div className="absolute w-full h-full top-0 left-0 bg-black bg-opacity-50"></div>
        <div className="flex__center gap-5 flex-col z-10">
          <div className="link_hover text-2xl" title={title}>
            {title}
          </div>
          <p className="text-slate-300 font-light text-sm">
            {" "}
            {desc.substring(0, 120)}...
          </p>
          <button className="primary-button">زیاتر بخوێنەوە</button>
        </div>
      </Link>
    </>
  );
};

export default GenreCard;
