import React, { useState } from "react";
import { AiFillHeart, AiOutlineCheckCircle } from "react-icons/ai";
import RatingGameDetails from "./RatingGameDetails";
import { Link } from "react-router-dom";
import SendData from "../../../../hooks/useSendData";
import { CreatedWarning } from "../../../../admin/admin-components";
import { MdReportGmailerrorred } from "react-icons/md";
import useFetch from "../../../../hooks/useFetch";
import { ButtonLoading } from "../../../../components";
import { MdClose } from "react-icons/md";
import useDelete from "../../../../hooks/useDelete";
import { API_URL } from "../../../../env";
import axios from "axios";
import Cookies from "js-cookie";

const FavAndStaffGameDetails = ({
  translators,
  creators,
  designers,
  id,
  isTranslated,
}) => {
  const { data, loading, refetch } = useFetch(
    `${API_URL}auth/user/favorite/check/${id}`
  );
  const token = Cookies.get("token");
  const { createError, createLoading, post } = SendData();
  const { deleteRequest, deleteLoading } = useDelete();
  const [isSent, setIsSent] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // await post(`${API_URL}auth/user/favorite/add`, { gameId: id });
      await axios.post(
        `${API_URL}auth/user/favorite/add`,
        { gameId: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsSent(true);
      refetch();
    } catch (error) {
      setIsSent(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteRequest(`${API_URL}auth/user/favorite/delete/${id}`);
      refetch();
    } catch (err) {}
  };

  if (
    translators === undefined ||
    designers === null ||
    creators === undefined ||
    isTranslated === null
  ) {
    return <h1>هەڵە ڕوویدا</h1>;
  }
  return (
    <>
      <div className="w-full flex__start md:flex-col sm:flex-row flex-col flex-wrap gap-5">
        {/* <div className="bg-backgroundSecondary lg:p-4 p-2 rounded-lg w-full flex__start flex-col gap-4">
          <RatingGameDetails id={id} />
        </div> */}
        <div className="bg-backgroundSecondary lg:p-4 p-2 rounded-lg w-full flex__start flex-col gap-3">
          <h3 className="text-xl">بەشداربوان</h3>
          {isTranslated == 1 && (
            <div className="w-full justify-start gap-2 items-center flex">
              <h3 className="w-[70px]">وەرگێڕان:</h3>
              <div className="flex__start overflow-x-auto gap-1 w-[60%] flex-shrink-0">
                {translators.map((translator, i) => (
                  <div
                    key={i}
                    className="flex items-center sm:gap-4 gap-1 flex-shrink-0"
                  >
                    <Link
                      to={`/staff/${translator.id}`}
                      title={translator.name}
                    >
                      <img
                        className="w-10 h-10 rounded-full bg-background flex-shrink-0 object-cover"
                        src={translator.poster}
                        alt={translator.name}
                      />
                    </Link>
                    <div className="font-medium change_font flex__start flex-col ">
                      <Link
                        to={`/staff/${translator.id}`}
                        title={translator.name}
                        className="sm:text-sm text-xs"
                      >
                        {translator.name}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="w-full justify-start gap-2 items-center flex">
            <h3 className="w-[70px]">دیزاین:</h3>
            <div className="flex__start overflow-x-auto gap-1 w-[60%] flex-shrink-0">
              {designers.map((designer, i) => (
                <div
                  key={i}
                  className="flex items-center sm:gap-4 gap-1 flex-shrink-0"
                >
                  <Link to={`/staff/${designer.id}`} title={designer.name}>
                    <img
                      className="w-10 h-10 rounded-full bg-background flex-shrink-0 object-cover"
                      src={designer.poster}
                      alt={designer.name}
                    />
                  </Link>
                  <div className="font-medium change_font flex__start flex-col ">
                    <Link
                      to={`/staff/${designer.id}`}
                      title={designer.name}
                      className="sm:text-sm text-xs"
                    >
                      {designer.name}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {creators.length > 1 && (
            <div className="w-full justify-start gap-2 items-center flex">
              <h3 className="w-[70px]">ئامادەکردن:</h3>
              <div className="flex__start overflow-x-auto gap-1 w-[60%] flex-shrink-0">
                {creators.map((creator, i) => (
                  <div
                    key={i}
                    className="flex items-center sm:gap-4 gap-1 flex-shrink-0"
                  >
                    <Link to={`/staff/${creator.id}`} title={creator.name}>
                      <img
                        className="w-10 h-10 rounded-full bg-background flex-shrink-0 object-cover"
                        src={creator.poster}
                        alt={creator.name}
                      />
                    </Link>
                    <div className="font-medium change_font flex__start flex-col ">
                      <Link
                        to={`/staff/${creator.id}`}
                        title={creator.name}
                        className="sm:text-sm text-xs"
                      >
                        {creator.name}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {data.favorite != true && !loading && (
          <button
            type="button"
            onClick={handleSubmit}
            className="lg:text-lg text-sm relative w-full flex items-start justify-center gap-4 bg-primary text-black rounded-2xl px-2 py-3 h-[45px]"
          >
            {!createLoading && <span>زیادی کە بۆ دڵخوازەکانم</span>}
            {!createLoading && <AiFillHeart fontSize={24} />}
            {createLoading && <ButtonLoading />}
          </button>
        )}
        {data.favorite == true && !loading && (
          <button
            type="button"
            onClick={handleDelete}
            className="lg:text-lg text-sm relative w-full flex items-start justify-center gap-4 bg-[#ff2e2e3b] text-white rounded-2xl px-2 py-3 h-[45px]"
          >
            {!deleteLoading && <span>لە دڵخوازەکانم لایدە</span>}
            {!deleteLoading && <MdClose fontSize={24} />}
            {deleteLoading && <ButtonLoading />}
          </button>
        )}
      </div>
      {isSent && (
        <CreatedWarning
          icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
          message={"بە سەرکەوتوویی زیادکرا بۆ دڵخوازەکانت"}
        />
      )}
      {createError && createError.response && (
        <CreatedWarning
          icon={<MdReportGmailerrorred className="text-9xl text-red" />}
          message={
            "پێوسیتە خۆت تۆماربکەیت بۆ ئەوەی یاریەکە زیاد بکەیت بۆ بەشی دڵخوازت"
          }
        />
      )}
    </>
  );
};

export default FavAndStaffGameDetails;
