import React from "react";

const TopAndDetails = ({ rank, img, title, info }) => {
  return (
    <>
      <div className="w-full flex__start flex-col border-b border-b-backgroundSecondary pb-5">
        <div className="flex__center">
          <h3 className="change_font bg-primary text-black px-8 py-3 text-3xl">
            {rank}
          </h3>
          <p className="px-5 sm:text-xl text-lg">{title}</p>
        </div>
        <img
          src={img}
          className="w-full md:h-[400px] h-auto rounded-md"
          alt={title}
        />
        <p className="w-full text-gray-300 mt-5 sm:text-lg text-base">{info}</p>
      </div>
    </>
  );
};

export default TopAndDetails;
