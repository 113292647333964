export const stackData = [
  {
    name: "January",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "February",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "March",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "April",
    uv: 4000,
    pv: 11398,
    amt: 210,
  },
  {
    name: "June",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "May",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Jule",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "August",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "September",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "October",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "November",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "December",
    uv: 3490,
    pv: 4300,
    amt: 3100,
  },
];

export const sparkData = [
  {
    name: "Sat",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Sun",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Mon",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Tue",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Wed",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Thu",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Fri",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export const userColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="flex__center gap-4">
          <img
            src={params.row.img}
            className="w-[32px] h-[32px] rounded-full object-cover"
            alt=""
          />
          {params.row.username}
        </div>
      );
    },
  },
  { field: "email", headerName: "Email", width: 230 },
  { field: "phone", headerName: "Phone Number", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div
          className={`${
            params.row.status === "active" ? "bg-green" : "bg-red"
          } px-3 py-2 rounded-md bg-opacity-50 !text-white`}
        >
          {params.row.status}
        </div>
      );
    },
  },
];

export const userRows = [
  {
    id: 1,
    username: "Snow",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    status: "active",
    email: "1snow@gmail.com",
    phone: "07504444444",
  },
  {
    id: 2,
    username: "Jamie Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "2snow@gmail.com",
    status: "passive",
    phone: "07504444444",
  },
  {
    id: 3,
    username: "Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "3snow@gmail.com",
    status: "active",
    phone: "07504444444",
  },
  {
    id: 4,
    username: "Stark",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "4snow@gmail.com",
    status: "active",
    phone: "07504444444",
  },
  {
    id: 5,
    username: "Targaryen",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "5snow@gmail.com",
    status: "passive",
    phone: "07504444444",
  },
  {
    id: 6,
    username: "Melisandre",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "6snow@gmail.com",
    status: "active",
    phone: "07504444444",
  },
  {
    id: 7,
    username: "Clifford",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "7snow@gmail.com",
    status: "passive",
    phone: "07504444444",
  },
  {
    id: 8,
    username: "Frances",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "8snow@gmail.com",
    status: "active",
    phone: "07504444444",
  },
  {
    id: 9,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "passive",
    phone: "07504444444",
  },
  {
    id: 10,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "active",
    phone: "07504444444",
  },
  {
    id: 11,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "active",
    phone: "07504444444",
  },
  {
    id: 12,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "active",
    phone: "07504444444",
  },
  {
    id: 13,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "active",
    phone: "07504444444",
  },
  {
    id: 14,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "active",
    phone: "07504444444",
  },
  {
    id: 15,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "active",
    phone: "07504444444",
  },
  {
    id: 16,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "active",
    phone: "07504444444",
  },
  {
    id: 17,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "active",
    phone: "07504444444",
  },
];
export const itemColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "food",
    headerName: "Food",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="flex__center gap-4">
          <img
            src={params.row.img}
            className="w-[32px] h-[32px] rounded-full object-cover"
            alt=""
          />
          {params.row.title}
        </div>
      );
    },
  },
  { field: "category", headerName: "Category", width: 230 },
  { field: "price", headerName: "Price", width: 70 },
];

export const itemRows = [
  {
    id: 1,
    title: "Pizza",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "pizza",
    price: "20",
  },
  {
    id: 2,
    title: "italian pizza",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "burger",

    price: "25",
  },
  {
    id: 3,
    title: "pasta",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "cupcake",
    price: "10",
  },
  {
    id: 4,
    title: "green pasta",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "ramen",
    price: "5",
  },
  {
    id: 5,
    title: "chwzanm",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "ramen",

    price: "8",
  },
  {
    id: 6,
    title: "xwardn",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "pizza",
    price: "13",
  },
  {
    id: 7,
    title: "xwardneki tr",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "icecream",

    price: "20",
  },
  {
    id: 8,
    title: "Frances",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "pizza",
    price: "20",
  },
  {
    id: 9,
    title: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "pizza",

    price: "20",
  },
  {
    id: 10,
    title: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "pizza",
    price: "20",
  },
  {
    id: 11,
    title: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "pizza",
    price: "20",
  },
  {
    id: 12,
    title: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "pizza",
    price: "20",
  },
  {
    id: 13,
    title: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "pizza",
    price: "20",
  },
  {
    id: 14,
    title: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "pizza",
    price: "20",
  },
  {
    id: 15,
    title: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "pizza",
    price: "20",
  },
  {
    id: 16,
    title: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "pizza",
    price: "20",
  },
  {
    id: 17,
    title: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    category: "pizza",
    price: "20",
  },
];

// category data
export const category = ["pizza", "burger", "icecream"];

// staff column

export const staffColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: "Name",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="flex__center gap-4">
          <img
            src={params.row.img}
            className="w-[32px] h-[32px] rounded-full object-cover"
            alt=""
          />
          {params.row.username}
        </div>
      );
    },
  },
  { field: "email", headerName: "Email", width: 230 },
  { field: "phone", headerName: "Phone Number", width: 230 },
];
