import React from "react";
import { BsDot } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AiFillEye, AiFillStar } from "react-icons/ai";

const GamePoster = ({ title, img, genre, link, rate, views, key }) => {
  genre = JSON.parse(genre);
  // console.log(genre);

  return (
    <>
      <div className="flex__center sm:w-[220px] w-[48%] h-auto flex-col gap-0 rounded-md poster">
        <Link
          title={title}
          key={key}
          to={link}
          className="w-full h-auto rounded-lg overflow-hidden relative"
        >
          <img src={img} className="w-full h-[300px] poster-img" alt={title} />
          <div className="absolute flex-col duration-300 poster-content opacity-0 p-3 top-0 left-0 w-full h-full backdrop-blur flex justify-between items-start">
            <div className="flex__center gap-1">
              {rate} <AiFillStar className="text-xl text-primary" />
            </div>
            <h2 className="w-full text-center primary-button">بینینی زیاتر</h2>
            <div className="flex__center gap-1">
              {views} <AiFillEye className="text-xl" />
            </div>
          </div>
        </Link>
        <Link
          to={link}
          className="sm:text-xl text-base font-medium w-full text-ellipsis overflow-hidden change_direction whitespace-nowrap text-center change_font link_hover mt-2"
        >
          {title}
        </Link>
        <div className="flex__center gap-1 w-full overflow-hidden">
          {genre.map((g, i) => (
            <>
              <span className="max-w-[12ch] text-ellipsis overflow-hidden whitespace-nowrap sm:text-base text-sm">
                {g.title}
              </span>
              {i < genre.length - 1 && (
                <BsDot className="text-[#a4a4a4] text-xl" />
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default GamePoster;
