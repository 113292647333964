import logo from "./image/logo.png";
import ghost from "./image/ghost.jpg";
import google from "./image/google.png";
import detail from "./image/detail.png";
import console from "./image/console.png";
import youtuber from "./image/youtuber.png";
import top from "./image/top.png";
import company from "./image/company.png";
import dive from "./image/dive.png";
import gameHistory from "./image/game-history.png";
import gameSeries from "./image/game-series.png";
import comingSoon from "./image/coming-soon.png";
import event from "./image/event.png";
import genre from "./image/genre.png";
import recommend from "./image/recommend.png";
import playstationLogo from "./image/playstation-logo.png";
import nintendoLogo from "./image/nintendo-logo.png";
import xboxLogo from "./image/xbox-logo.png";
import ghostProfile from "./image/ghost-profile.png";
import noImg from "./image/no-image.jpg";
import auth from "./image/auth.png";
import mario from "./image/mario.png";
import cover from "./image/cover.png";
import sega from "./image/sega.svg";
import ad from "./image/ad.png";

const images = {
  logo,
  auth,
  ad,
  detail,
  ghostProfile,
  playstationLogo,
  noImg,
  xboxLogo,
  gameSeries,
  gameHistory,
  dive,
  nintendoLogo,
  // test,
  youtuber,
  top,
  cover,
  company,
  comingSoon,
  event,
  genre,
  recommend,
  console,
  sega,
  ghost,
  google,
  mario,
};

export default images;
