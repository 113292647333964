import React, { useState } from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";

const PosterCharacter = ({ poster, characters, id }) => {
  const [character, setCharacter] = useState(true);
  const characterMore = () => {
    setCharacter(!character);
  };
  if (characters === undefined) {
    return <p>Loading...</p>;
  } else {
    return (
      <>
        <div
          className="lg:w-[300px] md:w-[220px] w-[300px] lg:h-[420px] h-auto rounded-md overflow-hidden"
          key={id}
        >
          <img src={poster} className="w-full h-full" alt="title" />
        </div>
        <div className="flex__start p-3 gap-2 bg-backgroundSecondary rounded-md lg:w-[300px] md:w-[220px] w-[300px] flex-col">
          <h3 className="text-3xl pb-4">کەسایەتیەکان</h3>
          <div
            className={`flex__start w-full duration-300 transition-all overflow-hidden flex-col gap-3 ${character ? "max-h-[210px]" : "max-h-auto"
              }`}
          >
            {characters.map((char) => (
              char.id !== 28 ? (
                <Link
                  to={`/characters/${char.id}`}
                  key={char.id}
                  className="w-full flex justify-between items-start"
                >
                  <img
                    src={char.image}
                    className="lg:w-[60px] lg:h-[60px] w-[50px] h-[50px] rounded-full object-cover"
                    alt="title"
                  />
                  <div className="flex__start flex-col gap-1">
                    <h4 className="text-gray-400 link_hover">{char.k_name}</h4>
                    <h3 className="change_font link_hover">{char.name}</h3>
                  </div>
                  <HiOutlineArrowLeft className="link_hover lg:text-xl text-base" />
                </Link>
              ) : (
                <div key={char.id}>
                  <p>هیچ کەسایەتیەک دانەنراوە</p>
                </div>
              )
            ))}
          </div>
          {characters.length >= 4 && (
            <button
              onClick={characterMore}
              type="button"
              className="text-primary text-lg w-full text-center hover:text-secondary"
            >
              {character === true ? "بینینی زیاتر" : "بینینی زیاتر دابخە"}
            </button>
          )}
        </div>
      </>
    );
  }
};

export default PosterCharacter;
