import React from "react";
import { Link } from "react-router-dom";

const CharactersCard = ({ name, link, img, game, numGames, key }) => {
  return (
    <>
      <div
        key={key}
        className="relative flex__center rounded duration-300 hover:bg-backgroundTerinary flex-col sm:w-[220px] w-[48%] gap-2 sm:p-3 p-1 bg-backgroundSecondary"
      >
        <Link to={link} title="title" className="w-full h-[250px]">
          <img src={img} className="w-full h-full" alt="" />
        </Link>
        <Link
          to={link}
          className="md:text-xl text-base change_font link_hover font-bold text-ellipsis w-full whitespace-nowrap overflow-hidden"
        >
          {name}
        </Link>
        <div className="flex justify-between items-center gap-2 w-full">
          <span className="change_font sm:text-sm text-xs text-gray-300 text-ellipsis w-[10ch] whitespace-nowrap overflow-hidden">
            {game}
          </span>
          <span className="sm:text-sm text-xs text-gray-300">
            بەشداریەکان ({numGames})
          </span>
        </div>
      </div>
    </>
  );
};

export default CharactersCard;
