import React from "react";
import { Link } from "react-router-dom";

const ByWho = ({ imgPerson, title, link, time, changeText }) => {
  return (
    <>
      <div className="flex items-center sm:gap-4 gap-1 flex-shrink-0">
        {imgPerson && imgPerson != null && (
          <Link to={link} title={title}>
            <img
              className="w-10 h-10 rounded-full bg-background flex-shrink-0"
              src={imgPerson}
              alt={title}
            />
          </Link>
        )}
        <div className="font-medium change_font flex__start flex-col ">
          <Link
            to={link}
            title={title}
            className={` sm:text-sm text-xs ${
              changeText === "textBlack"
                ? "text-black"
                : "text-white link_hover"
            }`}
          >
            {title}
          </Link>
          <div
            className={`text-sm  ${
              changeText === "textBlack" ? "text-black" : "text-gray-500"
            }`}
          >
            {time}
          </div>
        </div>
      </div>
    </>
  );
};

export default ByWho;
