import React from "react";
import { Link } from "react-router-dom";

const NewsCard = ({
  person,
  time,
  img,
  text,
  linkPerson,
  link,
  title,
  tag,
}) => {
  return (
    <>
      <div className="sm:w-[290px] w-full flex-shrink-0 rounded-md overflow-hidden relative flex__center flex-col gap-2 bg-backgroundSecondary">
        <Link
          to={linkPerson}
          className="link_hover change_font text-sm absolute top-1 right-1 bg-gradient-to-r from-[#ffffff20] to-[#ffffff70] text-white px-2 py-1 rounded-md font-bold"
        >
          {person}
        </Link>
        <span className="link_hover change_font text-sm absolute top-1 left-1 bg-gradient-to-r from-[#ffffff20] to-[#ffffff70] text-white px-2 py-1 rounded-md font-bold">
          {time}
        </span>
        <Link to={link} className="w-full h-[200px]">
          <img src={img} alt="" className="w-full h-full" />
        </Link>
        <div className="flex__start flex-col gap-1 w-[90%] mb-2">
          <h2 className="text-primary change_font">{tag}</h2>
          <Link to={link} className="link_hover sm:text-lg text-base">
            {title}
          </Link>
          <p className="text-slate-300 font-light text-sm whitespace-normal">
            {text.substring(0, 60).trim()}...
          </p>
        </div>
      </div>
    </>
  );
};

export default NewsCard;
