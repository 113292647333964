import React from "react";
import { Link } from "react-router-dom";

const TopCard = ({ linkPerson, link, person, img, title }) => {
  return (
    <>
      <div className="sm:w-[400px] w-full rounded-md overflow-hidden relative flex__center flex-col gap-2 bg-backgroundSecondary">
        {person && (
          <Link
            title={person}
            to={linkPerson}
            className="link_hover change_font text-sm absolute top-1 right-1 bg-gradient-to-r from-[#ffffff20] to-[#ffffff70] text-white px-2 py-1 rounded-md font-bold"
          >
            {person}
          </Link>
        )}

        <Link to={link} className="w-full h-[240px]" title={title}>
          <img src={img} alt="" className="w-full h-full" />
        </Link>
        <div className="flex__start flex-col gap-2 w-[90%] mb-2">
          <Link to={link} className="link_hover text-lg">
            {title}
          </Link>
        </div>
      </div>
    </>
  );
};

export default TopCard;
