import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { API_URL, IMG_URL } from "../../../env";
import { Loading } from "../../../components";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
const Advertisements = () => {
  const { data, loading, error } = useFetch(`${API_URL}advertisements/all`);

  const [getData, setGetData] = useState([]);

  useEffect(() => {
    if (!loading && data && data.data && data.data.length > 0) {
      setGetData(data.data);
      // console.log(data);
    }
  }, [data, loading]);
  if (!loading || data) {
    return (
      <>
        <div className="w-full flex__center my-10 relative max-h-[400px]">
          <Swiper
            // pagination={{
            //   type: "progressbar",
            // }}

            modules={[Autoplay]}
            className="w-full max-w-[1250px] max-h-[400px] relative rounded-xl overflow-hidden"
            // loop={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: true,
            }}
          >
            {getData.map((ad, i) => (
              <SwiperSlide className="w-full h-full max-h-[400px]" key={i}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={ad.link}
                  className="w-full max-w-[1250px] rounded-lg overflow-hidden xl:px-0 px-3 relative max-h-[400px]"
                >
                  <img
                    src={`${IMG_URL}advertisements/${ad.image}`}
                    alt="advertisemnt"
                    className="w-full h-full object-cover"
                  />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </>
    );
  }
};

export default Advertisements;
