import React, { useEffect, useState } from "react";
import { BreadCrumb, ByWho, Loading } from "../../components";
import TopAndDetails from "../../components/TopAndDetail/TopAndDetails";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { API_URL } from "../../env";
import { IMG_URL } from "../../env";

const DetailsInfo = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(`${API_URL}details/view/${id}`);
  console.log(data);
  const dateFormat = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  if (loading || !data.data || !data?.data?.points) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section gap-10">
          <div className="w-full max-w-[1250px] xl:px-0 px-3 flex__start flex-col gap-10 mb-10 flex__start">
            {loading ? (
              "چاوەڕێکە..."
            ) : (
              <BreadCrumb
                exactPlace={data?.data?.result?.name}
                secondPlaceLink="/deatils"
                key={data?.data?.result?.id}
                secondPlace="وردەکاریەکان"
              />
            )}
          </div>
          {!loading && data ? (
            <div className="mb-10 flex-col lg:w-[1024px] w-full lg:px-0 px-3 flex__start gap-5">
              <div className="w-full flex justify-between gap-x-2 items-center border-b pb-5 border-b-backgroundSecondary">
                <h2 className="sm:text-2xl text-xl w-auto">
                  {data.data.result.title}
                </h2>
                <ByWho
                  imgPerson={
                    data?.data?.result?.staff?.image
                      ? `${IMG_URL}staff/poster/${data?.data?.result?.staff?.image}`
                      : null
                  }
                  link={`/staff/${data?.data?.result?.staff?.id}`}
                  time={dateFormat(data.data.result.created_at)}
                  title={data.data.result?.staff?.name}
                  key={data.data.result.id}
                />
              </div>
              {data.data.points?.map((point, i) => (
                <TopAndDetails
                  key={i}
                  img={`${IMG_URL}detail_points/${point.image}`}
                  info={point.description}
                  rank={point.number}
                  title={point.title}
                />
              ))}
            </div>
          ) : (
            <p>هەڵە ڕوویدا.</p>
          )}
        </section>
      </>
    );
  }
};

export default DetailsInfo;
