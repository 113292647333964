import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip } from "recharts";
import { sparkData } from "../../../constants/data";

const getIntroOfPage = (label) => {
  if (label === "Sat") {
    return "Page A is about men's clothing";
  }
  if (label === "Sun") {
    return "Page B is about women's dress";
  }
  if (label === "Mon") {
    return "Page C is about women's bag";
  }
  if (label === "Tue") {
    return "Page D is about household goods";
  }
  if (label === "Wed") {
    return "Page E is about food";
  }
  if (label === "Thu") {
    return "Page F is about baby food";
  }
  if (label === "Fri") {
    return "Page F is about baby food";
  }
};

const CustomTooltip = ({ payload, label, active }) => {
  if (active) {
    return (
      <div className="bg-[#f4f7fe] dark:bg-[#0b1437] bg-opacity-80 dark:bg-opacity-80 bg-clip-padding blur__effect p-2 rounded-lg">
        <p className="label">{`${label} : ${payload[0].value} visits`}</p>
        <p className="intro">{getIntroOfPage(label)}</p>
        <p className="desc">Anything you want can be displayed here.</p>
      </div>
    );
  }
  return null;
};

const SparkChart = () => {
  return (
    <div className="sm:w-[450px] sm:h-[300px] overflow-x-auto w-[320px]">
      {/* <ResponsiveContainer width="450px" height="300px"> */}
      <LineChart
        width={450}
        height={300}
        data={sparkData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          dataKey="pv"
          stroke="#2c0ba7"
          activeDot={{ r: 8 }}
          strokeWidth={5}
        />
        <Line
          type="monotone"
          dataKey="uv"
          strokeWidth={5}
          stroke="#0adf5c"
          activeDot={{ r: 8 }}
        />
      </LineChart>
      {/* </ResponsiveContainer> */}
    </div>
  );
};

//USE IT FOR SHWOING DATA AND ITEMS SEELLS AND PROFIT

export default SparkChart;
