import React, { useEffect, useState } from "react";
import images from "../../../assets/images";
import { Link } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { API_URL, IMG_URL } from "../../../env";

const SimilarNews = () => {
  const { data, loading, error } = useFetch(
    `${API_URL}news/all?perPage=10&page=1`
  );
  const [getData, setGetData] = useState([]);
  useEffect(() => {
    if (!loading && data && data.data && data.data.length > 0) {
      setGetData(data.data);
    }
  }, [data, loading]);
  const dateFormat = (date) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  // console.log(getData);
  return (
    <div className="lg:flex-[0.3] flex-1 bg-backgroundSecondary flex items-start justify-start flex-col gap-8 p-4 rounded-lg">
      <h3 className="text-lg w-full border-b-2 border-b-backgroundTerinary pb-3">
        هەواڵی تر
      </h3>
      {getData.map((news) => (
        <div className="flex justify-start items-start gap-2">
          <Link
            to={`/news/${news.id}`}
            title={news.title}
            className="w-20 h-20 shrink-0 rounded-md overflow-hidden"
          >
            <img
              src={`${IMG_URL}news/${news.image}`}
              alt={news.title}
              className="w-full h-full object-cover"
            />
          </Link>
          <div className="flex items-start justify-start flex-col flex-1">
            <Link
              to={`/news/${news.id}`}
              title={news.title}
              className="text-lg link_hover whitespace-nowrap"
            >
              {news.title.substring(0, 40)}...
            </Link>
            <div className="w-full flex items-start justify-between">
              <span className="text-gray-300 text-sm change_font">
                {dateFormat(news.created_at)}
              </span>
              <Link
                to={`/staff/${news?.staff_id}`}
                title={news?.staff_name}
                className="change_font link_hover text-gray-300 text-sm"
              >
                {news?.staff_name}
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SimilarNews;
