import React from "react";

import Header from "./Header/Header";
import Categories from "./Categories/Categories";
import GamesComponent from "./GamesComponent/GamesComponent";
import CharactersComponent from "./CharactersComponent/CharactersComponent";
import NewsComponent from "./NewsComponent/NewsComponent";
import ReviewComponent from "./ReviewComponent/ReviewComponent";
import ComingSoonComponent from "./ComingSoonComponent/ComingSoonComponent";
import Subtitles from "./Subtitles/Subtitles";
import Advertisements from "./Advdertisements/Advertisements";
const Home = () => {
  return (
    <>
      <Header />
      <Advertisements />
      <Subtitles />
      <Categories />
      <GamesComponent />
      <CharactersComponent />
      <NewsComponent />
      <ReviewComponent />
      <ComingSoonComponent />
    </>
  );
};

export default Home;
