import React, { useEffect } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import { Footer, Navbar } from "./components";
import "swiper/css/bundle";

import {
  Characters,
  Games,
  Home,
  News,
  Reviews,
  SignUp,
  Staff,
  NotFound,
  Top,
  Dive,
  ComingSoon,
  Company,
  GameSeries,
  Details,
  // Genre,
  Console,
  GameHistory,
  Events,
  Login,
  GameDetails,
  GameSeriesDetail,
  ChararctersDetails,
  NewsDetails,
  ReviewsDetail,
  StaffDetails,
  DetailsInfo,
  TopDetails,
  DiveDetails,
  Youtubers,
  YoutubersDetails,
  ComingSoonDetails,
  CompanyDetails,
  ConsoleDetails,
  GameHistoryDetails,
  Recommend,
  EventsDetails,
  Genres,
  GenresDetail,
  Profile,
  Settings,
  About,
  Search,
  KurdGameEvent,
} from "./pages";

import { AuthContextProvider } from "./context/authContext";
import { ThemeProvider } from "./context/ThemeContext";
import Admin from "./admin/Admin";

function App() {
  const location = useLocation();

  useEffect(() => {
    const savedColor = localStorage.getItem("primaryColor");

    if (savedColor) {
      document.documentElement.style.setProperty("--primary-color", savedColor);
    }
  }, []);
  const isAdminRoute =
    location.pathname.includes("admin") ||
    location.pathname.includes("signup") ||
    location.pathname.includes("login");
  return (
    <>
      <ThemeProvider>
        <AuthContextProvider>
          {!isAdminRoute && <Navbar />}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/games" element={<Games />} />
            <Route path="/game-details/:id" element={<GameDetails />} />
            <Route path="/characters" element={<Characters />} />
            <Route path="/characters/:id" element={<ChararctersDetails />} />
            <Route path="/staff" element={<Staff />} />
            <Route path="/staff/:id" element={<StaffDetails />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/:id" element={<NewsDetails />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/reviews/:id" element={<ReviewsDetail />} />
            <Route path="/top" element={<Top />} />
            <Route path="/top/:id" element={<TopDetails />} />
            <Route path="/dive" element={<Dive />} />
            <Route path="/dive/:id" element={<DiveDetails />} />
            <Route path="/genre" element={<Genres />} />
            <Route path="/genre/:id" element={<GenresDetail />} />
            <Route path="/comingsoon" element={<ComingSoon />} />
            <Route path="/comingsoon/:id" element={<ComingSoonDetails />} />
            <Route path="/company" element={<Company />} />
            <Route path="/company/:id" element={<CompanyDetails />} />
            <Route path="/game-series" element={<GameSeries />} />
            <Route path="/game-series/:id" element={<GameSeriesDetail />} />
            <Route path="/details" element={<Details />} />
            <Route path="/details/:id" element={<DetailsInfo />} />
            <Route path="/console" element={<Console />} />
            <Route path="/console/:id" element={<ConsoleDetails />} />
            <Route path="/game-history" element={<GameHistory />} />
            <Route path="/game-history/:id" element={<GameHistoryDetails />} />
            <Route path="/events" element={<Events />} />
            <Route path="/events/:id" element={<EventsDetails />} />
            <Route path="/login" element={<Login />} />
            <Route path="/youtubers" element={<Youtubers />} />
            <Route path="/youtubers/:id" element={<YoutubersDetails />} />
            <Route path="/recommend" element={<Recommend />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/about" element={<About />} />
            <Route path="/search" element={<Search />} />
            <Route path="/kurd-game-event" element={<KurdGameEvent />} />
            <Route path="/admin/*" element={<Admin />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          {!isAdminRoute && <Footer />}
        </AuthContextProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
