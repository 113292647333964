import React from "react";
import images from "../../assets/images";
import { AiOutlineEye } from "react-icons/ai";
import { BreadCrumb, ByWho, Loading } from "../../components";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";

const GameHistoryDetails = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(
    `https://gamewebsite.xyz/api/history-games/${id}`
  );

  const dateFormat = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  if (loading || !data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section">
          <div className="w-full max-w-[1250px] xl:px-0 px-3 flex__center flex-col gap-10 mb-10">
            <div className="flex__start w-full">
              <BreadCrumb
                exactPlace={data.title}
                secondPlace="مێژووی یاریەکان"
                secondPlaceLink="/game-history"
                key={data.id}
              />
            </div>
            <div className="w-full flex__center flex-col gap-5">
              <img
                src={data.background_image}
                alt={data.title}
                className="w-full sm:h-[400px] h-auto rounded-lg"
              />
              <div className="w-full flex__start flex-wrap sm:gap-x-10 gap-x-5 gap-y-3">
                <ByWho
                  imgPerson={data?.staff?.poster}
                  link={`/staff/${data?.staff?.id}`}
                  time={dateFormat(data.created_at)}
                  title={data?.staff?.name}
                />
                <div className="flex__center gap-2">
                  <span className="text-gray-300">کاتی خوێندنەوە : </span>
                  <span className="flex__center gap-1">
                    <span className="change_font">{data.read_duration}</span>
                    خولەک
                  </span>
                </div>
                <div className="flex__center gap-2">
                  <AiOutlineEye className="text-2xl" />
                  <span className="change_font">{data.view_count}</span>
                </div>
                <div className="flex__center gap-2">
                  <h3 className="text-gray-300">بەرواری یەکەم یاری :</h3>
                  <h3>{data.first_game_release}</h3>
                </div>
              </div>
              <div className="w-full border-t border-t-backgroundTerinary pt-5 flex__start flex-col gap-5">
                <h2 className="text-2xl">{data.title}</h2>
                <p className="paragraph">{data.description}</p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default GameHistoryDetails;
