import React from "react";
import { AiFillHeart, AiFillStar, AiOutlineStar } from "react-icons/ai";
import { ReadMore } from "../../../../components/index";
import { Link } from "react-router-dom";

const InfoGameDetails = ({
  id,
  relaseDate,
  title,
  kurdishTitle,
  genre,
  ign,
  metaCritic,
  kg,
  story,
  trailer,
  devCompany,
  publisherCompany,
  consoles,
  mode,
  revenue,
  duration,
  publisherCompanyId,
  devCompanyId,
}) => {
  const formattedNumber = (str) => {
    const num = parseFloat(str);

    if (!isNaN(num)) {
      return num.toLocaleString();
    } else {
      return "نیە";
    }
  };

  const kurdGameArray = [1, 2, 3, 4, 5];
  const ignArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  if (consoles === undefined || genre === undefined) {
    return <h1>هەڵە</h1>;
  } else {
    return (
      <>
        <div className="flex__start w-full flex-col gap-3" key={id}>
          <span className="change_font">{relaseDate}</span>
          <h1 className="text-3xl change_font font-bold">{title}</h1>
          <h3 className="text-xl">{kurdishTitle}</h3>
          <div className="text-sm flex__center gap-2">
            {genre.map((g, i) => (
              <Link key={id} className="link_hover" to={`/genre/${g.id}`}>
                {g.title}
              </Link>
            ))}
          </div>
          <div className="flex__start flex-col gap-2 text-xl">
            {ign !== 0 && <div className="flex__center gap-2">
              <span className="change_font">IGN :</span>
              {ignArray.map((i, index) => (
                <div
                  key={index}
                  className="flex__center sm:text-xl text-base text-primary"
                >
                  {i > +ign && <AiOutlineStar />}
                  {i <= +ign && <AiFillStar />}
                </div>
              ))}
              {ign}
            </div>}
            {metaCritic !== 0 && <div className="flex__center gap-2">
              <span className="change_font">Metacritic :</span>
              <div className="flex__center text-primary change_font">
                {metaCritic}
              </div>
            </div>}
            {kg && (
              <div className="flex__center gap-2">
                <span className="change_font">Kurd Game :</span>
                {kurdGameArray.map((k, index) => (
                  <div
                    key={index}
                    className="flex__center sm:text-xl text-base text-primary"
                  >
                    {k > +kg && <AiOutlineStar />}
                    {k <= +kg && <AiFillStar />}
                  </div>
                ))}
                {formattedNumber(kg)}
              </div>
            )}
          </div>
          <h1 className="mt-5 text-xl">چیرۆک</h1>
          <ReadMore maxCharacterCount={450} text={story} />
          <div className="w-full flex sm:justify-start justify-between gap-3">
            <a
              href={trailer}
              target="_blank"
              rel="noreferrer"
              className="bg-primary shadow-secondary shadow-sm sm:px-10 px-3 py-2 rounded-xl sm:text-lg text-base text-black"
            >
              بینینی ترایلەر
            </a>
          </div>
          <h2 className="text-xl mt-2">زانیاری گشتی</h2>
          <ul className="w-full flex__start flex-col gap-3">
            <li className="flex justify-between items-start w-full">
              <h4>کۆمپانیای پەرەپێدەر:</h4>
              <Link
                to={`/company/${devCompanyId}`}
                className="text-gray-300 change_font text-sm link_hover"
              >
                {devCompany}
              </Link>
            </li>
            <li className="flex justify-between items-start w-full">
              <h4>کۆمپانیای بڵاوکەرەوە:</h4>
              <Link
                to={`/company/${publisherCompanyId}`}
                className="text-gray-300 change_font text-sm link_hover"
              >
                {publisherCompany}
              </Link>
            </li>
            <li className="flex justify-between gap-2 items-start w-full">
              <h4 className="flex-shrink-0">بەردەستە بۆ:</h4>
              <div className="text-gray-300 change_font text-sm text-end w-full flex justify-end items-start flex-wrap">
                {consoles.map((e, i) => (
                  <Link key={i} className="link_hover" to={`/console/${e.id}`}>
                    {e.name}
                    {i < consoles.length - 1 && " - "}
                  </Link>
                ))}
              </div>
            </li>
            <li className="flex justify-between items-start w-full">
              <h4>مۆد:</h4>
              <h5 className="text-gray-300 text-sm">{mode}</h5>
            </li>
            {revenue > 0 && (
              <li className="flex justify-between items-start w-full">
                <h4>داهات:</h4>
                <h5 className="text-gray-300 change_font text-sm">
                  $ {formattedNumber(revenue)}
                </h5>
              </li>
            )}
            <li className="flex justify-between items-start w-full">
              <h4>کات:</h4>
              <h5 className="text-gray-300">
                <span className="change_font">{duration}</span> کاتژمێر
              </h5>
            </li>
          </ul>
        </div>
      </>
    );
  }
};

export default InfoGameDetails;
