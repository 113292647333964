import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";

import { stackData } from "../../../constants/data";

const getIntroOfPage = (label) => {
  if (label === 0) {
    return "Page A is about men's clothing";
  }
  if (label === 1) {
    return "Page B is about women's dress";
  }
  if (label === 2) {
    return "Page C is about women's bag";
  }
  if (label === 3) {
    return "Page D is about household goods";
  }
  if (label === 4) {
    return "Page E is about food";
  }
  if (label === 5) {
    return "Page F is about baby food";
  }
  if (label === 6) {
    return "Page F is about baby food";
  }
  if (label === 7) {
    return "Page F is about baby food";
  }
  if (label === 8) {
    return "Page F is about baby food";
  }
  if (label === 9) {
    return "Page F is about baby food";
  }
  if (label === 10) {
    return "Page F is about baby food";
  }
  if (label === 11) {
    return "Page F is about baby food";
  }
};

const CustomTooltip = ({ payload, label, active }) => {
  if (active) {
    return (
      <div className="bg-backgroundSecondary  bg-opacity-80 bg-clip-padding blur__effect p-2 rounded-lg">
        <p className="label">{`${label} : ${payload[0].value} visits`}</p>
        <p className="intro">{getIntroOfPage(label)}</p>
        <p className="desc">Anything you want can be displayed here.</p>
      </div>
    );
  }

  return null;
};
const StackChart = () => {
  return (
    <div className="sm:w-[450px] sm:h-[300px] overflow-x-auto w-[320px]">
      <BarChart
        width={450}
        height={300}
        data={stackData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid /> */}
        <XAxis dataKey="" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        <Bar dataKey="amt" stackId="a" fill="#5333E3" barSize={10} />
        <Bar dataKey="uv" stackId="a" fill="#5DB7E3" />
        <Bar dataKey="pv" stackId="a" fill="#C2CADD" />
      </BarChart>
    </div>
  );
};

export default StackChart;
