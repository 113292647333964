import React, { useEffect, useState } from "react";
import images from "../../assets/images";
import { BreadCrumb, ByWho, GamePoster, Loading } from "../../components";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import CharactersCard from "./CharactersComponents/CharactersCard";
import { API_URL, IMG_URL } from "../../env";

const ChararctersDetails = () => {
  const { id } = useParams();
  const [games, setGames] = useState([]);
  const [similar, setSimilar] = useState([]);
  const { data, loading, error } = useFetch(`${API_URL}characters/view/${id}`);
  const {
    data: similarData,
    loading: similarLoading,
    similarError,
  } = useFetch(
    `${API_URL}characters/similar?gameName=${data?.data?.game_name}`
  );

  const {
    data: gamesData,
    loading: gamesLoading,
    gamesError,
  } = useFetch(`${API_URL}characters/view/${id}/games`);
  useEffect(() => {
    if (!loading && gamesData && gamesData?.length > 0) {
      setGames(gamesData);
    }
  }, [gamesData, loading]);

  useEffect(() => {
    if (!similarLoading && similarData && similarData?.data?.length > 0) {
      setSimilar(similarData?.data);
      // console.log(similar);
    }
  }, [similarData, similarLoading]);

  const dateFormat = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  if (loading || !data.data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section">
          <div className="w-full max-w-[1250px] xl:px-0 px-3 flex__center flex-col gap-10 mb-10">
            <div className="w-full flex__start">
              <BreadCrumb
                exactPlace={data.data.name}
                secondPlaceLink="/characters"
                secondPlace="کەسایەتیەکان"
                key={data.data.id}
              />
            </div>
            <div className="w-full flex__start sm:flex-row flex-col gap-10 border-b border-b-backgroundTerinary pb-5">
              <div className="w-[250px] h-[370px] rounded-lg overflow-hidden">
                <img
                  src={`${IMG_URL}characters/${data.data.image}`}
                  alt={data.data.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex__start flex-col gap-5">
                <h2 className="change_font font-semibold text-4xl tracking-wider">
                  {data.data.name}
                </h2>
                <h3 className="text-xl">{data.data.k_name}</h3>
                {data.data.nickname && (
                  <div className="flex__center gap-2">
                    <h3 className="text-gray-300">ناسراوە بە : </h3>
                    <h3 className="">{data.data.nickname}</h3>
                  </div>
                )}
                {data.data.age && (
                  <div className="flex__center gap-2">
                    <h3 className="text-gray-300">تەمەن : </h3>
                    <h3 className="">
                      <span className="change_font">{data.data.age}</span> ساڵ
                    </h3>
                  </div>
                )}
                {data.data.gender && (
                  <div className="flex__center gap-2">
                    <h3 className="text-gray-300">ڕەگەز : </h3>
                    <h3 className="">
                      {data.data.gender === "male"
                        ? "نێر"
                        : data.data.gender === "female"
                        ? "مێ"
                        : "مرۆڤ نیە"}
                    </h3>
                  </div>
                )}
                {data.data.voice_actor && (
                  <div className="flex__center gap-2">
                    <h3 className="text-gray-300">ئەکتەری دەنگی : </h3>
                    <h3 className="change_font">{data.data.voice_actor}</h3>
                  </div>
                )}
                {similar.length > 0 && (
                  <div className="flex__center gap-2">
                    <h3 className="text-gray-300">بەشداریەکان : </h3>
                    <h3 className="change_font">{similar.length}</h3>
                  </div>
                )}
                {data.data.job && (
                  <div className="flex__center gap-2">
                    <h3 className="text-gray-300">پیشە : </h3>
                    <h3 className="">{data.data.job}</h3>
                  </div>
                )}
                <ByWho
                  imgPerson={
                    data.data.staff.image ? data.data.staff.image : null
                  }
                  time={dateFormat(data.data.created_at)}
                  title={data.data.staff.name}
                  key={data.data.id}
                  link={`staff/${data?.data?.staff?.id}`}
                />
              </div>
            </div>
            {data.data.quote && (
              <h2 className="text-2xl w-full text-center pb-8 border-b border-b-backgroundTerinary">
                "{data.data.quote}"
              </h2>
            )}
            <div className="w-full flex__start flex-col  gap-5">
              <h3 className="text-2xl">زانیاری گشتی</h3>
              <p className="text-gray-300 whitespace-pre-line">
                {data.data.description}
              </p>
            </div>
            {games.length > 0 && (
              <div className="w-full flex__start flex-col gap-10 pt-5 border-t border-t-backgroundTerinary">
                <h3 className="text-2xl">یاریەکانی تێیدا بەشداربووە</h3>
                <div className="flex-wrap w-full flex items-start justify-center sm:gap-x-5 gap-x-2 gap-y-5">
                  {games.map((game, i) => (
                    <GamePoster
                      title={game.title}
                      genre={game.genres}
                      img={`${IMG_URL}games/poster_image/${game.poster_image}`}
                      link={`/game-details/${game.id}`}
                      key={i}
                      rate={game.ign_rating}
                      views={game.view_count}
                    />
                  ))}
                </div>
              </div>
            )}
            {similar.length > 1 && (
              <div className="w-full flex__start flex-col gap-10 pt-5 border-t border-t-backgroundTerinary">
                <h3 className="text-2xl">کەسایەتی هاوشێوە</h3>
                <div className="flex-wrap w-full flex items-start justify-center sm:gap-x-5 gap-x-2 gap-y-5">
                  {similar.map((character, i) => {
                    character.id != id && (
                      <CharactersCard
                        name={character.name}
                        game={character.game_name}
                        numGames={character.game_count}
                        key={i}
                        img={`${IMG_URL}characters/${character.image}`}
                        link={`characters/${character.id}`}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </section>
      </>
    );
  }
};

export default ChararctersDetails;
