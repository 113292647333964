import React, { useEffect, useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useParams } from "react-router-dom";
import SendData from "../../../hooks/useSendData";
import { ButtonLoading } from "../../../components";
import { API_URL } from "../../../env";

const AddDetailPoints = () => {
  const { id } = useParams();
  const { createData, createError, createLoading, post } = SendData();

  const [childImg, setChildImg] = useState("");
  const [errorText, setErrorText] = useState("");

  const [isSent, setIsSent] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
    number: "",
    detail_id: id,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("number", formData.number);
    formDataToSend.append("image", formData.image);
    formDataToSend.append("detail_id", formData.detail_id);

    try {
      await post(`${API_URL}detail-points/create`, formDataToSend);
      setIsSent(true);
      window.location.reload();
    } catch (error) {
      setErrorText(error.response.data.message);
    }
  };
  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
    setChildImg(e.target.files[0]);
  };

  useEffect(() => {
    if (createError) {
    }
  }, [createError]);

  return (
    <>
      <div className="fixed p-5 rounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] bg-backgroundSecondary z-40 w-full max-w-[500px]">
        <div className="flex__start gap-5 flex-col w-full">
          <h1 className="text-2xl">زیادکردنی خاڵەکانی وردەکاری و بەرزترین</h1>
          <div className="flex__start flex-col gap-1 w-full">
            <label htmlFor="title" className="text-lg">
              ناونیشان
            </label>
            <input
              type="text"
              id="title"
              className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
              value={formData.title}
              onChange={handleChange}
              name="title"
            />
          </div>
          <div className="flex__start flex-col gap-1 w-full">
            <label htmlFor="description" className="text-lg">
              زانیاری زیاتر
            </label>
            <input
              type="text"
              id="description"
              className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
              value={formData.description}
              onChange={handleChange}
              name="description"
            />
          </div>
          <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="imgPoint" className=" text-lg">
              وێنە
            </label>
            <input
              type="file"
              id="imgPoint"
              disabled={false}
              className="hidden"
              onChange={handleImageChange}
              name="image"
            />
            <div className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent flex items-center justify-between">
              <div className="mx-3 text-ellipsis w-full overflow-hidden whitespace-nowrap">
                {childImg.name}
              </div>
              <label
                htmlFor="imgPoint"
                className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
              >
                <AiOutlineCloudUpload />
              </label>
            </div>
          </div>
          <div className="flex__start flex-col gap-1 w-full">
            <label htmlFor="number" className="text-lg">
              ڕیزبدەندی
            </label>
            <input
              type="number"
              id="number"
              className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
              value={formData.number}
              onChange={handleChange}
              name="number"
            />
          </div>
          <p className="text-lg text-red">{errorText}</p>
          <button
            onClick={handleSubmit}
            type="submit"
            disabled={createLoading}
            className={`sm:w-[450px] w-[90%] relative h-[45px] bg-background text-xl py-2 hover:bg-primary hover:text-black duration-300 rounded-md ${
              createLoading ? "disabled" : ""
            }`}
          >
            {!createLoading && <span>ناردن</span>}
            {createLoading && <ButtonLoading />}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddDetailPoints;
