import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { AuthContext } from "../../../context/authContext";
import images from "../../../assets/images";

const Navbar = ({ secondPlace, exactPlace, secondPlaceLink }) => {
  const { user, loading, error, dispatch } = useContext(AuthContext);
  const [account, setAccount] = useState(false);

  const handleAccount = () => {
    setAccount(!account);
  };
  const handleLogOut = () => {
    localStorage.removeItem("user");
    dispatch({ type: "LOGOUT" });
  };
  return (
    <>
      <div className="xl:w-[78%] w-[95%] bg-background bg-opacity-80 bg-clip-padding blur__effect flex md:justify-between justify-end items-center h-20 fixed left-[50%] sm:left-5 -translate-x-[50%] sm:-translate-x-[0] top-5 rounded-2xl px-4 z-20">
        <div className="md:flex items-start justify-start hidden flex-col">
          <div className="flex__center gap-2 text-lg ">
            <Link to="/" className="link_hover text-slate-300" title="ماڵپەر">
              ماڵپەر
            </Link>
            <span className="text-slate-300">/</span>
            <Link
              className="text-slate-300 link_hover"
              title={secondPlace}
              to={secondPlaceLink}
            >
              {secondPlace}
            </Link>
            {exactPlace && <span>/</span>}
            {exactPlace && <h2>{exactPlace}</h2>}
          </div>
          <h1 className="text-4xl space-x-2">{exactPlace}</h1>
        </div>
        <div className="flex__center bg-backgroundSecondary sm:gap-2 gap-0 sm:px-4 px-2 py-2 rounded-2xl">
          <div className="relative xl:pr-0 pr-10">
            <input
              type="text"
              className="px-2 h-9 rounded-xl sm:w-40 w-32 outline-none bg-background z-10"
              placeholder="بگەڕێ..."
            />
            <button className="absolute xl:left-5 left-12 top-2 text-xl">
              <BiSearch />
            </button>
          </div>
          <div className="relative">
            {user && (
              <>
                <div
                  onClick={handleAccount}
                  className={`fixed w-full h-[100vh] top-0 z-10 left-0 ${
                    account === true ? "block" : "hidden"
                  }`}
                ></div>
                {/* <h2>{userData.name}</h2> */}
                <div className="relative w-10 h-10 z-20">
                  <button
                    onClick={handleAccount}
                    type="button"
                    className="w-10 h-10 rounded-full overflow-hidden"
                  >
                    <img
                      src={images.joel}
                      alt={user.name}
                      className="w-full h-full"
                    />
                  </button>
                  <ul
                    className={`w-[170px] bg-backgroundSecondary flex justify-start items-start gap-3 flex-col rounded-md absolute duration-500 top-12 left-0 overflow-hidden ${
                      account === true ? "max-h-[500px]" : "max-h-0"
                    }`}
                  >
                    <li className="pt-3 pr-3 w-full">
                      <Link
                        onClick={handleAccount}
                        to="admin"
                        className="text-sm text-gray-300 w-full block link_hover"
                      >
                        ئەدمین
                      </Link>
                    </li>
                    <li className="pr-3 w-full">
                      <Link
                        onClick={handleAccount}
                        to="profile"
                        className="text-sm text-gray-300 link_hover block w-full"
                      >
                        هەژمار
                      </Link>
                    </li>
                    <li className="pr-3 w-full">
                      <Link
                        onClick={handleAccount}
                        to="settings"
                        className="text-sm text-gray-300 link_hover w-full block"
                      >
                        ڕێکخستن
                      </Link>
                    </li>
                    <li className="pb-3 pr-3 border-t-2 pt-1 border-t-background w-full">
                      <button
                        onClick={handleLogOut}
                        type="button"
                        className="text-sm text-start text-gray-300 link_hover w-full block"
                      >
                        چوونە دەرەوە
                      </button>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
