import React from "react";
import { BsDot } from "react-icons/bs";
import { Link } from "react-router-dom";

const GameSeriesCard = ({ title, link, img, parts, id, company }) => {
  return (
    <>
      <Link
        to={link}
        title={title}
        className="md:w-[48%] w-full h-[250px]  hovering_gameSeries relative rounded-md overflow-hidden"
      >
        <img src={img} alt={title} className="w-full h-[250px] object-cover" />
        <div className="flex justify-start z-10 flex-col items-start absolute bottom-5 right-5">
          <h1 className="text-2xl change_font">{title}</h1>
          {/* <div className="flex justify-start items-center gap-1 w-full overflow-hidden">
            {genre.map((g, i) => (
              <>
                <span className="max-w-[20ch] text-ellipsis overflow-hidden whitespace-nowrap sm:text-base text-sm">
                  {g.title}
                </span>
                {i < genre.length - 1 && (
                  <BsDot className="text-[#a4a4a4] text-xl" />
                )}
              </>
            ))}
          </div> */}
          <div className="flex__center gap-2">
            <span>{parts}</span>
            <p>بەش</p>
          </div>
          <div className="flex__center gap-1">
            <span>کۆمپانیا: </span>
            <span className="change_font">{company}</span>{" "}
          </div>
        </div>
        <div className="bg-black blur_hovered_gameSeries duration-300 absolute left-0 top-0 w-full h-full bg-opacity-20 rounded-2xl shadow-2xl shadow-black backdrop-blur-[5px] border-r-[0] border-b-[0] border border-background border-opacity-30 backdrop-filter"></div>
      </Link>
    </>
  );
};

export default GameSeriesCard;
