import React, { useEffect, useState } from "react";
import {
  AiOutlineCheckCircle,
  AiOutlineClose,
  AiOutlineCloudUpload,
} from "react-icons/ai";
import SendData from "../../../hooks/useSendData";
import { useParams } from "react-router-dom";
import { ButtonLoading } from "../../../components";
import { CreatedWarning } from "../../admin-components";
import { MdReportGmailerrorred } from "react-icons/md";
import { API_URL } from "../../../env";
const UpdateDetailPoint = ({ data }) => {
  const { id } = useParams();
  const { createData, createError, createLoading, post } = SendData();

  const [childImg, setChildImg] = useState("");
  const [errorText, setErrorText] = useState("");

  const [isSent, setIsSent] = useState(false);

  const defaultFormData = {
    title: "",
    description: "",
    image: "",
    number: "",
    detail_id: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  useEffect(() => {
    if (data) {
      setFormData({
        title: data.title || "", // Default title value
        number: data.number || "", // Default number value
        description: data.description || "", // Default description value
        detail_id: id || "", // Default description value
        image: "",
      });
    }
  }, [data]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("number", formData.number);
    formDataToSend.append("image", formData.image);
    formDataToSend.append("detail_id", formData.detail_id);

    try {
      await post(`${API_URL}detail-points/update/${data.id}`, formDataToSend);
      setIsSent(true);
      window.location.reload();
    } catch (error) {
      setErrorText(error.response.data.message);
    }
  };
  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
    setChildImg(e.target.files[0]);
  };

  useEffect(() => {
    if (createError) {
    }
  }, [createError]);
  return (
    <div className="fixed p-5 rounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] bg-backgroundSecondary z-50 w-full max-w-[800px]">
      <div className="flex__center gap-5 flex-col w-full">
        <h1 className="text-2xl">نوێکردنەوەی خاڵەکانی وردەکاری و بەرزترین</h1>
        <div className="flex__center flex-col gap-1 w-full">
          <label htmlFor="titleDetail" className="text-lg">
            ناونیشان
          </label>
          <input
            type="text"
            id="titleDetail"
            className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
            value={formData.title}
            onChange={handleChange}
            name="title"
          />
        </div>
        <div className="flex__center flex-col gap-1 w-full">
          <label htmlFor="description" className="text-lg">
            زانیاری زیاتر
          </label>
          <input
            type="text"
            id="description"
            className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
            value={formData.description}
            onChange={handleChange}
            name="description"
          />
        </div>
        <div className="flex__center flex-col gap-1 w-full sm:px-0 px-2">
          <label htmlFor="imgPointUpdate" className=" text-lg">
            وێنە
          </label>
          <input
            type="file"
            id="imgPointUpdate"
            disabled={false}
            className="hidden"
            onChange={handleImageChange}
            name="image"
          />
          <div className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent flex items-center justify-between">
            <div className="mx-3 text-ellipsis w-full overflow-hidden whitespace-nowrap">
              {childImg.name || data.image}
            </div>
            <label
              htmlFor="imgPointUpdate"
              className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
            >
              <AiOutlineCloudUpload />
            </label>
          </div>
        </div>
        <div className="flex__center flex-col gap-1 w-full">
          <label htmlFor="number" className="text-lg">
            ڕیزبدەندی
          </label>
          <input
            type="number"
            id="number"
            className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
            value={formData.number}
            onChange={handleChange}
            name="number"
          />
        </div>
        {/* <p className="text-lg text-red">{errorText}</p> */}
        <button
          onClick={handleSubmit}
          type="submit"
          disabled={createLoading}
          className={`sm:w-[450px] w-[90%] relative h-[45px] bg-background text-xl py-2 hover:bg-primary hover:text-black duration-300 rounded-md ${
            createLoading ? "disabled" : ""
          }`}
        >
          {!createLoading && <span>ناردن</span>}
          {createLoading && <ButtonLoading />}
        </button>
        {isSent && !createError && (
          <CreatedWarning
            icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
            message={"بابەتەکە بەسەرکەوتوویی نوێکرایەوە"}
          />
        )}
        {createError && createError.response && (
          <CreatedWarning
            icon={<MdReportGmailerrorred className="text-9xl text-red" />}
            message={"تکایە لە دڵنیاببەوە هەموو زانیاریەکانت بەڕاستی داناوە"}
            moreInfo={
              createError && createError.response
                ? createError.response.data.message
                : "An error occurred"
            }
          />
        )}
      </div>
    </div>
  );
};

export default UpdateDetailPoint;
