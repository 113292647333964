import React, { useState } from "react";

import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Search = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const handleSearch = () => {
    navigate(`/search?query=${searchText}`);
  };
  const handleKeySearch = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <>
      <div className="relative flex-[0.8]">
        <div className="w-full sm:h-[40px] h-[35px] rounded-xl overflow-hidden">
          <input
            type="text"
            placeholder="لێرە بگەڕێ..."
            className="w-full h-full lg:text-lg sm:text-base text-sm bg-backgroundSecondary px-4 outline-none"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={handleKeySearch}
          />
        </div>
        <button
          onClick={handleSearch}
          className="absolute sm:left-4 left-2 top-[50%] text-xl -translate-y-[50%]"
        >
          <FiSearch />
        </button>
      </div>
    </>
  );
};

export default Search;
