import React from "react";
import { Link } from "react-router-dom";

const AddButton = ({ title, link }) => {
  return (
    <>
      <div className="flex justify-between items-center w-[90%] ">
        <Link to={link} className="primary-button" title={title}>
          {title}
        </Link>
      </div>
    </>
  );
};

export default AddButton;
