import React from "react";

const About = () => {
  return (
    <>
      <section className="section">
        <div className="flex__start w-full max-w-[1250px] xl:px-0 px-3 sm:mb-32 mb-10 flex-col gap-4">
          <h1 className="sm:text-4xl text-xl">دەربارەی ئێمە</h1>
          <p className="w-full sm:text-lg text-base">
            ماڵپەڕی کوردگەیم یەکەمین ماڵپەڕی کوردی تایبەت بە یارییە ڤیدیۆییەکانە
            ساڵی ٢٠٢١ دامەزراوە. ماڵپەڕی کوردگەیم سەکۆکەیە بۆ بە ئاشناکردنی
            یاریکەرانی کورد بە چیرۆکی یارییەکان و گەیاندنی هەواڵەکانی ڕۆژ لە
            بارەی یارییە ڤیدیۆییەکان بە شێوەیەکی ئەکادیمی و بێ لایەنگری کردن و
            دەربڕینی بازاڕیانە. هەروەها لە ماڵپەڕەکەماندا چیرۆکی یارییەکان و
            دیمەنە سینەماتیکییەکانی یارییەکان ژێرنووس دەکرێن بۆ ئەو کەسانەی زمان
            نازانن یاخود لە گفتوگۆیەکان تێناگەن ئەوا ئێمە بۆتان ژێرنووس دەکەین و
            لە ماڵپەڕدا بەردەستی دەخەین.
          </p>
          <p className="w-full sm:text-lg text-base">
            ئامانجی کوردگەیم ئەوەیە کۆمەڵگەیەکی یاری کردنی هۆشیار و تەندروست
            بنیادبنێت و تاکەکان ئاشنا بکات بە چیرۆکی یارییەکان و گەیاندنی هەواڵ
            و زانیارییەکان بێ لایەنگری کردن و دەربڕینی بازاڕیانە. ئەمیش بۆ ئەوەی
            بەشێک نەبین لەو ناڕێکی و هەڵەتێگەیشتنەی لای بەشێک لە یاریکەرانی کورد
            هەیە.
          </p>
          <p className="w-full sm:text-lg text-base">
            ئێمە وەکو ستافی ماڵپەڕی کوردگەیم بە شێوازێکی ئەکادیمی ڕۆژانە
            بابەتەکانمان بڵاودەکەینەوە هەر لە نووسینی وردەکاری و نهێنی یارییەکان
            و هەواڵ تا کاری ژێرنووس بۆ یارییەکان و چەندەها بابەتی تر.
          </p>
          <p className="w-full sm:text-lg text-base">
            لێرە یاریکەران ئاشنا دەبن بە چەندەها بابەتی جیاواز لە بواری یارییە
            ڤیدیۆییەکان.
          </p>
        </div>
      </section>
    </>
  );
};

export default About;
