import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import images from "../../assets/images";
import axios from "axios";
import { AuthContext } from "../../context/authContext";
import Cookies from "js-cookie";
import SendData from "../../hooks/useSendData";
import { CreatedWarning } from "../../admin/admin-components";
import { MdReportGmailerrorred } from "react-icons/md";
import { ButtonLoading } from "../../components";
import { API_URL } from "../../env";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [crudentials, setCrudentials] = useState({
    username: undefined,
    password: undefined,
  });
  const { user, loading, error, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoginLoading(true);
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post(`${API_URL}auth/user/login`, crudentials);
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data.user });
      const userData = res.data.data;
      const token = res.data.token;
      Cookies.set("token", token, { expires: 365 });
      setLoginLoading(false);
      navigate("/");
    } catch (err) {
      setErrorMessage(err);
      setLoginLoading(false);
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data.message });
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setCrudentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  return (
    <>
      <section>
        <div className="w-full flex__center gap-10 h-[100vh]">
          <img
            src={images.auth}
            alt="auth"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          <div className="flex sm:w-auto w-[95%] md:items-start sm:justify-start justify-center items-center flex-col gap-4 relative backdrop-blur-md bg-black bg-opacity-30 p-5 rounded-lg">
            <Link to="/" className="w-40 h-auto">
              <img src={images.logo} alt="logo" className="w-full h-full" />
            </Link>
            <h1 className="text-3xl">چوونە ژوورەوە</h1>
            <span className="text-slate-400 text-sm">
              تکایە هەموو وردەکاریەکان دابنێ
            </span>
            <form
              onSubmit={handleSubmit}
              className="flex__start flex-col w-full gap-5"
            >
              <div className="flex__start w-full relative flex-col gap-y-1">
                <label htmlFor="email">ئیمەیڵ:</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={FormData.email}
                  placeholder="ئیمەیڵ"
                  onChange={handleChange}
                  className="border-b outline-none bg-transparent sm:w-[350px] w-full"
                />
                {/* <span className="sm:w-[350px] w-full spanInput">{input.errroMessage}</span> */}
              </div>
              <div className="flex__start w-full relative flex-col gap-y-1">
                <label htmlFor="password">تێپەرە وشە:</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={FormData.password}
                  placeholder="تێپەرە وشە"
                  className="border-b outline-none bg-transparent sm:w-[350px] w-full"
                  onChange={handleChange}
                />
                {/* <span className="sm:w-[350px] w-full spanInput">{input.errroMessage}</span> */}
              </div>
              <button
                type="submit"
                className="sm:w-[350px] relative w-full duration-300 transition-colors hover:bg-backgroundTerinary bg-backgroundSecondary text-base rounded-md px-2 py-2 h-[45px]"
              >
                {!loginLoading && <span>چوونە ژوورەوە</span>}
                {loginLoading && <ButtonLoading />}
              </button>
            </form>
            {/* <div className="w-full">
              <h1 className="text-center text-xl relative after:absolute after:bg-backgroundTerinary after:left-0 after:top-[15px] after:w-[40%] after:h-[2px] before:absolute before:bg-backgroundTerinary before:right-0 before:top-[15px] before:w-[40%] before:h-[2px]">
                یان
              </h1>
            </div>
            <div className="w-full">
              <button className="border rounded-lg sm:h-11 h-10 duration-300 hover:border-backgroundSecondary hover:bg-backgroundSecondary sm:w-[350px] w-full flex__center gap-x-5">
                <img
                  src={images.google}
                  alt="google"
                  className="w-[30px] h-[30px]"
                />
                <span>بە ئیمەیڵ بەردەوامبە</span>
              </button>
            </div> */}
            <h1 className="text-base">
              <span>هەژمارت نیە؟</span>
              <Link title="title" className="text-primary mx-3" to="/signup">
                خۆ تۆمارکردن
              </Link>
            </h1>
          </div>
        </div>
        {errorMessage && errorMessage.response && (
          <CreatedWarning
            icon={<MdReportGmailerrorred className="text-9xl text-red" />}
            message={"هەڵە ڕوویدا"}
            moreInfo={errorMessage.response.data.message}
          />
        )}
      </section>
    </>
  );
};

export default Login;
