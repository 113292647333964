import React from "react";
import { Link } from "react-router-dom";

const YoutubersCard = ({ title, img, link }) => {
  return (
    <>
      <Link
        title={title}
        to={link}
        className="sm:w-[390px] w-full h-[180px] relative flex__center rounded-md overflow-hidden"
      >
        <img
          src={img}
          alt="title"
          className="absolute left-0 object-cover top-0 w-full h-full"
        />
        <div className="flex__center h-full bg-gradient-to-r from-[#ffffff50] to-gray-900 flex-col gap-10 w-full p-4 z-10">
          <h2 className="text-3xl change_font text-primary">{title}</h2>
          <Link to={link} className="primary-button">
            بینینی زیاتر
          </Link>
        </div>
      </Link>
    </>
  );
};

export default YoutubersCard;
