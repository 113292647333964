import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import SendData from "../../../hooks/useSendData";
import { MdReportGmailerrorred } from "react-icons/md";
import { CreatedWarning, Navbar } from "../../admin-components";
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
import images from "../../../assets/images";
import { ButtonLoading, Loading } from "../../../components";
import { useParams } from "react-router-dom";

const UpdateGameHistory = () => {
  const { createData, createError, createLoading, post } = SendData();
  const { id } = useParams();
  const [getDataGame, setGetDataGame] = useState([]);

  const {
    data: staffData,
    loading: staffLoading,
    error: staffError,
    refetch: refetchStaff,
  } = useFetch("https://gamewebsite.xyz/api/staffs/game_filter");

  const {
    data: gameData,
    loading: gameLoading,
    error: gameError,
    refetch: refetchgame,
  } = useFetch("https://gamewebsite.xyz/api/games?limit=1");
  useEffect(() => {
    if (!gameLoading && gameData?.data?.length > 0) {
      setGetDataGame(gameData.data);
    }
  }, [gameLoading, getDataGame, gameData]);

  const {
    data: gameHistoryData,
    loading: gameHistoryLoading,
    error: gameHistoryError,
  } = useFetch(`https://gamewebsite.xyz/api/history-games/${id}`);

  const [getData, setGetData] = useState([]);

  useEffect(() => {
    if (!staffLoading && staffData?.length > 0) {
      setGetData(staffData);
    }
  }, [staffLoading, getData, staffData]);

  const [img, setImg] = useState("");

  const [isSent, setIsSent] = useState(false);

  const defaultFormData = {
    title: "",
    description: "",
    background_image: "",
    read_duration: "",
    popularity: "",
    first_game_release: "",
    staff_id: "",
    game_id: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  useEffect(() => {
    if (!gameHistoryLoading && gameHistoryData) {
      // Update formData with characterData when available
      setFormData({
        title: gameHistoryData.title || "", // Default name value
        description: gameHistoryData.description || "", // Default description value
        background_image: "", // Leave empty for the image field, or set it to a default value if needed
        read_duration: gameHistoryData.read_duration || "", // Default read_duration value
        first_game_release: gameHistoryData.first_game_release || "male", // Default first_game_release value
        game_id: gameHistoryData.game_id || "", // Default game_id value
        popularity: gameHistoryData.popularity || "", // Default popularity value
        staff_id: gameHistoryData?.staff?.id || "", // Default staff_id value
      });
    }
  }, [gameHistoryData, gameHistoryLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("first_game_release", formData.first_game_release);

    formDataToSend.append("background_image", formData.background_image);
    formDataToSend.append("read_duration", formData.read_duration);

    formDataToSend.append("popularity", formData.popularity);
    formDataToSend.append("staff_id", formData.staff_id);
    formDataToSend.append("game_id", formData.game_id);

    try {
      await post(
        `https://gamewebsite.xyz/api/history-games/update/${id}`,
        formDataToSend
      );
      setIsSent(true);
    } catch (error) {
      setIsSent(false);
    }
  };
  useEffect(() => {
    if (createError) {
      setIsSent(false);
    }
  }, [createError]);
  if (gameHistoryLoading || !gameHistoryData) {
    return <Loading />;
  } else {
    return (
      <>
        <Navbar
          exactPlace="زیادکردنی مێژووی یاریەکان"
          secondPlace="مێژووی یاریەکان"
          secondPlaceLink="/admin/games-history"
        />

        <div className="flex__center flex-col w-full xl:flex-[0.8] mb-10 flex-1 mt-28 gap-8">
          <h1 className="text-lg text-slate-300 w-full px-2">
            *تکایە وێنەکە و یاریەکیش دابنێوە لەکاتی گۆڕینی بابەتەکە
          </h1>
          <div className="flex__center md:flex-row flex-col md:gap-10 gap-5 w-full">
            <div className="flex__start flex-col gap-3 sm:w-auto w-full">
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="name" className=" text-lg">
                  ناو
                </label>
                <input
                  type="text"
                  id="name"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formData.title}
                  onChange={handleChange}
                  name="title"
                />
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="popularity" className=" text-lg">
                  ڕیزبەندی
                </label>
                <input
                  type="text"
                  id="popularity"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formData.popularity}
                  onChange={handleChange}
                  name="popularity"
                />
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="read_duration" className=" text-lg">
                  ماوەی خوێندنەوە
                </label>
                <input
                  type="text"
                  id="read_duration"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formData.read_duration}
                  onChange={handleChange}
                  name="read_duration"
                />
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="img" className=" text-lg">
                  وێنە
                </label>
                <input
                  type="file"
                  id="img"
                  disabled={false}
                  className="hidden"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      background_image: e.target.files[0],
                    });
                    setImg(e.target.files[0]);
                  }}
                  name="background_image"
                />
                <div className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent flex items-center justify-between">
                  <div className="mx-3">{img.name}</div>
                  <label
                    htmlFor="img"
                    className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                  >
                    <AiOutlineCloudUpload />
                  </label>
                </div>
              </div>
            </div>
            <label
              htmlFor="img"
              className="sm:w-[350px] sm:h-[150px] w-full h-auto bg-backgroundSecondary rounded-xl overflow-hidden"
            >
              <img
                src={
                  img
                    ? URL.createObjectURL(img)
                    : gameHistoryData.background_image
                }
                alt="img"
                className="w-full h-full object-cover"
              />
            </label>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="first_game_release">
                کاتی دەرچوونی یەکەم یاری
              </label>
              <input
                type="date"
                id="first_game_release"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
                value={formData.first_game_release}
                onChange={handleChange}
                name="first_game_release"
              />
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="staff_id">لەلایەن</label>
              <select
                name="staff_id"
                id="staff_id"
                onChange={handleChange}
                className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[230px] w-full p-2.5 bg-backgroundSecondary"
                value={formData.staff_id}
              >
                {getData.map((staff) => (
                  <option value={staff.id}>{staff.name}</option>
                ))}
              </select>
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="game_id">یاری</label>
              <select
                name="game_id"
                id="game_id"
                onChange={handleChange}
                className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[230px] w-full p-2.5 bg-backgroundSecondary"
                value={formData.game_id}
              >
                <option value="0" defaultValue>
                  یاریەک هەڵبژێرە
                </option>
                {getDataGame.map((game) => (
                  <option value={game.id}>{game.title}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="textarea">پێناسەکردنی</label>
            <textarea
              name="description"
              id="textarea"
              value={formData.description}
              onChange={handleChange}
              className="outline-none border-slate-300 border rounded h-32 md:w-[720px] sm:w-[450px] w-full bg-transparent px-2"
            />
          </div>
          <button
            onClick={handleSubmit}
            type="submit"
            disabled={createLoading}
            className={`sm:w-[450px] w-[90%] relative h-[45px] bg-backgroundSecondary text-xl py-2 hover:bg-primary hover:text-black duration-300 rounded-md ${
              createLoading ? "disabled" : ""
            }`}
          >
            {!createLoading && <span>ناردن</span>}
            {createLoading && <ButtonLoading />}
          </button>
        </div>
        {isSent === true && (
          <CreatedWarning
            icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
            message={"بابەتەکە بەسەرکەوتوویی نوێکرایەوە"}
          />
        )}
        {createError && createError.response && (
          <CreatedWarning
            icon={<MdReportGmailerrorred className="text-9xl text-red" />}
            message={"تکایە لە دڵنیاببەوە هەموو زانیاریەکانت بەڕاستی داناوە"}
            moreInfo={
              createError && createError.response
                ? createError.response.data.message
                : "An error occurred"
            }
          />
        )}
      </>
    );
  }
};

export default UpdateGameHistory;
