import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./Header.css";

// import required modules
import { Autoplay, Scrollbar } from "swiper";

import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import useFetch from "../../../hooks/useFetch";
import { Loading } from "../../../components";
import { API_URL, IMG_URL } from "../../../env";

export default function App() {
  const { data, loading, error } = useFetch(`${API_URL}games/slider`);

  const [getData, setGetData] = useState([]);

  useEffect(() => {
    if (!loading && data && data.data && data.data.length > 0) {
      setGetData(data.data);
    }
  }, [data, loading]);

  const getYear = (dateString) => {
    const date = new Date(dateString);
    if (!isNaN(date)) {
      return date.getFullYear();
    } else {
      return "بەرواری نیە";
    }
  };
  if (loading || !data) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="w-full lg:h-[100vh] md:h-[70vh] sm:h-[80vh] h-[65vh] relative">
          <Swiper
            // pagination={{
            //   type: "progressbar",
            // }}
            scrollbar={{
              hide: false,
            }}
            modules={[Autoplay, Scrollbar]}
            className="w-full h-full"
            loop={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: true,
            }}
          >
            {getData.map((game, i) => (
              <SwiperSlide className="w-full h-full" key={i}>
                <div className="w-full h-full relative">
                  <div className="w-full h-full">
                    <img
                      src={`${IMG_URL}games/background_image/${game.background_image}`}
                      alt={game.title}
                      className="w-full h-full object-cover  bg-cover bg-center"
                    />
                  </div>
                  <div className="absolute z-10 bottom-0 left-0 h-[50%] w-full bg-gradient-to-t from-[#000000be] to-transparent"></div>
                  <div className="absolute w-full z-20 sm:top-[70%] top-[70%] -translate-y-[50%] sm:right-[5%] right-[50%] sm:translate-x-[0] translate-x-[50%] flex sm:items-start sm:justify-start justify-center items-center gap-5 flex-col">
                    <Link
                      to={`/game-details/${game.id}`}
                      title={game.title}
                      className="change_font md:text-4xl text-center sm:text-start text-2xl link_hover w-full "
                    >
                      {game.title}
                    </Link>
                    <div className="flex sm:items-start sm:justify-start justify-center items-center gap-4 w-full sm:flex-row flex-col">
                      <div className="flex__center gap-2">
                        <span className="text-gray-300 change_font">
                          {getYear(game.release_date)}
                        </span>
                        <div className="flex__center gap-1">
                          <span className="text-gray-300 change_font">
                            {game.ign_rating}
                          </span>
                          <AiFillStar className="text-xl text-primary" />
                        </div>
                        <span className="text-gray-300 change_font">
                          {game.duration}h
                        </span>
                      </div>
                      <div className="flex__center gap-2">
                        {/* {game.genres.map((genre, i) => (
                          <span
                            key={i}
                            className="border border-white p-1 rounded text-sm min-w-fit"
                          >
                            {genre.title}
                          </span>
                        ))} */}
                        {JSON.parse(game.genres).map((genre, i) => (
                          <span
                            className="border border-white p-1 rounded text-sm min-w-fit"
                            key={i}
                          >
                            {genre.title}
                          </span>
                        ))}
                      </div>
                    </div>
                    <p className="font-light sm:block hidden text-base text-start text-gray-300 w-[600px] whitespace-normal">
                      {game.description.substring(0, 230)}...
                    </p>
                    <Link
                      to={`/game-details/${game.id}`}
                      title={game.title}
                      className="primary-button"
                    >
                      زیاتر ببینە
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </>
    );
  }
}
