import React from "react";
import CategoriesCard from "./CategoriesCard";
import images from "../../../assets/images/index";
const Categories = () => {
  return (
    <>
      <div className="flex__center w-full my-10 flex-col">
        <div className="flex__start md:gap-5 gap-3 w-full max-w-[1250px] flex-col xl:px-0 px-3">
          <h1 className="md:text-4xl text-2xl">بەشەکان</h1>
          <div className="flex justify-between items-center w-full flex-wrap gap-x-3 gap-y-5 ">
            <CategoriesCard
              link="details"
              title="وردەکاریەکان"
              img={images.detail}
            />
            <CategoriesCard link="top" title="بەرزترینەکان" img={images.top} />
            <CategoriesCard link="dive" title="قوڵبوونەوە" img={images.dive} />
            <CategoriesCard
              link="youtubers"
              title="یوتوبەرەکان"
              img={images.youtuber}
            />
            <CategoriesCard
              link="comingsoon"
              title="بەمزوانە"
              img={images.comingSoon}
            />
            <CategoriesCard
              link="company"
              title="کۆمپانیاکان"
              img={images.company}
            />
            <CategoriesCard link="genre" title="ژانراکان" img={images.genre} />
            <CategoriesCard
              link="console"
              title="کونسوڵەکان"
              img={images.console}
            />
            <CategoriesCard
              link="game-history"
              title="مێژووی یاریەکان"
              img={images.gameHistory}
            />
            <CategoriesCard
              link="game-series"
              title="زنجیرە یاریەکان"
              img={images.gameSeries}
            />
            <CategoriesCard
              link="recommend"
              title="پێشنیارکردن"
              img={images.recommend}
            />
            <CategoriesCard
              link="events"
              title="ئێڤێنتەکان"
              img={images.event}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
