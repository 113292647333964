import React from "react";
import { MdRotateRight } from "react-icons/md";

const ButtonLoading = () => {
  return (
    <span className="absolute  top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
      <MdRotateRight className="animate-spin text-2xl" />
    </span>
  );
};

export default ButtonLoading;
