import React from "react";
import images from "../../../assets/images";
import { useTheme } from "../../../context/ThemeContext";

const ChangeColor = () => {
  const { primaryColor, changePrimaryColor } = useTheme();
  const handleColorChange = (color) => {
    changePrimaryColor(color);
    document.documentElement.style.setProperty("--primary-color", color);
    localStorage.setItem("primaryColor", color);
  };
  console.log(primaryColor);
  return (
    <>
      <div className="bg-backgroundSecondary flex__start flex-col gap-10 p-5 rounded-md">
        <h1 className="sm:text-4xl text-2xl">ڕەنگی دڵخوازت دیاری بکە</h1>
        <span className="text-slate-300">
          *دەتوانیت بەپێی کونسوڵی دڵخوازت ڕەنگی ماڵپەر بگۆڕیت
        </span>
        <div className="flex justify-between items-center w-full gap-5">
          <button
            className="sm:w-[90px] sm:h-[90px] w-[60px] h-[60px] rounded-full relative overflow-hidden bg-blue"
            onClick={() => handleColorChange("#66c0f4")}
          >
            <img
              src={images.playstationLogo}
              className="opacity-20 w-full h-full"
              alt="playstation logo"
            />
          </button>
          <button
            className="sm:w-[90px] sm:h-[90px] w-[60px] h-[60px] rounded-full relative overflow-hidden bg-red"
            onClick={() => handleColorChange("#ff2e2e")}
          >
            <img
              src={images.nintendoLogo}
              className="opacity-20 w-full h-full"
              alt="nintendo logo"
            />
          </button>
          <button
            className="sm:w-[90px] sm:h-[90px] w-[60px] h-[60px] rounded-full relative overflow-hidden bg-green"
            onClick={() => handleColorChange("#20e87d")}
          >
            <img
              src={images.xboxLogo}
              className="opacity-20 w-full h-full"
              alt="xbox logo"
            />
          </button>
          <button
            className="sm:w-[90px] sm:h-[90px] w-[60px] h-[60px] rounded-full relative overflow-hidden bg-yellow"
            onClick={() => handleColorChange("#fff200")}
          >
            {/* <img
              src={images.playstationLogo}
              className="opacity-20 w-full h-full"
              alt="playstation logo"
            /> */}
          </button>
        </div>
      </div>
    </>
  );
};

export default ChangeColor;
