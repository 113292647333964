import React, { useEffect, useState } from "react";
import images from "../../assets/images";
import { BreadCrumb, GamePoster, Loading } from "../../components";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { AiFillEye, AiFillStar } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import { API_URL, IMG_URL } from "../../env";

// const GameSeriesDetail = () => {
//   const { id } = useParams();
//   const { data, loading, error } = useFetch(
//     `${API_URL}games/similar?tag=${id}`
//   );
//   const [getData, setGetData] = useState([]);
//   useEffect(() => {
//     if (!loading && data && data.data) {
//       setGetData(data?.data);
//       console.log(data);
//     }
//   }, [loading, data]);
//   if (loading || !data.data) {
//     return <Loading />;
//   } else {
//     return (
//       <section className="w-full flex__center mb-20 flex-col gap-10">
//         <div className="w-full absolute top-0 left-0">
//           <img
//             src={`${IMG_URL}games/background_image/${getData[0]?.background_image}`}
//             alt={getData[0].tag}
//             className="w-full h-[500px] object-cover opacity-25"
//           />
//         </div>
//         <div className="mt-20 flex__center gap-10 z-10 flex__center flex-col w-full max-w-[1250px] xl:px-0 px-3">
//           <div className="flex__start w-full mt-5">
//             <BreadCrumb
//               exactPlace={getData[0].tag}
//               secondPlace="زنجیرە یاریەکان"
//               secondPlaceLink="/game-series"
//             />
//           </div>
//           <div className="flex__center w-full gap-5 text-lg flex-col">
//             <h3 className="sm:text-5xl text-2xl text-primary font-bold tracking-wider change_font">
//               {getData[0].tag}
//             </h3>
//             {/* <h4 className="text-2xl change_font">2011 - 2022</h4> */}
//             <h4 className="text-lg">
//               <span className=" change_font">{getData?.length}</span> بەش
//             </h4>
//             {/* <div className="flex__center gap-3 text-lg w-full">
//               <span>ژانرا :</span>
//               <div className="flex justify-start items-center gap-1 overflow-hidden">
//                 {data?.data?.game_genres?.map((g, i) => (
//                   <>
//                     <Link
//                       to={`/genre/${g.id}`}
//                       title={g.title}
//                       className="max-w-[20ch] text-ellipsis overflow-hidden whitespace-nowrap sm:text-base text-sm"
//                     >
//                       {g.title}
//                     </Link>
//                     {i < data?.data?.game_genres?.length - 1 && (
//                       <BsDot className="text-[#a4a4a4] text-xl" />
//                     )}
//                   </>
//                 ))}
//               </div>
//             </div> */}
//             {/* <div className="flex__center gap-3 text-lg">
//               <span>کۆمپانیا :</span>
//               <Link
//                 to={`/company/${data?.data?.company?.id}`}
//                 title={data?.data?.company?.name}
//                 className="change_font"
//               >
//                 {data?.data?.company?.name}
//               </Link>
//             </div> */}
//           </div>
//         </div>
//         <div className="flex__start mt-28 gap-10 flex-col w-full max-w-[1250px] xl:px-0 px-3 mb-10">
//           {getData?.length > 0 && (
//             <div className="flex-wrap w-full flex items-start justify-start sm:gap-x-5 gap-x-2 gap-y-5">
//               {getData.map((game, i) => (
//                 <Link
//                   to={`/game-details/${game.id}`}
//                   title={game.title}
//                   key={i}
//                   className="flex__center sm:w-[220px] w-[48%] h-auto flex-col gap-0 rounded-md poster"
//                 >
//                   <div
//                     key={i}
//                     className="w-full h-auto rounded-lg overflow-hidden relative"
//                   >
//                     <span className="absolute top-0 left-0 bg-primary text-black flex__center px-2 text-xl rounded-br-3xl py-2">
//                       {i + 1}
//                     </span>
//                     <img
//                       title={game.title}
//                       src={game.poster_image}
//                       className="w-full h-[300px] poster-img"
//                       alt={game.title}
//                     />
//                     <div className="absolute flex-col duration-300 poster-content opacity-0 p-3 top-0 left-0 w-full h-full backdrop-blur flex justify-between items-start">
//                       <div className="w-full flex justify-between items-start gap-2">
//                         <div className="flex__center gap-1">
//                           {game.ign_rating}{" "}
//                           <AiFillStar className="text-xl text-primary" />
//                         </div>
//                       </div>
//                       <p className="w-full text-center primary-button">
//                         بینینی زیاتر
//                       </p>
//                       <div className="flex__center gap-1">
//                         {game.view_count} <AiFillEye className="text-xl" />
//                       </div>
//                     </div>
//                   </div>
//                   <h3 className="sm:text-xl text-base font-medium w-full text-ellipsis overflow-hidden change_direction whitespace-nowrap text-center change_font link_hover mt-2">
//                     {game.title}
//                   </h3>
//                 </Link>
//               ))}
//             </div>
//           )}
//         </div>
//       </section>
//     );
//   }
// };

// export default GameSeriesDetail;

const GameSeriesDetail = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(
    `${API_URL}games/similar?tag=${id}`
  );
  const [getData, setGetData] = useState([]);

  useEffect(() => {
    if (!loading && data && data.data) {
      setGetData(data.data);
      console.log(getData[0]?.tag);
    }
  }, [loading, data]);

  if (loading || !data || !data.data || !getData[0]) {
    return <Loading />;
  } else if (error) {
    // Handle error condition
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <section className="w-full flex__center mb-20 flex-col gap-10">
        <div className="w-full absolute top-0 left-0">
          <img
            src={`${IMG_URL}games/background_image/${getData[0]?.background_image}`}
            alt={getData[0]?.tag}
            className="w-full h-[500px] object-cover opacity-25"
          />
        </div>
        <div className="mt-20 flex__center gap-10 z-10 flex__center flex-col w-full max-w-[1250px] xl:px-0 px-3">
          <div className="mt-20 flex__center gap-10 z-10 flex__center flex-col w-full max-w-[1250px] xl:px-0 px-3">
            <div className="flex__start w-full mt-5">
              <BreadCrumb
                exactPlace={getData[0].tag}
                secondPlace="زنجیرە یاریەکان"
                secondPlaceLink="/game-series"
              />
            </div>
            <div className="flex__center w-full gap-5 text-lg flex-col">
              <h3 className="sm:text-5xl text-2xl text-primary font-bold tracking-wider change_font">
                {getData[0].tag}
              </h3>
              {/* <h4 className="text-2xl change_font">2011 - 2022</h4> */}
              <h4 className="text-lg">
                <span className=" change_font">{getData?.length}</span> بەش
              </h4>
              {/* <div className="flex__center gap-3 text-lg w-full">
              <span>ژانرا :</span>
              <div className="flex justify-start items-center gap-1 overflow-hidden">
                {data?.data?.game_genres?.map((g, i) => (
                  <>
                    <Link
                      to={`/genre/${g.id}`}
                      title={g.title}
                      className="max-w-[20ch] text-ellipsis overflow-hidden whitespace-nowrap sm:text-base text-sm"
                    >
                      {g.title}
                    </Link>
                    {i < data?.data?.game_genres?.length - 1 && (
                      <BsDot className="text-[#a4a4a4] text-xl" />
                    )}
                  </>
                ))}
              </div>
            </div> */}
              {/* <div className="flex__center gap-3 text-lg">
              <span>کۆمپانیا :</span>
              <Link
                to={`/company/${data?.data?.company?.id}`}
                title={data?.data?.company?.name}
                className="change_font"
              >
                {data?.data?.company?.name}
              </Link>
            </div> */}
            </div>
          </div>
        </div>
        <div className="flex__start mt-28 gap-10 flex-col w-full max-w-[1250px] xl:px-0 px-3 mb-10">
          {getData?.length > 0 && (
            <div className="flex-wrap w-full flex items-start justify-start sm:gap-x-5 gap-x-2 gap-y-5">
              {getData.map((game, i) => (
                <Link
                  to={`/game-details/${game.id}`}
                  title={game.title}
                  key={i}
                  className="flex__center sm:w-[220px] w-[48%] h-auto flex-col gap-0 rounded-md poster"
                >
                  <div
                    key={i}
                    className="w-full h-auto rounded-lg overflow-hidden relative"
                  >
                    <span className="absolute top-0 left-0 bg-primary text-black flex__center px-2 text-xl rounded-br-3xl py-2">
                      {i + 1}
                    </span>
                    <img
                      title={game.title}
                      src={`${IMG_URL}games/poster_image/${game.poster_image}`}
                      className="w-full h-[300px] poster-img"
                      alt={game.title}
                    />
                    <div className="absolute flex-col duration-300 poster-content opacity-0 p-3 top-0 left-0 w-full h-full backdrop-blur flex justify-between items-start">
                      <div className="w-full flex justify-between items-start gap-2">
                        <div className="flex__center gap-1">
                          {game.ign_rating}{" "}
                          <AiFillStar className="text-xl text-primary" />
                        </div>
                      </div>
                      <p className="w-full text-center primary-button">
                        بینینی زیاتر
                      </p>
                      <div className="flex__center gap-1">
                        {game.view_count} <AiFillEye className="text-xl" />
                      </div>
                    </div>
                  </div>
                  <h3 className="sm:text-xl text-base font-medium w-full text-ellipsis overflow-hidden change_direction whitespace-nowrap text-center change_font link_hover mt-2">
                    {game.title}
                  </h3>
                </Link>
              ))}
            </div>
          )}
        </div>
      </section>
    );
  }
};

export default GameSeriesDetail;
