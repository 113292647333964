import React from "react";
import { RxLapTimer } from "react-icons/rx";
import { Link } from "react-router-dom";

const ComingSoonCard = ({ img, title, link, date }) => {
  return (
    <>
      <Link
        to={link}
        title={title}
        className="overflow-hidden flex__start flex-col sm:basis-[48%] basis-[100%] lg:h-[300px] sm:h-[200px] sm:max-h-fit max-h-[200px] h-[200px] relative rounded-lg"
      >
        <div className="w-full rounded-lg overflow-hidden h-full">
          <img src={img} alt="title" className="w-full h-full object-cover" />
        </div>
        <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-30"></div>
        <div className="absolute bottom-[50%] min-w-max -translate-x-[50%] translate-y-[50%] left-[50%] flex__center flex-col gap-10 z-10">
          <h1 className="link__hover text-white min-w-fit change_font font-bold md:text-3xl text-xl">
            {title}
          </h1>
          <div className="flex__center gap-2">
            <div className="bg-background px-3 rounded flex__center flex-row-reverse gap-1 py-1 text-sm font-semibold change_font">
              <span>{date}</span>
              <span>
                <RxLapTimer className="text-primary text-2xl" />
              </span>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ComingSoonCard;
