import React from "react";
import { Link } from "react-router-dom";
import { ByWho } from "../../../components";

const ReviewsCard = ({
  link,
  img,
  title,
  text,
  imgPerson,
  time,
  namePerson,
  linkPerson,
}) => {
  return (
    <>
      <div className="flex__start xl:w-[580px] lg:w-[480px] sm:w-[48%] w-full bg-backgroundSecondary rounded-lg sm:p-3 p-2 lg:gap-10 gap-3 md:flex-row flex-col">
        <Link to={link} className="md:basis-[50%] w-full lg:h-[200px] h-auto">
          <img src={img} alt="" className="w-full h-full" />
        </Link>
        <div className="flex md:justify-start justify-between items-start md:flex-col flex-row sm:gap-3 gap-1 sm:basis-[50%] basis-[100%] w-full">
          <Link
            to={link}
            className="md:text-lg text-base link_hover max-w-[20ch] overflow-hidden text-ellipsis whitespace-nowrap"
          >
            {title}
          </Link>
          <ByWho
            imgPerson={imgPerson}
            time={time}
            title={namePerson}
            link={linkPerson}
          />
          <p className="text-slate-300 font-light text-sm lg:block hidden whitespace-normal">
            {text.substring(0, 90)}...
          </p>
          <Link
            to={link}
            title={title}
            className="primary-button md:flex hidden"
          >
            زیاتر بخوێنەوە
          </Link>
        </div>
      </div>
    </>
  );
};

export default ReviewsCard;
