import React, { useEffect, useState } from "react";
import images from "../../../assets/images";
import { HeaderComponent } from "../../../components/index";
import ReviewsCard from "../../../pages/Reviews/ReviewsComponent/ReviewsCard";
import useFetch from "../../../hooks/useFetch";
import { API_URL, IMG_URL } from "../../../env";
const ReviewComponent = () => {
  const { data, loading, error } = useFetch(
    `${API_URL}dives/all?table_type=review&perPage=4&page=1`
  );

  const [getData, setGetData] = useState([]);

  useEffect(() => {
    if (!loading && data && data.data && data.data.length > 0) {
      setGetData(data.data);
    }
  }, [data, loading]);
  const dateFormat = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  return (
    <>
      <div className="flex__center gap-5 w-full sm:my-20 my-10 flex-col">
        <HeaderComponent title="نوێترین پێداچوونەوەکان" link="characters" />

        <div className="flex__center w-full max-w-[1250px] gap-5 xl:px-0 px-3">
          <div className="w-full justify-between items-center flex flex-wrap gap-2">
            {getData.map((review) => (
              <ReviewsCard
                img={`${IMG_URL}dives/${review.background_image}`}
                imgPerson={
                  review.staff_image
                    ? `${IMG_URL}staff/poster/${review?.staff?.staff_image}`
                    : null
                }
                link={`/reviews/${review.id}`}
                linkPerson={`/staff/${review?.staff_id}`}
                namePerson={review?.staff_name}
                text={review.description}
                time={dateFormat(review.created_at)}
                title={review.title}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewComponent;
