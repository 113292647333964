import React, { useState, useEffect } from "react";
import { HeaderComponent } from "../../../components/index";

import images from "../../../assets/images/index";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
import CharactersCard from "../../Characters/CharactersComponents/CharactersCard";
import { Link } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { API_URL, IMG_URL } from "../../../env";

const CharactersComponent = () => {
  const { data, loading, error } = useFetch(
    `${API_URL}characters/all/?perPage=10&page=1`
  );

  const [getData, setGetData] = useState([]);

  useEffect(() => {
    if (!loading && data && data.data && data.data.length > 0) {
      setGetData(data.data);
    }
  }, [data, loading]);
  return (
    <>
      <div className="flex__center flex-col w-full gap-5 sm:my-20 my-10">
        <HeaderComponent title="نوێترین کەسایەتیەکان" link="characters" />
        <div className="relative w-full max-w-[1250px] px-3 xl:px-0">
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            navigation={true}
            freeMode={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: true,
            }}
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
              "--swiper-navigation-size": "26px",
            }}
            breakpoints={{
              500: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
            }}
            modules={[Pagination, Navigation, Autoplay]}
            className="w-full h-full"
          >
            {getData.map((character) => (
              <SwiperSlide>
                <div className="relative flex__center rounded duration-300 hover:bg-backgroundTerinary flex-col sm:w-[220px] w-auto sm:h-[335px] h-[270px] gap-2 sm:p-3 p-1 bg-backgroundSecondary">
                  <Link
                    to={`/characters/${character.id}`}
                    title={character.name}
                    className="w-full h-[80%]"
                  >
                    <img
                      src={`${IMG_URL}characters/${character.image}`}
                      className="w-full h-full"
                      alt={character.name}
                    />
                  </Link>
                  <Link
                    to={`/characters/${character.id}`}
                    title={character.name}
                    className="md:text-xl text-base change_font link_hover font-bold text-ellipsis w-full whitespace-nowrap overflow-hidden"
                  >
                    {character.name}
                  </Link>
                  <div className="flex justify-between items-center gap-2 w-full">
                    <span className="change_font sm:text-sm text-xs text-gray-300 text-ellipsis w-[10ch] whitespace-nowrap overflow-hidden">
                      {character.game_name}
                    </span>
                    <span className="sm:text-sm text-xs text-gray-300">
                      بەشداریەکان ({character.game_count})
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default CharactersComponent;
