import React, { useState } from "react";

const CreatedWarning = ({ icon, message, moreInfo }) => {
  const [show, setShow] = useState(true); // Use destructuring correctly

  const hideMessage = () => {
    setShow(false); // Use the setShow function to update the state
  };

  return (
    <>
      {show && (
        <>
          <div className="max-w-[500px] w-[90%] h-auto flex justify-center items-center flex-col gap-5 p-5 rounded-xl bg-backgroundSecondary fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-20">
            {icon}
            <h1 className="sm:text-2xl text-lg text-center">{message}</h1>
            {moreInfo && (
              <span className="text-slate-300 text-center">{moreInfo}</span>
            )}
            <button
              onClick={hideMessage}
              className="primary-button !bg-backgroundTerinary !text-white"
            >
              لایبدە
            </button>
          </div>
          <button
            className="bg-black bg-opacity-50 z-10 fixed top-0 left-0 w-full h-full"
            onClick={hideMessage}
          ></button>
        </>
      )}
    </>
  );
};

export default CreatedWarning;
