import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import ConsoleCard from "./ConsoleCard";
import { API_URL, IMG_URL } from "../../../env";

const SimilarConsole = ({ id }) => {
  const { data, loading, error } = useFetch(`${API_URL}consoles/similar/${id}`);

  const [getData, setGetData] = useState([]);

  useEffect(() => {
    if (!loading && data?.data?.length > 0) {
      setGetData(data.data);
    }
  }, [loading, getData, data]);
  return (
    <>
      {getData.length > 0 && (
        <div className="w-full flex__start flex-col gap-16">
          <h1 className="sm:text-4xl text-2xl">کونسوڵە هاوشێوەکان</h1>
          <div className="w-full  gap-x-5 gap-y-10 flex sm:justify-start justify-center sm:items-start items-center flex-wrap">
            {getData.map((item) => (
              <>
                <ConsoleCard
                  console={item.tag}
                  img={`${IMG_URL}consoles/${item.image}`}
                  link={`/console/${item.id}`}
                  title={item.name}
                  year={new Date(item.release_date).getFullYear()}
                  key={item.id}
                />
              </>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SimilarConsole;
