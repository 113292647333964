import React, { useEffect, useState } from "react";
import SendData from "../../../hooks/useSendData";
import { useParams } from "react-router-dom";
import { AiOutlineClose, AiOutlineCloudUpload } from "react-icons/ai";
import useFetch from "../../../hooks/useFetch";
import { ButtonLoading } from "../../../components";
import { API_URL } from "../../../env";
import axios from "axios";
import Cookies from "js-cookie";

const AddGameVideo = ({ key }) => {
  const { id } = useParams();
  const { createData, createError, createLoading, post } = SendData();

  const [childImg, setChildImg] = useState("");
  const [errorText, setErrorText] = useState("");

  const [isSent, setIsSent] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    game_id: id,
    background_image: "",
  });
  const [vidId, setVidId] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("title", formData.title);
    formDataToSend.append("game_id", formData.game_id);
    formDataToSend.append("background_image", formData.background_image); // Add the background_image to FormData

    try {
      await post(`${API_URL}game-videos/create`, formDataToSend);
      setIsSent(true);
      // console.log(isSent);
      setVidId(createData.id);
      // window.location.reload();
    } catch (error) {
      // Handle error if needed
      setErrorText("تکایە هەموو خانەکان پڕکەوە");
    }
  };
  const handleImageChangeVideo = (e) => {
    setFormData({ ...formData, background_image: e.target.files[0] });
    setChildImg(e.target.files[0]);
  };

  useEffect(() => {
    if (createError) {
    }
  }, [createError]);

  const [formOpen, setFormOpen] = useState(false);
  const handleFormOpen = () => {
    setFormOpen(!formOpen);
  };

  const [formDataUrl, setFormDataUrl] = useState({
    url: "",
    game_video_id: vidId,
    server_name: "",
  });
  const token = Cookies.get("token");

  const handleChangeUrl = (e) => {
    const { name, value } = e.target;
    setFormDataUrl({ ...formDataUrl, [name]: value });
  };
  const handleSubmitUrl = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${API_URL}game-videos/url/create`, formDataUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {}
  };

  return (
    <>
      <button key={key} onClick={handleFormOpen} className="primary-button">
        زیادکردنی ڤیدیۆ
      </button>
      {formOpen && (
        <div
          key={key}
          className="fixed p-5 rounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] bg-backgroundSecondary z-50 w-full max-w-[500px]"
        >
          {!isSent && (
            <div className="flex__start gap-5 flex-col w-full">
              <div className="w-full flex justify between items-center gap-1">
                <h1 className="text-2xl">زیادکردنی ڤیدیۆی یاریەکان</h1>
                <button
                  onClick={handleFormOpen}
                  className="bg-background p-1 rounded-full"
                >
                  <AiOutlineClose />
                </button>
              </div>
              <div className="flex__start flex-col gap-1 w-full">
                <label htmlFor="title" className="text-lg">
                  ناونیشان
                </label>
                <input
                  type="text"
                  id="title"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formData.title}
                  onChange={handleChange}
                  name="title"
                />
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="bg" className=" text-lg">
                  وێنە
                </label>
                <input
                  type="file"
                  id="bg"
                  className="hidden"
                  onChange={handleImageChangeVideo}
                  name="background_image"
                />
                <div className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent flex items-center justify-between">
                  <div className="mx-3">{childImg.name}</div>
                  <label
                    htmlFor="bg"
                    className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                  >
                    <AiOutlineCloudUpload />
                  </label>
                </div>
              </div>
              <p className="text-lg text-red">{errorText}</p>
              <button
                onClick={handleSubmit}
                type="submit"
                //   disabled={createLoading}
                className={`sm:w-[450px] w-[90%] relative text-white h-[45px] bg-background text-xl py-2 hover:bg-primary hover hover:text-black duration-300 rounded-md ${
                  createLoading ? "disabled" : ""
                }`}
              >
                {!createLoading && <span>ناردن</span>}
                {createLoading && <ButtonLoading />}
              </button>
            </div>
          )}
          {isSent && (
            <div className="flex__start gap-5 flex-col w-full">
              <div className="w-full flex justify between items-center gap-1">
                <h1 className="text-2xl">زیادکردنی سێرڤەر</h1>
                <button
                  onClick={handleFormOpen}
                  className="bg-background p-1 rounded-full"
                >
                  <AiOutlineClose />
                </button>
              </div>
              <div className="flex__start flex-col gap-1 w-full">
                <label htmlFor="url" className="text-lg">
                  لینک
                </label>
                <input
                  type="text"
                  id="url"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formDataUrl.url}
                  onChange={handleChangeUrl}
                  name="url"
                />
              </div>
              <div className="flex__start flex-col gap-1 w-full">
                <label htmlFor="server_name" className="text-lg">
                  سێرڤەر
                </label>
                <input
                  type="text"
                  id="server_name"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formDataUrl.server_name}
                  onChange={handleChangeUrl}
                  name="server_name"
                />
              </div>
              <p className="text-lg text-red">{errorText}</p>
              <button
                onClick={handleSubmitUrl}
                type="submit"
                //   disabled={createLoading}
                className={`sm:w-[450px] w-[90%] relative text-white h-[45px] bg-background text-xl py-2 hover:bg-primary hover hover:text-black duration-300 rounded-md ${
                  createLoading ? "disabled" : ""
                }`}
              >
                {!createLoading && <span>ناردن</span>}
                {createLoading && <ButtonLoading />}
              </button>
            </div>
          )}
        </div>
      )}

      {formOpen && (
        <button
          key={key}
          onClick={handleFormOpen}
          className="fixed top-0 left-0 bg-black w-full h-full bg-opacity-50 z-40"
        ></button>
      )}
    </>
  );
};

export default AddGameVideo;
