import React, { useContext, useEffect, useState } from "react";

import { Link, NavLink, useNavigate } from "react-router-dom";

import images from "../../assets/images/index";
import Search from "./Search";
import { HiMenuAlt3 } from "react-icons/hi";
import { FaUserAlt } from "react-icons/fa";
import { AuthContext, fetchUserFromAPI } from "../../context/authContext";
import Cookies from "js-cookie";
import SendData from "../../hooks/useSendData";
import { IMG_URL } from "../../env";

const Navbar = () => {
  const { createData, createError, createLoading, post } = SendData();
  const navigate = useNavigate();
  // const { user, loading, error, dispatch } = useContext(AuthContext);
  const [nav, setNav] = useState(false);
  const [account, setAccount] = useState(false);
  const handleNavbar = () => {
    setNav(!nav);
  };

  const authContext = useContext(AuthContext);
  const { dispatch, user } = authContext;

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (Cookies.get("token")) {
      // Call the fetchUserFromAPI function when the component mounts
      fetchUserFromAPI(dispatch)
        .then((userData) => {
          // Set the user data to the state variable
          setUserData(userData);
        })
        .catch((error) => {
          // Handle any errors here
          console.error("Error fetching user data:", error);
        });
    }
  }, [dispatch]);

  const handleAccount = () => {
    setAccount(!account);
  };
  const handleLogOut = async () => {
    // await post("https://gamewebsite.xyz/api/logout", null);

    // Clear the token cookie
    Cookies.remove("token");

    // Dispatch the "LOGOUT" action
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  const activeLink =
    "relative text-primary lg:text-lg md:text-[14px] sm:text-lg text-base hover:text-primary duration-300 border-b-2 border-b-primary";
  const normalLink =
    "hover:text-primary duration-300 lg:text-lg md:text-[14px] text-lg";
  return (
    <>
      <div className="flex__center w-full fixed  left-0 top-0 z-50 bg-black bg-opacity-25 backdrop-blur-xl">
        <div className="flex justify-between gap-3 items-center xl:px-0 px-3 w-full max-w-[1250px] lg:h-[70px] h-[60px]">
          <Link to="/" className="w-[90px] lg:w-[140px] h-auto">
            <img src={images.logo} alt="logo" className="w-full h-full" />
          </Link>
          <button onClick={handleNavbar} className="md:hidden block">
            <HiMenuAlt3 className="text-xl" />
          </button>
          <ul
            className={`flex md:justify-center md:items-center md:flex-row flex-col md:relative absolute lg:gap-5 gap-2 md:bg-transparent bg-backgroundSecondary top-0 md:w-fit  w-[250px] items-start md:max-w-fit justify-start md:p-0 p-4 gap-y-5 z-50 right-0 h-[100vh] md:h-fit duration-500 overflow-hidden ${
              nav !== true ? "max-w-0 p-0" : "max-w-[250px]"
            }`}
          >
            <li className="link_hover">
              <NavLink
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                to="/"
                title="home"
                onClick={handleNavbar}
              >
                سەرەکی
              </NavLink>
            </li>
            <li className="link_hover">
              <NavLink
                to="/games"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                title="games"
                onClick={handleNavbar}
              >
                یاریەکان
              </NavLink>
            </li>
            <li className="link_hover md:hidden block">
              <NavLink
                to="/game-series"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                title="game-series"
                onClick={handleNavbar}
              >
                زنجیرە یاریەکان
              </NavLink>
            </li>
            <li className="link_hover md:hidden block">
              <NavLink
                to="/details"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                title="details"
                onClick={handleNavbar}
              >
                وردەکاریەکان
              </NavLink>
            </li>
            <li className="link_hover md:hidden block">
              <NavLink
                to="/top"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                title="top"
                onClick={handleNavbar}
              >
                بەرزترینەکان
              </NavLink>
            </li>
            <li className="link_hover">
              <NavLink
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                title="characters"
                to="/characters"
                onClick={handleNavbar}
              >
                کەسایەتیەکان
              </NavLink>
            </li>
            <li className="link_hover">
              <NavLink
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                to="/news"
                title="news"
                onClick={handleNavbar}
              >
                هەواڵەکان
              </NavLink>
            </li>
            <li className="link_hover">
              <NavLink
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                to="/reviews"
                title="reviews"
                onClick={handleNavbar}
              >
                پێداچوونەوە
              </NavLink>
            </li>
            <li className="link_hover">
              <NavLink
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                to="/staff"
                title="staff"
                onClick={handleNavbar}
              >
                ستاف
              </NavLink>
            </li>
          </ul>
          <Search />
          {user && (
            <>
              <div
                onClick={handleAccount}
                className={`fixed w-full h-[100vh] top-0 z-10 left-0 ${
                  account === true ? "block" : "hidden"
                }`}
              ></div>
              {/* <h2>{userData.name}</h2> */}
              <div className="relative w-10 h-10 z-20">
                <button
                  onClick={handleAccount}
                  type="button"
                  className="w-10 h-10 rounded-full overflow-hidden"
                >
                  <img
                    src={
                      userData?.data?.profile_pic
                        ? `${IMG_URL}users/profile_pic/${userData?.data?.profile_pic}`
                        : images.ghostProfile
                    }
                    alt={userData?.data?.name}
                    className="w-full h-full"
                  />
                </button>
                <ul
                  className={`w-[170px] bg-backgroundSecondary flex justify-start items-start gap-3 flex-col rounded-md absolute duration-500 top-12 left-0 overflow-hidden ${
                    account === true ? "max-h-[500px]" : "max-h-0"
                  }`}
                >
                  <li className="pr-3 w-full mt-2">
                    <Link
                      onClick={handleAccount}
                      to="profile"
                      className="text-sm text-gray-300 link_hover block w-full"
                    >
                      هەژمار
                    </Link>
                  </li>
                  <li className="pr-3 w-full">
                    <Link
                      onClick={handleAccount}
                      to="settings"
                      className="text-sm text-gray-300 link_hover w-full block"
                    >
                      ڕێکخستن
                    </Link>
                  </li>
                  <li className="pb-3 pr-3 border-t-2 pt-1 border-t-background w-full">
                    <button
                      onClick={handleLogOut}
                      type="button"
                      className="text-sm text-start text-gray-300 link_hover w-full block"
                    >
                      چوونە دەرەوە
                    </button>
                  </li>
                </ul>
              </div>
            </>
          )}
          {!user && (
            <>
              <Link
                title="login"
                to="login"
                className="bg-primary  lg:px-4 lg:py-2 rounded-md text-black duration-300 hover:bg-secondary px-1 py-2 lg:text-base text-[12px] hidden sm:block"
              >
                چوونە ژووەوە
              </Link>
              <Link to="login" title="login" className="sm:hidden block ">
                <FaUserAlt to="signup" className=" text-lg" />
              </Link>
            </>
          )}
        </div>
        {nav === true && (
          <button
            onClick={handleNavbar}
            className="bg-black md:hidden block bg-opacity-30 z-10 fixed top-0 left-0 h-[100vh] w-full"
          ></button>
        )}
      </div>
    </>
  );
};

export default Navbar;
