import React, { useContext, useState } from "react";
import images from "../../../assets/images";
import { AiOutlineCloudUpload } from "react-icons/ai";
import SendData from "../../../hooks/useSendData";
import { AuthContext } from "../../../context/authContext";
import { ButtonLoading } from "../../../components";
import { API_URL } from "../../../env";
import { CreatedWarning } from "../../../admin/admin-components";
import { MdReportGmailerrorred } from "react-icons/md";

const ChangeInfo = () => {
  const authContext = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const { createData, createError, createLoading, post } = SendData();
  const [isSent, setIsSent] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    background: "",
    profile_pic: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("name", formData.name);
    formDataToSend.append("profile_pic", formData.profile_pic);
    formDataToSend.append("background", formData.background);
    try {
      await post(`${API_URL}auth/user/update`, formDataToSend);
      // await authContext.updateUserFromAPI();
      window.location.reload();
    } catch (err) {
      setErrorMessage(err);
    }
    setIsSent(true);
  };

  const [imagePreview, setImagePreview] = useState("");
  const [imgBg, setImgBg] = useState("");

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       setImagePreview(e.target.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  return (
    <>
      <div className="bg-backgroundSecondary flex__start sm:w-auto w-full flex-col gap-10 p-5 rounded-md">
        <h1 className="sm:text-4xl text-2xl">زانیاریەکان</h1>
        <div className="flex__start relative flex-col gap-y-1 w-full">
          <input
            type="text"
            id="username"
            placeholder="ناوی بەکارهێنەر"
            className="border-b outline-none bg-transparent sm:w-[350px] w-full pb-2"
            value={formData.name}
            onChange={handleChange}
            name="name"
          />
          {/* <span className="sm:w-[350px] w-full spanInput">{input.errroMessage}</span> */}
        </div>
        <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
          <label htmlFor="background_image">وێنەی باکگراوند</label>
          <input
            type="file"
            id="background_image"
            className="hidden"
            onChange={(e) => {
              setFormData({ ...formData, background: e.target.files[0] });
              setImgBg(e.target.files[0]);
            }}
            name="background"
          />
          <div className="outline-none border-slate-300 border rounded h-11 sm:w-[350px] w-full bg-transparent flex items-center justify-between">
            <div className="mx-3">{imgBg.name}</div>
            <label
              htmlFor="background_image"
              className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
            >
              <AiOutlineCloudUpload />
            </label>
          </div>
        </div>
        <div className="sm:w-[350px] w-full flex justify-between items-start">
          <label htmlFor="user-picture" className="primary-button">
            وێنە دابنێ
          </label>
          <label
            htmlFor="user-picture"
            className="w-[100px] h-[100px] bg-background overflow-hidden rounded-full"
          >
            <img
              src={
                imagePreview
                  ? URL.createObjectURL(imagePreview)
                  : images.ghostProfile
              }
              className="w-full h-full"
              alt="defaultImage"
            />
          </label>
          <input
            type="file"
            accept="image/*"
            id="user-picture"
            hidden
            onChange={(e) => {
              setFormData({ ...formData, profile_pic: e.target.files[0] });
              setImagePreview(e.target.files[0]);
            }}
          />
        </div>
        <button
          type="submit"
          onClick={handleSubmit}
          className="sm:w-[350px] w-full duration-300 relative h-[40px] flex__center transition-colors hover:bg-backgroundTerinary bg-background text-base rounded-md px-2 py-2"
        >
          {!createLoading && <span>نوێ کردنەوە</span>}
          {createLoading && <ButtonLoading />}
        </button>
      </div>
      {errorMessage && errorMessage.response && (
        <CreatedWarning
          icon={<MdReportGmailerrorred className="text-9xl text-red" />}
          message={errorMessage.response.data.message}
        />
      )}
    </>
  );
};

export default ChangeInfo;
