import React from "react";
import { Link } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi";

const HeaderComponent = ({ title, link }) => {
  return (
    <>
      <div className="w-full max-w-[1250px] px-3 xl:px-0 flex justify-between items-center">
        {/* <h1 className="md:text-3xl text-xl">{title}</h1> */}
        <Link
          to={link}
          title="see more"
          className="flex__center gap-0 duration-300 hover:mr-2 hover:bg-backgroundSecondary md:text-3xl text-xl hover:px-2 rounded-md"
        >
          <span>{title}</span>
          <span>
            <BiChevronLeft className="sm:text-4xl text-lg text-primary" />
          </span>
        </Link>
      </div>
    </>
  );
};

export default HeaderComponent;
