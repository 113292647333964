import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import SendData from "../../../hooks/useSendData";
import { CreatedWarning, Navbar } from "../../admin-components";
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
import images from "../../../assets/images";
import { MdReportGmailerrorred } from "react-icons/md";
import { ButtonLoading } from "../../../components";
import { API_URL } from "../../../env";

const AddGenre = () => {
  const { createData, createError, createLoading, post } = SendData();

  const { data, loading, error, refetch } = useFetch(`${API_URL}staff/list`);
  const [getData, setGetData] = useState([]);
  useEffect(() => {
    if (!loading && data?.data?.length > 0) {
      setGetData(data.data);
    }
  }, [loading, getData, data]);

  const [img, setImg] = useState("");

  const [isSent, setIsSent] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    background_image: "",
    read_duration: "",
    popularity: "",
    staff_id: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("read_duration", formData.read_duration);

    formDataToSend.append("background_image", formData.background_image);

    formDataToSend.append("popularity", formData.popularity);
    formDataToSend.append("staff_id", formData.staff_id);

    try {
      await post(`${API_URL}genres/create`, formDataToSend);
      setIsSent(true);
    } catch (error) {
      setIsSent(false);
    }
  };
  useEffect(() => {
    if (createError) {
    }
  }, [createError]);
  return (
    <>
      <Navbar
        exactPlace="زیادکردنی ژانرا"
        secondPlace="ژانرا"
        secondPlaceLink="/admin/genre"
      />

      <div className="flex__center flex-col w-full xl:flex-[0.8] mb-10 flex-1 mt-28 gap-8">
        <div className="flex__center md:flex-row flex-col md:gap-10 gap-5 w-full">
          <div className="flex__start flex-col gap-3 sm:w-auto w-full">
            <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="title" className=" text-lg">
                ناونیشان
              </label>
              <input
                type="text"
                id="title"
                className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                value={formData.title}
                onChange={handleChange}
                name="title"
              />
            </div>
            <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="img" className=" text-lg">
                وێنە
              </label>
              <input
                type="file"
                id="img"
                disabled={false}
                className="hidden"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    background_image: e.target.files[0],
                  });
                  setImg(e.target.files[0]);
                }}
                name="background_image"
              />
              <div className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent flex items-center justify-between">
                <div className="mx-3">{img.name}</div>
                <label
                  htmlFor="img"
                  className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                >
                  <AiOutlineCloudUpload />
                </label>
              </div>
            </div>
          </div>
          <label
            htmlFor="img"
            className="sm:w-[250px] sm:h-[150px] max-h-[300px] w-full h-auto bg-secondary rounded-[5px] overflow-hidden"
          >
            <img
              src={img ? URL.createObjectURL(img) : images.noImg}
              alt="img"
              className="w-full h-full object-cover"
            />
          </label>
        </div>
        <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="read_duration">ماوەی خوێندنەوە</label>
            <input
              type="text"
              id="read_duration"
              className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
              value={formData.read_duration}
              onChange={handleChange}
              name="read_duration"
            />
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="popularity">ڕیزبەندی</label>
            <input
              type="text"
              id="popularity"
              className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
              value={formData.popularity}
              onChange={handleChange}
              name="popularity"
            />
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="staff_id">لەلایەن</label>
            <select
              name="staff_id"
              id="staff_id"
              onChange={handleChange}
              className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[230px] w-full p-2.5 bg-backgroundSecondary"
              value={formData.staff_id}
            >
              <option value="0">ستاف هەڵبژێرە</option>
              {getData.map((staff) => (
                <option value={staff.id}>{staff.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
          <label htmlFor="textarea">پێناسەکردنی</label>
          <textarea
            name="description"
            id="textarea"
            value={formData.description}
            onChange={handleChange}
            className="outline-none border-slate-300 border rounded h-32 md:w-[720px] sm:w-[450px] w-full bg-transparent px-2"
          />
        </div>
        <button
          onClick={handleSubmit}
          type="submit"
          disabled={createLoading}
          className={`sm:w-[450px] w-[90%] relative h-[45px] bg-backgroundSecondary text-xl py-2 hover:bg-primary hover:text-black duration-300 rounded-md ${
            createLoading ? "disabled" : ""
          }`}
        >
          {!createLoading && <span>ناردن</span>}
          {createLoading && <ButtonLoading />}
        </button>
      </div>
      {isSent && !createError && (
        <CreatedWarning
          icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
          message={"بابەتەکە بەسەرکەوتوویی دروستکرا"}
        />
      )}
      {createError && createError.response && (
        <CreatedWarning
          icon={<MdReportGmailerrorred className="text-9xl text-red" />}
          message={"تکایە لە دڵنیاببەوە هەموو زانیاریەکانت بەڕاستی داناوە"}
          moreInfo={
            createError && createError.response
              ? createError.response.data.message
              : "An error occurred"
          }
        />
      )}
    </>
  );
};

export default AddGenre;
