import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
import images from "../../../../assets/images";
import { GamePoster } from "../../../../components";
import { Link, useParams } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import { AiFillEye, AiFillStar } from "react-icons/ai";
import useFetch from "../../../../hooks/useFetch";
import { API_URL } from "../../../../env";

const SimilarGameDetails = (tag) => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(
    `${API_URL}games/similar?tag=${tag.tag}`
  );
  const [getData, setGetData] = useState([]);

  useEffect(() => {
    if (!loading && data && data.data && data.data.length > 0) {
      setGetData(data.data);
    }
  }, [data, loading]);
  return (
    <>
      {getData.length > 0 && (
        <div className="w-full flex__center gap-5 flex-col">
          <h2 className="md:text-3xl text-2xl">یاریە هاوشێوەکان</h2>
          <div className="relative w-full">
            <Swiper
              slidesPerView={2}
              spaceBetween={10}
              navigation={true}
              freeMode={true}
              autoplay={{
                delay: 3500,
                disableOnInteraction: true,
              }}
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
                "--swiper-navigation-size": "26px",
              }}
              breakpoints={{
                640: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                900: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1100: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}
              modules={[Pagination, Navigation, Autoplay]}
              className="w-full h-full"
            >
              {getData.map((game) => (
                <SwiperSlide>
                  <div className="flex__center sm:w-[220px] w-auto h-auto flex-col gap-0 rounded-md poster">
                    <Link
                      to={`/game-details/${game.id}`}
                      className="w-full h-auto rounded-lg overflow-hidden relative"
                    >
                      <img
                        src={game.poster_image}
                        className="w-full h-[300px] poster-img"
                        alt={game.title}
                      />
                      <div className="absolute flex-col duration-300 poster-content opacity-0 p-3 top-0 left-0 w-full h-full backdrop-blur flex justify-between items-start">
                        <div className="flex__center gap-1">
                          {game.ign_rating}{" "}
                          <AiFillStar className="text-xl text-primary" />
                        </div>
                        <h2 className="w-full text-center primary-button">
                          بینینی زیاتر
                        </h2>
                        <div className="flex__center gap-1">
                          {game.view_count} <AiFillEye className="text-xl" />
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={`/game-details/${game.id}`}
                      title={game.title}
                      className="sm:text-xl text-base font-medium w-full text-ellipsis overflow-hidden change_direction whitespace-nowrap text-center change_font link_hover mt-2"
                    >
                      {game.title}
                    </Link>
                    <div className="flex__center gap-1 w-full">
                      {JSON.parse(game.genres).map((g, i) => (
                        <>
                          <span
                            key={g.id}
                            className="max-w-[12ch] sm:text-base text-sm text-ellipsis overflow-hidden whitespace-nowrap"
                          >
                            {g.title}
                          </span>
                          {i < game.genres.length - 1 && (
                            <BsDot className="text-[#a4a4a4] text-xl" />
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </>
  );
};

export default SimilarGameDetails;
