import React, { useEffect, useState } from "react";
import { FaFire } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Autoplay } from "swiper";
import { Link } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { AiFillStar } from "react-icons/ai";
import { API_URL, IMG_URL } from "../../../env";
const Subtitles = () => {
  const { data, loading, error } = useFetch(
    `${API_URL}games/slider?isTranslated=1`
  );

  const [getData, setGetData] = useState([]);
  useEffect(() => {
    if (!loading && data && data.data && data.data.length > 0) {
      setGetData(data.data);
    }
  }, [data, loading]);

  const getYear = (dateString) => {
    const date = new Date(dateString);
    if (!isNaN(date)) {
      return date.getFullYear();
    } else {
      return "بەرواری نیە";
    }
  };
  return (
    <>
      <div className="flex__center gap-5 w-full my-10 flex-col">
        <div className="flex__center gap-2">
          <FaFire className="text-2xl" />
          <h1 className="text-2xl">نوێترین ژێرنوس کراوەکان</h1>
          <FaFire className="text-2xl" />
        </div>

        <div className="w-full max-w-[1250px] gap-5 xl:px-0 px-3 relative">
          <Swiper
            pagination={{
              type: "progressbar",
            }}
            slidesPerView={1}
            spaceBetween={0}
            modules={[Pagination, Autoplay]}
            className="w-full h-full"
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              900: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1100: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {getData.map((game, i) => (
              <SwiperSlide className="w-full h-full min-h-[200px]" key={i}>
                <Link
                  to={`/game-details/${game.id}`}
                  title={game.title}
                  className="w-full h-[205px] block relative rounded overflow-hidden "
                >
                  <div className="absolute left-0 top-0 w-full h-full shadow_inner opacity-0 duration-300 hover:opacity-100 z-10"></div>
                  <div className="w-full h-full rounded overflow-hidden">
                    <img
                      src={`${IMG_URL}games/background_image/${game.background_image}`}
                      alt={game.title}
                      className="w-full h-full object-cover bg-cover bg-center"
                    />
                  </div>
                  <div className="absolute left-0 top-0 w-full h-full bg-gradient-to-b from-[#00000000] to-[#00000080]"></div>
                  <div className="absolute z-10 bottom-[20px] right-[20px] flex items-start justify-start gap-2 flex-col">
                    <h1 className="change_font md:text-2xl text-xl link_hover">
                      {game.title}
                    </h1>
                    <div className="flex__center gap-4">
                      <span className="text-primary change_font">
                        {getYear(game.release_date)}
                      </span>
                      <div className="flex__center gap-1">
                        <span className="text-primary change_font">
                          {game.ign_rating}
                        </span>
                        <AiFillStar className="text-xl text-primary" />
                      </div>
                      <span className="text-primary change_font">
                        {game.duration}h
                      </span>
                      {JSON.parse(game.genres).map((genre, i) => (
                        <span
                          className="text-primary text-sm text-ellipsis md:max-w-[15ch] max-w-[10ch] whitespace-nowrap overflow-hidden"
                          key={i}
                        >
                          {genre.title}
                        </span>
                      ))}
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Subtitles;
