import React, { useState } from "react";
import SendData from "../../../hooks/useSendData";
import { CreatedWarning } from "../../../admin/admin-components";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdReportGmailerrorred } from "react-icons/md";
import { API_URL } from "../../../env";
import axios from "axios";
import Cookies from "js-cookie";

const ChangePassword = () => {
  const [isSent, setIsSent] = useState(false);
  const { createData, createError, createLoading, post } = SendData();

  const [crudentials, setCrudentials] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCrudentials({ ...crudentials, [name]: value });
  };

  const token = Cookies.get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_URL}auth/user/update-password`, crudentials, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setIsSent(true);
    } catch (e) {}
    if (createError) {
      // console.log("logged");
    }
  };
  return (
    <>
      <div className="bg-backgroundSecondary flex__start sm:w-auto w-full flex-col gap-10 p-5 rounded-md">
        <h1 className="sm:text-4xl text-2xl">گۆڕینی تێپەرە وشە</h1>
        <div className="flex__start relative flex-col gap-y-1 w-full">
          <input
            type="password"
            name="old_password"
            id="password"
            onChange={handleChange}
            value={crudentials.old_password}
            placeholder="تێپەرە وشەی کۆن"
            className="border-b outline-none bg-transparent sm:w-[350px] w-full pb-2"
          />
          {/* <span className="sm:w-[350px] w-full spanInput">{input.errroMessage}</span> */}
        </div>
        <div className="flex__start relative flex-col gap-y-1 w-full">
          <input
            type="password"
            name="new_password"
            id="password"
            onChange={handleChange}
            value={crudentials.new_password}
            placeholder="تێپەرە وشەی نوێ"
            className="border-b outline-none bg-transparent sm:w-[350px] w-full pb-2"
          />
          {/* <span className="sm:w-[350px] w-full spanInput">{input.errroMessage}</span> */}
        </div>
        <div className="flex__start relative flex-col gap-y-1 w-full">
          <input
            type="password"
            onChange={handleChange}
            value={crudentials.confirm_new_password}
            name="confirm_new_password"
            id="password"
            placeholder="دڵنیابوونەوەلە تێپەرە وشە"
            className="border-b outline-none bg-transparent sm:w-[350px] w-full pb-2"
          />
          {/* <span className="sm:w-[350px] w-full spanInput">{input.errroMessage}</span> */}
        </div>
        <button
          onClick={handleSubmit}
          type="submit"
          className="sm:w-[350px] w-full duration-300 transition-colors hover:bg-backgroundTerinary bg-background text-base rounded-md px-2 py-2"
        >
          نوێ کردنەوە
        </button>
        {isSent && !createError && (
          <CreatedWarning
            icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
            message={"بە سەرکەوتوویی تێپەرە وشە نوێکرایەوە"}
          />
        )}
        {createError && createError.response && (
          <CreatedWarning
            icon={<MdReportGmailerrorred className="text-9xl text-red" />}
            message={"تکایە لە دڵنیاببەوە هەموو زانیاریەکانت بەڕاستی داناوە"}
            moreInfo={createError.response.data.message}
          />
        )}
      </div>
    </>
  );
};

export default ChangePassword;
