import React from "react";
import { AiOutlineEye } from "react-icons/ai";
import images from "../../assets/images";
import { BreadCrumb, ByWho, Loading } from "../../components";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { API_URL, IMG_URL } from "../../env";

const GenresDetail = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(`${API_URL}genres/view/${id}`);

  const dateFormat = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  if (loading || !data.data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section">
          <div className="w-full max-w-[1250px] xl:px-0 px-3 flex__center flex-col gap-10 mb-10">
            <div className="flex__start w-full">
              <BreadCrumb
                exactPlace={data.data.title}
                secondPlace="ژانراکان"
                secondPlaceLink="/genres"
                key={data.data.id}
              />
            </div>
            <div className="w-full flex__center flex-col gap-5">
              <img
                src={`${IMG_URL}genres/${data.data.background_image}`}
                alt={data.data.title}
                className="w-full sm:h-[400px] h-auto rounded-lg"
              />
              <div className="w-full flex__start flex-wrap sm:gap-x-10 gap-x-5 gap-y-3">
                <ByWho
                  imgPerson={
                    data?.data?.staff?.image
                      ? `${IMG_URL}staff/poster/${data?.data?.staff?.image}`
                      : null
                  }
                  link={`/staff/${data?.data?.staff?.id}`}
                  time={dateFormat(data.data.created_at)}
                  title={data?.data?.staff?.name}
                />
                <div className="flex__center gap-2">
                  <span className="text-gray-300">کاتی خوێندنەوە : </span>
                  <span>
                    <span className="change_font">
                      {data.data.read_duration}
                    </span>
                    خولەک
                  </span>
                </div>
                <div className="flex__center gap-2">
                  <AiOutlineEye className="text-2xl" />
                  <span className="change_font">{data.data.view_count}</span>
                </div>
              </div>
              <div className="w-full border-t border-t-backgroundTerinary pt-5 flex__start flex-col gap-5">
                <h2 className="text-2xl">{data.data.title}</h2>
                <p className="paragraph">{data.data.description}</p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default GenresDetail;
