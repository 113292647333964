import React from "react";
import { Link } from "react-router-dom";

const EventsCard = ({ link, title, date, img, id }) => {
  return (
    <>
      <Link
        to={link}
        key={id}
        title={title}
        className="w-full relative sm:h-[300px] h-auto rounded-lg overflow-hidden"
      >
        <img src={img} alt={title} className="w-full h-full relative z-10" />
        <div className="w-full h-full bg-gradient-to-r from-slate-50 to-slate-900 absolute left-0 top-0"></div>
        <div className="flex sm:items-start sm:justify-start justify-center items-center w-full absolute top-[50%] sm:right-10 right-[50%] sm:translate-x-0 translate-x-[50%] -translate-y-[50%]  z-20 sm:gap-10 gap-5 flex-col">
          <h2 className="sm:text-3xl text-lg change_font">{title}</h2>
          <h1 className="sm:text-4xl text-xl change_font">{date}</h1>
          <p className="primary-button">زیاتر بخوێنەوە</p>
        </div>
      </Link>
    </>
  );
};

export default EventsCard;
