import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import images from "../../assets/images/index";
import { AuthContext } from "../../context/authContext";
import { CreatedWarning } from "../../admin/admin-components";
import { MdReportGmailerrorred } from "react-icons/md";
import SendData from "../../hooks/useSendData";
import { ButtonLoading } from "../../components";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { API_URL } from "../../env";
import axios from "axios";
import Cookies from "js-cookie";

const SignUp = () => {
  // const [isSent, setIsSent] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const { createData, createError, createLoading, post } = SendData();
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    password: "",
    // password_confirmation: "",
  });
  const [modal, setModal] = useState(false);

  const { user, loading, error, dispatch } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    setLoginLoading(true);
    try {
      // await post(`${API_URL}register`, formData);
      const res = await axios.post(`${API_URL}auth/user/register`, formData);
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data.data.user });
      const userData = res.data.data;
      const token = res.data.token;
      Cookies.set("token", token, { expires: 365 });
      setLoginLoading(false);
      navigate("/");
    } catch (err) {
      setErrorMessage(err);
      setLoginLoading(false);
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data.message });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <section>
      <div className="w-full flex__center h-[100vh] gap-10">
        <img
          src={images.auth}
          alt="auth"
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
        <div className="flex sm:w-auto w-[95%] md:items-start sm:justify-start justify-center items-center flex-col gap-4 relative backdrop-blur-md bg-black bg-opacity-30 p-5 rounded-lg">
          <Link to="/" className="w-40 h-auto">
            <img src={images.logo} alt="logo" className="w-full h-full" />
          </Link>
          <h1 className="text-3xl">خۆ تۆمارکردن</h1>
          <span className="text-slate-400 text-sm">
            تکایە هەموو وردەکاریەکان دابنێ
          </span>
          <form className="flex__start flex-col gap-5">
            <div className="flex__start relative flex-col gap-y-1">
              <label htmlFor="name">ناوی بەکارهێنەر:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                id="name"
                placeholder="ناوی بەکارهێنەر"
                className="border-b outline-none bg-transparent w-[350px]"
              />
            </div>
            <div className="flex__start relative flex-col gap-y-1">
              <label htmlFor="email">ئیمەیڵ:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                id="email"
                placeholder="ئیمەیڵ"
                className="border-b outline-none bg-transparent w-[350px]"
              />
            </div>
            <div className="flex__start relative flex-col gap-y-1">
              <label htmlFor="password">تێپەرە وشە:</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                id="password"
                placeholder="تێپەرە وشە"
                className="border-b outline-none bg-transparent w-[350px]"
              />
            </div>
            {/* <div className="flex__start relative flex-col gap-y-1">
              <label htmlFor="password_confirmation">
                دڵنیاکردنەوەی تێپەرە وشە:
              </label>
              <input
                type="password"
                name="password_confirmation"
                value={formData.password_confirmation}
                onChange={handleChange}
                id="password_confirmation"
                placeholder="تێپەرە وشە"
                className="border-b outline-none bg-transparent w-[350px]"
              />
            </div> */}
            <button
              type="submit"
              onClick={handleSubmit}
              disabled={loginLoading}
              className={`w-[350px] relative h-[45px] duration-300 transition-colors hover:bg-backgroundTerinary bg-backgroundSecondary text-base rounded-md px-2 py-2 ${
                loginLoading ? "disabled" : ""
              }`}
            >
              {!loginLoading && <span> خۆ تۆمارکردن</span>}
              {loginLoading && <ButtonLoading />}
            </button>
          </form>
          <h1 className="text-base">
            <span>هەژمارت هەیە؟</span>
            <Link title="title" className="text-primary mx-3" to="/login">
              چوونە ژوورەوە
            </Link>
          </h1>
        </div>
        {/* {modal && (
          <>
            <div className="max-w-[500px] w-[90%] h-auto flex justify-center items-center flex-col gap-5 p-5 rounded-xl bg-backgroundSecondary fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-20">
              <AiOutlineCheckCircle className="text-9xl text-green" />
              <h1 className="sm:text-2xl text-lg text-center">
                هەژمارت دروستکرا
              </h1>
              <span className="text-slate-300 text-center">
                ئێستا دەتوانیت بچیتە ناو ماڵپەرەوە
              </span>
              <Link to="/login" title="login" className="primary-button">
                چوونە ژوورەوە
              </Link>
            </div>
            <Link
              to="/login"
              title="login"
              className="w-full h-full fixed top-0 left-0 bg-black bg-opacity-25 z-10"
            ></Link>
          </>
        )} */}
        {errorMessage && errorMessage.response && (
          <CreatedWarning
            icon={<MdReportGmailerrorred className="text-9xl text-red" />}
            message={"هەڵە ڕوویدا"}
            moreInfo={errorMessage.response.data.message}
          />
        )}
      </div>
    </section>
  );
};

export default SignUp;
