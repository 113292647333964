import React, { useState, useEffect } from "react";
import { GamePoster, HeaderComponent } from "../../../components/index";

import images from "../../../assets/images/index";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";

import "./GamesComponent.css";
import { Link } from "react-router-dom";
import { AiFillEye, AiFillStar } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import useFetch from "../../../hooks/useFetch";
import { API_URL, IMG_URL } from "../../../env";

const GamesComponent = () => {
  const { data, loading, error } = useFetch(
    `${API_URL}games/all?perPage=10&page1`
  );

  const [getData, setGetData] = useState([]);

  useEffect(() => {
    if (!loading && data && data.data && data.data.length > 0) {
      setGetData(data.data);
    }
  }, [data, loading]);
  if (!loading || data.data || getData.length > 0) {
    return (
      <>
        <div className="flex__center flex-col w-full gap-5 sm:my-20 my-10">
          <HeaderComponent title="نوێترین یاریەکان" link="games" />
          <div className="relative w-full max-w-[1250px] xl:px-0 px-3">
            <Swiper
              slidesPerView={2}
              spaceBetween={10}
              navigation={true}
              freeMode={true}
              autoplay={{
                delay: 3500,
                disableOnInteraction: true,
              }}
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
                "--swiper-navigation-size": "26px",
              }}
              breakpoints={{
                640: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                900: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1100: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}
              modules={[Pagination, Navigation, Autoplay]}
              className="w-full h-full"
            >
              {getData.map((game) => (
                <SwiperSlide key={game.id}>
                  <div className="flex__center sm:w-[220px] w-auto h-auto flex-col gap-0 rounded-md poster">
                    <Link
                      to={`/game-details/${game.id}`}
                      className="w-full h-auto rounded-lg overflow-hidden relative"
                    >
                      <img
                        src={`${IMG_URL}games/poster_image/${game.poster_image}`}
                        className="w-full h-[300px] poster-img"
                        alt={game.title}
                      />
                      <div className="absolute flex-col duration-300 poster-content opacity-0 p-3 top-0 left-0 w-full h-full backdrop-blur flex justify-between items-start">
                        <div className="flex__center gap-1">
                          {game.ign_rating}{" "}
                          <AiFillStar className="text-xl text-primary" />
                        </div>
                        <h2 className="w-full text-center primary-button">
                          بینینی زیاتر
                        </h2>
                        <div className="flex__center gap-1">
                          {game.view_count} <AiFillEye className="text-xl" />
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={`/game-details/${game.id}`}
                      title={game.title}
                      className="sm:text-xl text-base font-medium w-full text-ellipsis overflow-hidden change_direction whitespace-nowrap text-center change_font link_hover mt-2"
                    >
                      {game.title}
                    </Link>
                    <div className="flex__center w-full">
                      {JSON.parse(game.genres).map((g, i) => (
                        <>
                          <span className="w-[12ch] text-ellipsis overflow-hidden whitespace-nowrap sm:text-base text-sm">
                            {g.title}
                          </span>
                          {i < game.genres.length - 1 && (
                            <BsDot className="text-[#a4a4a4] text-xl" />
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </>
    );
  }
};

export default GamesComponent;
