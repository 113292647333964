import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CreatedWarning, Navbar } from "../../admin-components";
import { MdReportGmailerrorred } from "react-icons/md";
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
import useFetch from "../../../hooks/useFetch";
import SendData from "../../../hooks/useSendData";
import images from "../../../assets/images";
import { ButtonLoading, Loading } from "../../../components";
import { API_URL, IMG_URL } from "../../../env";

const UpdateCompany = () => {
  const { id } = useParams();
  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
  } = useFetch(`${API_URL}companies/view/${id}`);

  const { createData, createError, createLoading, post } = SendData();

  const { data, loading, error, refetch } = useFetch(`${API_URL}staff/list`);
  const [getData, setGetData] = useState([]);
  useEffect(() => {
    if (!loading && data?.data?.length > 0) {
      setGetData(data.data);
    }
  }, [loading, getData, data]);

  const [img, setImg] = useState("");
  const [imgBg, setImgBg] = useState("");

  const [isSent, setIsSent] = useState(false);

  const defaultFormData = {
    name: "",
    description: "",
    background: "",
    started_at: "",
    popularity: "",
    logo: "",
    is_company: "1",
    read_duration: "",
    staff_id: "",
  };

  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    if (!companyLoading && companyData) {
      // Update formData with data when available
      setFormData({
        name: companyData?.data?.name || "", // Default name value
        description: companyData?.data?.description || "", // Default description value
        background: "", // Leave empty for the image field, or set it to a default value if needed
        logo: "", // Leave empty for the image field, or set it to a default value if needed
        started_at: companyData?.data?.started_at || "", // Default started_at value
        read_duration: companyData?.data?.read_duration || "", // Default read_duration value
        is_company: companyData?.data?.is_company || "1", // Default is_company value
        popularity: companyData?.data?.popularity || "", // Default popularity value
        staff_id: companyData?.data?.staff?.id || "", // Default staff value
      });
    }
  }, [companyData, companyLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("name", formData.name);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("started_at", formData.started_at);

    formDataToSend.append("background", formData.background);
    formDataToSend.append("logo", formData.logo);
    formDataToSend.append("is_company", formData.is_company);

    formDataToSend.append("popularity", formData.popularity);
    formDataToSend.append("read_duration", formData.read_duration);
    formDataToSend.append("staff_id", formData.staff_id);

    try {
      await post(`${API_URL}companies/update/${id}`, formDataToSend);
      setIsSent(true);
    } catch (error) {}
  };
  useEffect(() => {
    if (createError) {
    }
  }, [createError]);
  if (loading || !data.data || !companyData.data) {
    return <Loading />;
  } else {
    return (
      <>
        <Navbar
          exactPlace="زیادکردنی کۆمپانیا"
          secondPlace="کۆمپانیا"
          secondPlaceLink="/admin/company"
          key={id}
        />

        <div className="flex__center flex-col w-full xl:flex-[0.8] mb-10 flex-1 mt-28 gap-8">
          <div className="flex__center md:flex-row flex-col md:gap-10 gap-5 w-full">
            <div className="flex__start flex-col gap-3 sm:w-auto w-full">
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="name" className=" text-lg">
                  ناو
                </label>
                <input
                  type="text"
                  id="name"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formData.name}
                  onChange={handleChange}
                  name="name"
                />
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="img" className=" text-lg">
                  لۆگۆ
                </label>
                <input
                  type="file"
                  id="img"
                  disabled={false}
                  className="hidden"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      logo: e.target.files[0],
                    });
                    setImg(e.target.files[0]);
                  }}
                  name="logo"
                />
                <div className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent flex items-center justify-between">
                  <div className="mx-3 w-full text-ellipsis overflow-hidden whitespace-nowrap">
                    {img.name || companyData.data.logo}
                  </div>
                  <label
                    htmlFor="img"
                    className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                  >
                    <AiOutlineCloudUpload />
                  </label>
                </div>
              </div>
            </div>
            <label
              htmlFor="img"
              className="w-[200px] h-[200px] bg-backgroundSecondary rounded-full overflow-hidden"
            >
              <img
                src={
                  img
                    ? URL.createObjectURL(img)
                    : `${IMG_URL}companies/logo/${companyData?.data?.logo}`
                }
                alt="img"
                className="w-full h-full object-cover"
              />
            </label>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="popularity">ڕیزبەندی</label>
              <input
                type="text"
                id="popularity"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
                value={formData.popularity}
                onChange={handleChange}
                name="popularity"
              />
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="read_duration">ماوەی خوێندنەوەی</label>
              <input
                type="text"
                id="read_duration"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
                value={formData.read_duration}
                onChange={handleChange}
                name="read_duration"
              />
            </div>
            <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="background">وێنەی باکگراوند</label>
              <input
                type="file"
                id="background"
                name="background"
                onChange={(e) => {
                  setFormData({ ...formData, background: e.target.files[0] });
                  setImgBg(e.target.files[0]);
                }}
                className="hidden"
              />
              <div className="outline-none border-slate-300 border rounded h-11 sm:w-[230px] w-full bg-transparent flex items-center justify-between">
                <div className="mx-3 w-full text-ellipsis overflow-hidden whitespace-nowrap">
                  {imgBg.name || companyData.data.background}
                </div>
                <label
                  htmlFor="background"
                  className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                >
                  <AiOutlineCloudUpload />
                </label>
              </div>
            </div>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="is_company">جۆرەکەی</label>
              <select
                id="is_company"
                name="is_company"
                value={formData.is_company}
                onChange={handleChange}
                className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[230px] w-full p-2.5 bg-backgroundSecondary"
              >
                <option value="1" defaultValue>
                  کۆمپانیا
                </option>
                <option value="0">ستۆدیۆ</option>
              </select>
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="started_at">کاتی دروستکردنی</label>
              <input
                type="date"
                id="started_at"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
                value={formData.started_at}
                onChange={handleChange}
                name="started_at"
              />
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="staff_id">لەلایەن</label>
              <select
                name="staff_id"
                id="staff_id"
                onChange={handleChange}
                className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[230px] w-full p-2.5 bg-backgroundSecondary"
                value={formData.staff_id}
              >
                {getData.map((staff) => (
                  <option value={staff.id}>{staff.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="textarea">پێناسەکردنی</label>
            <textarea
              name="description"
              id="textarea"
              value={formData.description}
              onChange={handleChange}
              className="outline-none border-slate-300 border rounded h-32 md:w-[720px] sm:w-[450px] w-full bg-transparent px-2"
            />
          </div>
          <button
            onClick={handleSubmit}
            type="submit"
            disabled={createLoading}
            className={`sm:w-[450px] w-[90%] relative h-[45px] bg-backgroundSecondary text-xl py-2 hover:bg-primary hover:text-black duration-300 rounded-md ${
              createLoading ? "disabled" : ""
            }`}
          >
            {!createLoading && <span>ناردن</span>}
            {createLoading && <ButtonLoading />}
          </button>
        </div>
        {isSent && (
          <CreatedWarning
            icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
            message={"بابەتەکە بەسەرکەوتوویی نوێکرایەوە"}
          />
        )}
        {createError && createError.response && (
          <CreatedWarning
            icon={<MdReportGmailerrorred className="text-9xl text-red" />}
            message={"تکایە لە دڵنیاببەوە هەموو زانیاریەکانت بەڕاستی داناوە"}
            moreInfo={
              createError && createError.response
                ? createError.response.data.message
                : "An error occurred"
            }
          />
        )}
      </>
    );
  }
};

export default UpdateCompany;
