import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = Cookies.get("token");

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (isMounted) {
          setData(res.data);
        }
      } catch (err) {
        if (isMounted) {
          setError(err);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [url]);

  const refetch = async () => {
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setData(res.data);
    } catch (err) {
      setError(err);
    }
  };
  const refetchPagination = async () => {
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer 37|7SomMTnlZUXslmXDZIVWDqRobg9d0lKry3qt9rS2",
        },
      });
      return res.data; // Return the data
    } catch (err) {
      throw err; // Throw an error if something goes wrong
    }
  };

  return { data, loading, error, refetch, refetchPagination };
};

export default useFetch;
