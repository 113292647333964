import React, { useContext, useEffect, useState } from "react";
import { BreadCrumb, Loading } from "../../components";
import ChangeColor from "./SettingsComponents/ChangeColor";
import ChangeInfo from "./SettingsComponents/ChangeInfo";
import ChangePassword from "./SettingsComponents/ChangePassword";
import { AuthContext, fetchUserFromAPI } from "../../context/authContext";
import useDelete from "../../hooks/useDelete";
import Cookies from "js-cookie";
import SendData from "../../hooks/useSendData";
import { useNavigate } from "react-router-dom";
import { CreatedWarning } from "../../admin/admin-components";
import { MdReportGmailerrorred } from "react-icons/md";
import { API_URL } from "../../env";

const Settings = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleModal = () => {
    setShowModal(!showModal);
  };
  const handleLogOut = async () => {
    // Clear the token cookie
    Cookies.remove("token");

    // Dispatch the "LOGOUT" action
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };
  const { deleteRequest, deleteLoading, deleteError } = useDelete();
  const handleDelete = async (id) => {
    setShowModal(!showModal);
    try {
      await deleteRequest(`${API_URL}auth/user/delete`);
      setIsSent(true);
      handleLogOut();
    } catch (err) {
      setIsSent(false);
    }
  };

  const authContext = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState(1);
  const [userData, setUserData] = useState(null);
  const { dispatch, user } = authContext;
  const [loading, setLoading] = useState(null);
  useEffect(() => {
    setLoading(true);
    // Call the fetchUserFromAPI function when the component mounts
    fetchUserFromAPI(dispatch)
      .then((userData) => {
        // Set the user data to the state variable
        setUserData(userData);
        setLoading(false);
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error fetching user data:", error);
      });
  }, [dispatch]);

  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  if (loading || !userData) {
    return <Loading />;
  } else {
    return (
      <section className="section">
        <div className="flex__center flex-col w-full max-w-[1250px] xl:px-0 px-3 mb-10 gap-10">
          <div className="w-full flex__start">
            <BreadCrumb
              exactPlace={userData?.data?.name}
              secondPlace="ڕێکخستن"
              secondPlaceLink="/profile"
            ></BreadCrumb>
          </div>
          <div className="flex__center md:auto w-full bg-backgroundSecondary relative p-1 rounded-xl md:gap-20 gap-8">
            <span
              className={`bg-primary md:w-[200px] w-[100px] rounded-xl duration-300 py-2 h-[40px] absolute ${
                activeTab === 3
                  ? "md:-translate-x-[280px] -translate-x-[110px] w-[120px]"
                  : activeTab === 2
                  ? "md:-translate-x-0 translate-x-5"
                  : "md:translate-x-[280px] translate-x-[130px]"
              }`}
            ></span>
            <button
              onClick={() => handleTabChange(1)}
              className={`${
                activeTab === 1 ? "text-black" : "text-white"
              } z-10 py-2 md:text-lg text-base  md:w-[200px] w-auto rounded-xl`}
            >
              زانیاریەکانم
            </button>
            <button
              onClick={() => handleTabChange(2)}
              className={`${
                activeTab === 2 ? "text-black" : "text-white"
              } z-10 py-2 md:text-lg text-base  md:w-[200px] w-auto rounded-xl`}
            >
              هەڵبژاردنی ڕەنگ
            </button>
            <button
              onClick={() => handleTabChange(3)}
              className={`${
                activeTab === 3 ? "text-black" : "text-white"
              } z-10 py-2 md:text-lg text-base  md:w-[200px] w-auto rounded-xl`}
            >
              گۆڕینی تێپەرە وشە
            </button>
          </div>
          <div className="w-full flex items-center justify-center">
            {activeTab === 2 && <ChangeColor />}
            {activeTab === 1 && <ChangeInfo />}
            {activeTab === 3 && <ChangePassword />}
          </div>
          <button
            onClick={handleModal}
            className="max-w-[400px] w-full bg-red relative h-[40px] flex__center rounded"
          >
            ڕەشکردنەوە
          </button>
          {showModal === true && (
            <>
              <div className="max-w-[500px] w-[90%] h-auto flex justify-center items-center flex-col gap-5 p-5 rounded-xl bg-backgroundSecondary fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-20">
                <h1 className="sm:text-2xl text-lg">
                  دڵنیای لە ڕەشکردنەوەی ئەم بابەتە؟
                </h1>
                <span className="text-slate-300">
                  دوای ڕەشکردنەوەی توانای ئەوەت نابێت بیگەڕێنیتەوە
                </span>
                <div className="flex__center gap-5">
                  <button
                    className="primary-button !text-white !bg-backgroundTerinary"
                    onClick={handleModal}
                  >
                    ڕەتکردنەوە
                  </button>
                  <button
                    className="primary-button !bg-red"
                    onClick={() => handleDelete(userData?.data?.id)}
                  >
                    ڕەشکردنەوە
                  </button>
                </div>
              </div>
              <button
                className="bg-black bg-opacity-50 z-10 fixed top-0 left-0 w-full h-full"
                onClick={handleModal}
              ></button>
            </>
          )}
        </div>
      </section>
    );
  }
};

export default Settings;
