import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import SendData from "../../../hooks/useSendData";
import { CreatedWarning, Navbar } from "../../admin-components";
import { MdReportGmailerrorred } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { ButtonLoading } from "../../../components";
import { API_URL } from "../../../env";
import axios from "axios";
import Cookies from "js-cookie";

const AddAdmins = () => {
  const { createData, createError, createLoading, post } = SendData();

  const [isSent, setIsSent] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    role: "MODERATE",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const token = Cookies.get("token");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${API_URL}auth/admins/register`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setIsSent(true);
    } catch (error) {
      setIsSent(false);
    }
  };
  useEffect(() => {
    if (createError) {
    }
  }, [createError]);
  return (
    <>
      <Navbar
        exactPlace="زیادکردنی ئەدمین"
        secondPlace="ئەدمین"
        secondPlaceLink="/admin/admins"
      />

      <div className="flex__center flex-col w-full xl:flex-[0.8] mb-10 flex-1 mt-28 gap-8">
        <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="name">ناو</label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={handleChange}
              className="outline-none border-slate-300 border rounded h-10 sm:w-[350px] w-full bg-transparent px-2"
              name="name"
            />
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="email">ئیمەیڵ</label>
            <input
              type="email"
              value={formData.email}
              onChange={handleChange}
              id="email"
              name="email"
              className="outline-none border-slate-300 border rounded h-10 sm:w-[350px] w-full bg-transparent px-2"
            />
          </div>
        </div>
        <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="password">تێپەرە وشە</label>
            <input
              type="password"
              id="password"
              value={formData.password}
              onChange={handleChange}
              className="outline-none border-slate-300 border rounded h-10 sm:w-[350px] w-full bg-transparent px-2"
              name="password"
            />
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="password">دڵنیاکردنەوەی تێپەرە وشە</label>
            <input
              type="password"
              value={formData.password_confirmation}
              onChange={handleChange}
              id="password"
              name="password_confirmation"
              className="outline-none border-slate-300 border rounded h-10 sm:w-[350px] w-full bg-transparent px-2"
            />
          </div>
        </div>
        <div className="flex__start gap-5 md:flex-row flex-col sm:w-auto w-full">
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="role">ڕۆڵ</label>
            <select
              name="role"
              id="role"
              onChange={handleChange}
              className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[350px] w-full p-2.5 bg-backgroundSecondary"
              value={formData.role}
            >
              <option value="MODERATE" defaultValue>
                مۆد
              </option>
              <option value="ADMIN">ئەدمین</option>
              <option value="SUPER_ADMIN">سوپەر ئەدمین</option>
            </select>
          </div>
        </div>
        <button
          onClick={handleSubmit}
          type="submit"
          disabled={createLoading}
          className={`sm:w-[450px] w-[90%] relative h-[45px] bg-backgroundSecondary text-xl py-2 hover:bg-primary hover:text-black duration-300 rounded-md ${
            createLoading ? "disabled" : ""
          }`}
        >
          {!createLoading && <span>ناردن</span>}
          {createLoading && <ButtonLoading />}
        </button>
      </div>
      {isSent && !createError && (
        <CreatedWarning
          icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
          message={"بابەتەکە بەسەرکەوتوویی دروستکرا"}
        />
      )}
      {createError && createError.response && (
        <CreatedWarning
          icon={<MdReportGmailerrorred className="text-9xl text-red" />}
          message={"تکایە لە دڵنیاببەوە هەموو زانیاریەکانت بەڕاستی داناوە"}
          moreInfo={
            createError && createError.response
              ? createError.response.data.message
              : "An error occurred"
          }
        />
      )}
    </>
  );
};

export default AddAdmins;
