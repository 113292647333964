import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, createContext, useReducer } from "react";
import { API_URL } from "../../env";

const INITIAL_STATE = {
  admin: null,
  loading: false,
  error: null,
};

export const AdminContext = createContext(INITIAL_STATE);

const AdminReducer = (state, action) => {
  switch (action.type) {
    case "LOAGIN_START":
      return {
        admin: null,
        loading: true,
        error: null,
      };
    case "LOGIN_SUCCESS":
      return {
        admin: action.payload,
        loading: false,
        error: null,
      };
    case "LOGIN_FAILURE":
      return {
        admin: null,
        loading: false,
        error: action.payload,
      };
    case "LOGOUT":
      return {
        admin: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export const fetchAdminFromAPI = async (dispatch) => {
  const token = Cookies.get("token");
  try {
    // Dispatch a "LOGIN_START" action to set loading to true
    dispatch({ type: "LOGIN_START" });

    // Make an Axios request to fetch the admin data
    // const response = await axios.get("https://gamewebsite.xyz/api/admin");
    const response = await axios.get(`${API_URL}auth/admins/info`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const adminData = response.data; // Assuming the response contains admin data

    // Dispatch a "LOGIN_SUCCESS" action with the admin data
    dispatch({ type: "LOGIN_SUCCESS", payload: adminData });

    return adminData; // Return the admin data
  } catch (error) {
    // Dispatch a "LOGIN_FAILURE" action with the error message
    dispatch({ type: "LOGIN_FAILURE", payload: error.message });
    throw error; // Rethrow the error
  }
};

export const AdminContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AdminReducer, INITIAL_STATE);

  // useEffect(() => {
  //   localStorage.setItem("admin", JSON.stringify(state.admin));
  // }, [state.admin]);

  return (
    <AdminContext.Provider
      value={{
        admin: state.admin,
        loading: state.loading,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
