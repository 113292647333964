import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import useDelete from "../../../hooks/useDelete";
import { AddButton, CreatedWarning, Navbar } from "../../admin-components";
import { Link } from "react-router-dom";
import { Loading } from "../../../components";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdReportGmailerrorred } from "react-icons/md";
import { API_URL } from "../../../env";

const ViewNews = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const indexOfLastItem = currentPage * itemsPerPage;
  const { deleteRequest, deleteLoading, deleteError } = useDelete();
  const { data, loading, error, refetch, refetchPagination } = useFetch(
    `${API_URL}news/all?perPage=${itemsPerPage}&page=${currentPage}`
  );
  const [getData, setGetData] = useState([]);
  const [isSent, setIsSent] = useState(false);

  const handleDelete = async (id) => {
    setShowModal(!showModal);
    try {
      await deleteRequest(`${API_URL}news/delete/${id}`);
      setIsSent(true);
      refetch();
    } catch (err) {
      setIsSent(false);
    }
  };

  const fetchData = async (page) => {
    try {
      const result = await refetchPagination(
        `${API_URL}news?perPage=${itemsPerPage}&page=${page}`
      );
      if (result) {
        setGetData(result.data);
      }
    } catch (error) {}
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage, () => {
      fetchData(newPage);
    });
  };

  useEffect(() => {
    if (!loading && data?.data?.length > 0) {
      setGetData(data.data);
      localStorage.setItem("totalNews", data?.pagination?.totalCount);
    }
  }, [loading, data, currentPage]);

  const dateFormat = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  // remove and show modal
  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState(false);

  const handleModal = (id) => {
    setShowModal(!showModal);
    setModalId(id);
  };
  if (loading || !data || !data.data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="admin-section">
          <Navbar
            exactPlace="بینینی هەواڵەکان"
            secondPlace="هەواڵەکان"
            secondPlaceLink="/admin/news"
          />
          <div className="flex__center flex-col w-full xl:flex-[0.8] flex-1 gap-8">
            <AddButton title="زیادکردنی هەواڵەکان" link="/admin/news/new" />
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-[90%]">
              <table className="w-full  text-sm text-left text-slate-300">
                <thead className="text-sm font-light uppercase bg-backgroundSecondary ">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 font-light change_font"
                    >
                      ID
                    </th>
                    <th scope="col" className="px-6 py-3 font-light text-right">
                      ناونیشان
                    </th>
                    <th scope="col" className="px-6 py-3 font-light text-right">
                      -
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 font-light text-right min-w-[20ch]"
                    >
                      -
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 font-light text-right min-w-[20ch]"
                    >
                      دروستکراوە لە
                    </th>
                    <th scope="col" className="px-6 py-3 font-light text-right">
                      کردەکان
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getData.map((news) => (
                    <tr
                      key={news.id}
                      className="bg-backgroundTerinary border-b border-backgroundSecondary  hover:bg-gray-700"
                    >
                      <td className="w-4 p-4 text-right">{news.id}</td>
                      <th
                        scope="row"
                        className="px-6 py-4 w-[15ch] text-ellipsis overflow-hidden font-normal text-right text-slate-300 whitespace-nowrap"
                      >
                        <Link
                          to={`/news/${news.id}`}
                          rel="noopener noreferrer"
                          target="_blank"
                          className="link_hover"
                        >
                          {news.title}
                        </Link>
                      </th>
                      <td className="px-6 py-4 text-right">
                        {news.role || "-"}
                      </td>
                      <td className="px-6 py-4 text-right change_font">
                        {news.count_game || "-"}
                      </td>
                      {news.created_at ? (
                        <td className="px-6 py-4 change_font text-right">
                          {dateFormat(news.created_at)}
                        </td>
                      ) : (
                        <td className="px-6 py-4 change_font text-right">-</td>
                      )}
                      <td className="px-6 py-4 flex justify-start items-center gap-2 text-right">
                        <Link
                          title={news.title}
                          to={`/admin/news/${news.id}`}
                          className="font-medium  hover:underline"
                        >
                          گۆڕین
                        </Link>
                        <button
                          className="font-medium text-red hover:underline"
                          onClick={() => handleModal(news.id)}
                        >
                          ڕەشکردنەوە
                        </button>
                      </td>
                      {/* delete modal */}
                      {news.id === modalId && showModal === true && (
                        <>
                          <div className="max-w-[500px] w-[90%] h-auto flex justify-center items-center flex-col gap-5 p-5 rounded-xl bg-backgroundSecondary fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-20">
                            <h1 className="sm:text-2xl text-lg">
                              دڵنیای لە ڕەشکردنەوەی ئەم بابەتە؟
                            </h1>
                            <span className="text-slate-300">
                              دوای ڕەشکردنەوەی توانای ئەوەت نابێت بیگەڕێنیتەوە
                            </span>
                            <div className="flex__center gap-5">
                              <button
                                className="primary-button !text-white !bg-backgroundTerinary"
                                onClick={() => handleModal(news.id)}
                              >
                                ڕەتکردنەوە
                              </button>
                              <button
                                className="primary-button !bg-red"
                                onClick={() => handleDelete(news.id)}
                              >
                                ڕەشکردنەوە
                              </button>
                            </div>
                          </div>
                          <button
                            className="bg-black bg-opacity-50 z-10 fixed top-0 left-0 w-full h-full"
                            onClick={() => handleModal(news.id)}
                          ></button>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              <nav
                className="flex items-center justify-between pt-4"
                aria-label="Table navigation"
              >
                <span className="text-sm font-normal text-gray-500">
                  نیشاندانی{" "}
                  <span className="font-semibold text-slate-300 change_font">
                    {itemsPerPage}
                  </span>{" "}
                  لە{" "}
                  <span className="font-semibold text-slate-300 change_font">
                    {data?.pagination?.totalCount}
                  </span>
                </span>
                <ul className="inline-flex -space-x-px text-sm h-8">
                  <li>
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className={`flex items-center justify-center px-3 h-8 leading-tight bg-background border border-gray-700 rounded-r-lg hover:bg-gray-900 ${
                        currentPage === 1 ? "text-gray-500" : "text-white"
                      }`}
                    >
                      پێشتر
                    </button>
                  </li>
                  <li>
                    <p className="flex items-center justify-center px-3 h-8 leading-tight text-slate-300 change_font bg-background border border-gray-700 hover:bg-gray-900 ">
                      {currentPage}
                    </p>
                  </li>
                  <li>
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={indexOfLastItem >= data?.pagination?.totalCount}
                      className={`flex items-center justify-center px-3 h-8 leading-tight bg-background border border-gray-700 rounded-l-lg hover:bg-gray-900 ${
                        indexOfLastItem >= data?.pagination?.totalCount
                          ? "text-gray-500"
                          : "text-white"
                      }`}
                    >
                      دواتر
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          {!isSent && deleteError && deleteError.response && (
            <CreatedWarning
              icon={<MdReportGmailerrorred className="text-9xl text-red" />}
              message="بابەتەکە ڕەشنەکرایەوە"
              moreInfo="تکایە دوبارە هەوڵ بدەوە یان پەیوەندی بە پەررەپێدەر بکە"
              key={1}
            />
          )}
          {isSent && (
            <CreatedWarning
              icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
              message="بابەتەکە ڕەشکرایەوە"
              key={2}
            />
          )}
        </section>
      </>
    );
  }
};

export default ViewNews;
