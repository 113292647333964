import React, { useEffect, useState } from "react";
import images from "../../assets/images";
import GameHistoryCard from "./GameHistoryComponent/GameHistoryCard";
import useFetch from "../../hooks/useFetch";
import { Loading } from "../../components";
import { BsArrowLeftShort } from "react-icons/bs";

const GameHistory = () => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 10;
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const { data, loading, error, refetchPagination } = useFetch(
  //   `https://gamewebsite.xyz/api/history-games?limit=${itemsPerPage}&page=${currentPage}`
  // );

  // const [getData, setGetData] = useState([]);

  // const totalPageCount = Math.ceil(data?._meta?.totalCount / itemsPerPage);

  // const fetchData = async (page) => {
  //   try {
  //     const result = await refetchPagination(
  //       `https://gamewebsite.xyz/api/games?page=${page}&limit=${itemsPerPage}`
  //     );
  //     if (result) {
  //       setGetData(result.data);
  //     }
  //   } catch (error) {}
  // };
  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage, () => {
  //     fetchData(newPage);
  //   });
  // };

  // const generatePageNumbers = () => {
  //   const pageNumbers = [];
  //   for (let index = currentPage - 5; index < currentPage + 5; index++) {
  //     if (index > 0 && index <= totalPageCount) {
  //       pageNumbers.push(index);
  //     }
  //   }
  //   return pageNumbers;
  // };

  // useEffect(() => {
  //   if (!loading && data?.data?.length > 0) {
  //     setGetData(data.data);
  //   }
  // }, [loading, data, currentPage]);
  // const dateFormat = (date) => {
  //   const options = { year: "numeric", month: "short", day: "numeric" };
  //   return new Date(date).toLocaleDateString(undefined, options);
  // };
  // if (loading || !data) {
  //   return <Loading />;
  // } else {
  //   return (
  //     <>
  //       <section className="section">
  //         <div className="w-full max-w-[1250px] xl:px-0 px-3 flex__center flex-col gap-10 mb-10">
  //           <h1 className="sm:text-4xl text-2xl">مێژووی یاریەکان</h1>
  //           <div className="w-full flex__center gap-y-10 gap-x-5 flex-col">
  //             {getData.map((history) => (
  //               <GameHistoryCard
  //                 date={dateFormat(history.created_at)}
  //                 key={history.id}
  //                 img={history.background_image}
  //                 link={`/game-history/${history.id}`}
  //                 personImg={history?.staff?.poster}
  //                 personLink={`/staff/${history?.staff?.id}`}
  //                 personName={history?.staff?.name}
  //                 title={history.title}
  //                 // sum={4}
  //                 // genre={["سەرکێشی", "شەڕ"]}
  //               />
  //             ))}
  //           </div>
  //           <nav
  //             className="flex items-center justify-center w-full pt-4"
  //             aria-label="Table navigation"
  //           >
  //             <ul className="inline-flex w-full items-center justify-center -space-x-px text-sm h-8">
  //               <li>
  //                 <button
  //                   onClick={() => handlePageChange(currentPage - 1)}
  //                   disabled={currentPage === 1}
  //                   className={`flex items-center justify-center px-3 h-8 leading-tight bg-background border border-backgroundTerinary rounded-r-lg hover:bg-gray-900 ${
  //                     currentPage === 1 ? "text-gray-500" : "text-white"
  //                   }`}
  //                 >
  //                   <BsArrowLeftShort className="text-xl rotate-180" />
  //                   <span>پێشتر</span>
  //                 </button>
  //               </li>
  //               <div className="flex overflow-x-auto pagination">
  //                 {generatePageNumbers().map((pageNumber) => (
  //                   <li key={pageNumber}>
  //                     <button
  //                       onClick={() => handlePageChange(pageNumber)}
  //                       className={`flex items-center justify-center px-3 h-8 leading-tight change_font bg-background border border-backgroundTerinary ${
  //                         currentPage === pageNumber
  //                           ? "text-black bg-primary"
  //                           : "text-slate-300"
  //                       }`}
  //                     >
  //                       {pageNumber}
  //                     </button>
  //                   </li>
  //                 ))}
  //               </div>
  //               <li>
  //                 <button
  //                   onClick={() => handlePageChange(currentPage + 1)}
  //                   disabled={indexOfLastItem >= data?._meta?.totalCount}
  //                   className={`flex items-center justify-center gap-1 px-3 h-8 leading-tight bg-background border border-backgroundTerinary rounded-l-lg hover:bg-gray-900 ${
  //                     indexOfLastItem >= data?._meta?.totalCount
  //                       ? "text-gray-500 "
  //                       : "text-white"
  //                   }`}
  //                 >
  //                   <span>دواتر</span>
  //                   <BsArrowLeftShort className="text-xl" />
  //                 </button>
  //               </li>
  //             </ul>
  //           </nav>
  //         </div>
  //       </section>
  //     </>
  //   );
  // }
  return (
    <>
      <div className="flex__center w-full h-[100vh] relative">
        <img
          src={images.auth}
          alt="bg"
          className="w-full h-full absolute top-0 left-0 object-cover"
        />
        <div className="bg-black bg-opacity-50 backdrop-blur-sm absolute top-0 left-0 w-full h-full"></div>
        <h3 className="sm:text-8xl text-4xl z-10 text-center text-primary">
          بەمزوانە بەردەست دەبێت
        </h3>
      </div>
    </>
  );
};

export default GameHistory;
