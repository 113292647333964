import React from "react";
import images from "../../assets/images";
import { BreadCrumb, ByWho, Loading } from "../../components";
import useFetch from "../../hooks/useFetch";
import { Link, useParams } from "react-router-dom";
import SimilarNews from "./NewsComponent/SimilarNews";
import { API_URL, IMG_URL } from "../../env";

const NewsDetails = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(`${API_URL}news/view/${id}`);
  const dateFormat = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  if (loading || !data.data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section">
          <div className="flex__start flex-col w-full max-w-[1250px] xl:px-0 px-3 gap-10 mb-10">
            <div className="w-full flex__start">
              <BreadCrumb
                exactPlace={data.data.tag}
                secondPlaceLink="/news"
                secondPlace="هەواڵەکان"
              />
            </div>
            {loading === true ? (
              "loading"
            ) : (
              <div className="flex items-start justify-start lg:flex-row flex-col gap-10 w-full">
                <div className="lg:flex-[0.7] flex-1 flex__center flex-col gap-5">
                  <div className="w-full sm:h-[400px] h-auto rounded-lg overflow-hidden">
                    <img
                      src={`${IMG_URL}news/${data.data.image}`}
                      alt={data.data.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="w-full flex items-start justify-between">
                    <h2 className="lg:text-3xl text-xl lg:w-[500px] w-auto">
                      {data.data.title}
                    </h2>
                    <ByWho
                      imgPerson={
                        data.data.staff.image
                          ? `${IMG_URL}staff/poster/${data.data.staff?.image}`
                          : null
                      }
                      time={dateFormat(data.data.created_at)}
                      title={data.data.staff?.name}
                    />
                  </div>
                  <div className="flex-col border-t border-t-backgroundTerinary pt-5 flex__start w-full gap-5">
                    <h3 className="text-2xl">درێژەی هەواڵ</h3>
                    <p className="paragraph">{data.data.description}</p>
                    {data.data.link && (
                      <div className="w-full sm:h-[400px] h-auto">
                        <iframe
                          src={data.data.link}
                          scrolling="no"
                          frameborder="0"
                          allowfullscreen="true"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          className="w-full h-full rounded-lg"
                          title="video"
                        ></iframe>
                      </div>
                    )}
                  </div>
                </div>
                <SimilarNews />
              </div>
            )}
          </div>
        </section>
      </>
    );
  }
};

export default NewsDetails;
