import React from "react";
import images from "../../assets/images";

const Recommend = () => {
  return (
    <>
      <div className="flex__center w-full h-[100vh] relative">
        <img
          src={images.auth}
          alt="bg"
          className="w-full h-full absolute top-0 left-0 object-cover"
        />
        <div className="bg-black bg-opacity-50 backdrop-blur-sm absolute top-0 left-0 w-full h-full"></div>
        <h3 className="sm:text-8xl text-4xl z-10 text-center text-primary">
          بەمزوانە بەردەست دەبێت
        </h3>
      </div>
    </>
  );
};

export default Recommend;
