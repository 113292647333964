import React, { useState } from "react";
import { Link } from "react-router-dom";
import images from "../../assets/images/index";
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { AiOutlineYoutube } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
const Footer = () => {
  const [category, setCategory] = useState(false);
  const [contact, setContact] = useState(false);
  const [pages, setPages] = useState(false);
  const handleClick = (e) => {
    if (e === "category") {
      setCategory((prevCategory) => !prevCategory);
    }
    if (e === "contact") {
      setContact((prevContact) => !prevContact);
    }
    if (e === "pages") {
      setPages((prevPages) => !prevPages);
    }
  };

  return (
    <>
      <footer className="w-full bg-[#1d1d1d] py-5 flex__center">
        <div className="w-full flex__center gap-6 max-w-[1250px] flex-col xl:px-0 px-3">
          <div className="w-full flex sm:justify-between justify-center sm:items-start items-center gap-10 sm:flex-row flex-col">
            <ul className="flex sm:items-start sm:justify-start items-center justify-center flex-col gap-2 sm:flex-[0.3] flex-1 w-full text-[#d8d7d7] text-base">
              <button
                onClick={() => handleClick("category")}
                className="pb-2 border-b-2 border-b-[#2E2E2E] w-full flex justify-between items-center sm:hidden"
              >
                <h1 className="text-xl ">بەشەکان</h1>
                <BsChevronDown />
              </button>
              <h1 className="text-xl hidden sm:block pb-2 border-b-2 border-b-[#2E2E2E] w-full ">
                بەشەکان
              </h1>
              <div
                className={`grid w-full md:grid-cols-2 sm:grid-cols-1 grid-cols-2 lg:gap-x-20 sm:gap-x-3 gap-x-2 gap-y-2 sm:max-h-[400px] overflow-hidden duration-500 ${
                  category === false ? "max-h-0" : "max-h-[400px]"
                }`}
              >
                <li>
                  <Link className="link_hover" to="details" title="details">
                    وردەکارییەکان
                  </Link>
                </li>
                <li>
                  <Link className="link_hover" to="top" title="top">
                    بەرزترینەکان
                  </Link>
                </li>
                <li>
                  <Link className="link_hover" to="dive" title="dive">
                    قووڵبوونەوە
                  </Link>
                </li>
                <li>
                  <Link className="link_hover" to="youtubers" title="youtubers">
                    یوتوبەرەکان
                  </Link>
                </li>
                <li>
                  <Link
                    className="link_hover"
                    to="comingsoon"
                    title="coming soon"
                  >
                    بەم زوانە
                  </Link>
                </li>
                <li>
                  <Link className="link_hover" to="company" title="company">
                    کۆمپانیاکان
                  </Link>
                </li>
                <li>
                  <Link className="link_hover" to="genre" title="genre">
                    ژانراکان
                  </Link>
                </li>
                <li>
                  <Link className="link_hover" to="console" title="console">
                    کونسوڵەکان
                  </Link>
                </li>
                <li>
                  <Link
                    className="link_hover"
                    to="game-history"
                    title="game history"
                  >
                    مێژووی یارییەکان
                  </Link>
                </li>
                <li>
                  <Link className="link_hover" to="recommend" title="recommend">
                    پێشنیارکردن
                  </Link>
                </li>
                <li>
                  <Link
                    className="link_hover"
                    to="game-series"
                    title="game-series"
                  >
                    زنجیرە یارییەکان
                  </Link>
                </li>
                <li>
                  <Link className="link_hover" to="events" title="events">
                    بۆنەکان
                  </Link>
                </li>
              </div>
            </ul>
            <ul className="flex sm:items-start sm:justify-start items-center justify-center flex-col gap-2 sm:flex-[0.3] flex-1 w-full text-[#d8d7d7] text-base">
              <button
                onClick={() => handleClick("contact")}
                className="pb-2 border-b-2 border-b-[#2E2E2E] w-full flex justify-between items-center sm:hidden"
              >
                <h1 className="text-xl ">پەیوەندی</h1>
                <BsChevronDown />
              </button>
              <h1 className="text-xl hidden sm:block pb-2 border-b-2 border-b-[#2E2E2E] w-full ">
                پەیوەندی
              </h1>
              <div
                className={`grid w-full sm:max-h-[400px] md:grid-cols-2 sm:grid-cols-1 grid-cols-2 lg:gap-x-10 sm:gap-x-3 gap-x-2 gap-y-2 overflow-hidden duration-500 ${
                  contact === false ? "max-h-0" : "max-h-[400px]"
                }`}
              >
                <li>
                  <Link className="link_hover" to="/about" title="about">
                    دەربارەی ئێمە
                  </Link>
                </li>
                <li>
                  <a
                    className="change_direction link_hover text-sm change_font flex justify-end"
                    href="https://api.whatsapp.com/send?phone=9647518706465"
                    title="number"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    0751 8706465
                  </a>
                </li>
                <li>
                  <a
                    className="change_direction link_hover text-sm change_font"
                    href="mailto:info@kurdgame.net"
                    title="email"
                  >
                    info@kurdgame.net
                  </a>
                </li>
              </div>
            </ul>
            <ul className="flex sm:items-start sm:justify-start items-center justify-center flex-col gap-2 sm:flex-[0.3] flex-1 w-full text-[#d8d7d7] text-base">
              <button
                onClick={() => handleClick("pages")}
                className="pb-2 border-b-2 border-b-[#2E2E2E] w-full flex justify-between items-center sm:hidden"
              >
                <h1 className="text-xl ">پەڕە سەرەکییەکان</h1>
                <BsChevronDown />
              </button>
              <h1 className="text-xl hidden sm:block pb-2 border-b-2 border-b-[#2E2E2E] w-full ">
                پەڕە سەرەکییەکان
              </h1>
              <div
                className={`grid w-full sm:max-h-[400px] md:grid-cols-2 sm:grid-cols-1 grid-cols-2 lg:gap-x-20 sm:gap-x-3 gap-x-2 gap-y-2 overflow-hidden duration-500 ${
                  pages === false ? "max-h-0" : "max-h-[400px]"
                }`}
              >
                <li>
                  <Link className="link_hover" to="/" title="home">
                    سەرەکی
                  </Link>
                </li>
                <li>
                  <Link className="link_hover" to="games" title="games">
                    یاریەکان
                  </Link>
                </li>
                <li>
                  <Link
                    className="link_hover"
                    to="characters"
                    title="characters"
                  >
                    کەسایەتیەکان
                  </Link>
                </li>
                <li>
                  <Link className="link_hover" to="news" title="news">
                    هەواڵەکان
                  </Link>
                </li>
                <li>
                  <Link className="link_hover" to="reviews" title="reviews">
                    پێداچوونەوە
                  </Link>
                </li>
                <li>
                  <Link className="link_hover" to="staff" title="staff">
                    ستاف
                  </Link>
                </li>
              </div>
            </ul>
          </div>
          <div className="w-full flex justify-between">
            {/* <p>هەموو مافێکی پارێزراوە بۆ کوردگەیم</p> */}
            <img
              src={images.logo}
              className="sm:w-[200px] w-[130px] h-auto"
              alt="logo"
            />
            <ul className="flex__center flex-row gap-2">
              <a
                title="number"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/gamingnews11"
                className="bg-white sm:w-10 sm:h-10 w-7 h-7 hover:bg-primary duration-300 flex__center rounded"
              >
                <FaFacebookF className="text-black sm:text-2xl text-xl" />
              </a>
              <a
                title="number"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/kurd_game_official/"
                className="bg-white sm:w-10 sm:h-10 w-7 h-7 hover:bg-primary duration-300 flex__center rounded"
              >
                <AiOutlineInstagram className="text-black sm:text-2xl text-xl" />
              </a>
              <a
                title="number"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/@kurdgame9831"
                className="bg-white sm:w-10 sm:h-10 w-7 h-7 hover:bg-primary duration-300 flex__center rounded"
              >
                <AiOutlineYoutube className="text-black sm:text-2xl text-xl" />
              </a>
              <a
                title="number"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.tiktok.com/@kurd.gamee?lang=en"
                className="bg-white sm:w-10 sm:h-10 w-7 h-7 hover:bg-primary duration-300 flex__center rounded"
              >
                <FaTiktok className="text-black sm:text-2xl text-xl" />
              </a>
            </ul>
          </div>
          <div className="w-full sm:flex-row flex-col flex sm:justify-between justify-center text-center text-[#d8d7d7] gap-3">
            <small>هەموو مافێکی پارێزراوە بۆ کوردگەیم</small>
            <small>
              دیزاین و پەرەپێدانی <span className="change_font">Mohamed</span> |{" "}
              <span className="change_font">Bawan</span>
            </small>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
