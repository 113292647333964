import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { Loading } from "../../components";
import { BsArrowLeftShort } from "react-icons/bs";
import { API_URL } from "../../env";
import { IMG_URL } from "../../env";

const Dive = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const { data, loading, error, refetchPagination } = useFetch(
    `${API_URL}dives/all?table_type=dive&limit=${itemsPerPage}&page=${currentPage}`
  );

  const [getData, setGetData] = useState([]);

  const totalPageCount = Math.ceil(data?.pagination?.totalCount / itemsPerPage);

  const fetchData = async (page) => {
    try {
      const result = await refetchPagination(
        `${API_URL}dives/all?table_type=dive&limit=${itemsPerPage}&page=${page}`
      );
      if (result) {
        setGetData(result.data);
      }
    } catch (error) {}
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage, () => {
      fetchData(newPage);
    });
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let index = currentPage - 5; index < currentPage + 5; index++) {
      if (index > 0 && index <= totalPageCount) {
        pageNumbers.push(index);
      }
    }
    return pageNumbers;
  };

  useEffect(() => {
    if (!loading && data?.data?.length > 0) {
      setGetData(data.data);
    }
  }, [loading, data, currentPage]);
  if (loading || !data.data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section">
          <div className="w-full max-w-[1250px] xl:px-0 px-3 flex__center flex-col lg:gap-10 gap-5 mb-10">
            <h1 className="sm:text-4xl text-2xl">قوڵبوونەوە لە یاریەکان</h1>
            <div className="w-full flex__center flex-wrap sm:gap-x-5 gap-x-2 gap-y-10">
              {getData.map((dive, i) => (
                <div
                  key={i}
                  className="flex__start md:basis-[48%] basis-[100%] sm:flex-row flex-col flex-shrink-0 border border-backgroundSecondary shadow-md shadow-backgroundSecondary rounded-lg p-3 lg:gap-10 sm:gap-5 gap-2"
                >
                  <Link
                    to={`/dive/${dive.id}`}
                    title={dive.title}
                    className="basis-[50%] sm:h-[200px] h-[150px] flex-shrink-0"
                  >
                    <img
                      src={`${IMG_URL}/dives/${dive.background_image}`}
                      alt={dive.title}
                      className="w-full h-full"
                    />
                  </Link>
                  <div className="flex__start flex-col gap-3 basis-[50%]">
                    <Link
                      to={`/dive/${dive.id}`}
                      title={dive.title}
                      className="sm:text-lg text-base link_hover text-ellipsis w-[20ch] overflow-hidden whitespace-nowrap"
                    >
                      {dive.title}
                    </Link>
                    {dive.staff_name && (
                      <div>
                        <span className="text-gray-300 sm:text-base text-sm">
                          لەلایەن:
                        </span>
                        <Link
                          title={dive?.staff_name}
                          to={`/staff/${dive?.staff_id}`}
                          className="link_hover mx-2 sm:text-base text-sm change_font"
                        >
                          {dive?.staff_name}
                        </Link>
                      </div>
                    )}
                    <p className="text-slate-300 sm:block hidden font-light text-base whitespace-normal">
                      {dive.description?.substring(0, 100)}...
                    </p>
                    <Link
                      to={`/dive/${dive.id}`}
                      title={dive.title}
                      className="primary-button"
                    >
                      زیاتر بخوێنەوە
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <nav
              className="flex items-center justify-center w-full pt-4"
              aria-label="Table navigation"
            >
              <ul className="inline-flex w-full items-center justify-center -space-x-px text-sm h-8">
                <li>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`flex items-center justify-center px-3 h-8 leading-tight bg-background border border-backgroundTerinary rounded-r-lg hover:bg-gray-900 ${
                      currentPage === 1 ? "text-gray-500" : "text-white"
                    }`}
                  >
                    <BsArrowLeftShort className="text-xl rotate-180" />
                    <span>پێشتر</span>
                  </button>
                </li>
                <div className="flex overflow-x-auto pagination">
                  {generatePageNumbers().map((pageNumber) => (
                    <li key={pageNumber}>
                      <button
                        onClick={() => handlePageChange(pageNumber)}
                        className={`flex items-center justify-center px-3 h-8 leading-tight change_font bg-background border border-backgroundTerinary ${
                          currentPage === pageNumber
                            ? "text-black bg-primary"
                            : "text-slate-300"
                        }`}
                      >
                        {pageNumber}
                      </button>
                    </li>
                  ))}
                </div>
                <li>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={indexOfLastItem >= data?.pagination?.totalCount}
                    className={`flex items-center justify-center gap-1 px-3 h-8 leading-tight bg-background border border-backgroundTerinary rounded-l-lg hover:bg-gray-900 ${
                      indexOfLastItem >= data?.pagination?.totalCount
                        ? "text-gray-500 "
                        : "text-white"
                    }`}
                  >
                    <span>دواتر</span>
                    <BsArrowLeftShort className="text-xl" />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </section>
      </>
    );
  }
};

export default Dive;
