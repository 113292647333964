import React, { useEffect, useRef, useState } from "react";
import { BreadCrumb, Loading } from "../../components";
import images from "../../assets/images";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { API_URL, IMG_URL } from "../../env";

const EventsDetails = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(`${API_URL}events/view/${id}`);

  const dateFormat = (date) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  if (loading || !data.data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section">
          <div className="w-full max-w-[1250px] xl:px-0 px-3 flex__center flex-col gap-10 mb-10">
            <div className="flex__start w-full">
              <BreadCrumb
                exactPlace={data.data.title}
                secondPlaceLink="/events"
                secondPlace="بۆنەکان"
              />
            </div>
            <div className="flex__center w-full flex-col gap-5">
              <div className="relative w-full">
                <img
                  src={`${IMG_URL}events/${data?.data?.background}`}
                  alt={data?.data?.title}
                  className="rounded-lg w-full sm:h-96 h-auto"
                />
              </div>
              <div className="flex__start w-full flex-wrap sm:gap-10 gap-y-2 gap-5">
                <h1 className="text-xl change_font">{data?.data?.title}</h1>
                <div className="flex__center gap-2">
                  <h3 className="text-slate-300">کاتی بڵاوبوونەوە:</h3>
                  <h1 className="change_font">
                    {dateFormat(data?.data?.release_date)}
                  </h1>
                </div>
                <a
                  href={data?.data?.link}
                  title={data?.data?.title}
                  rel="noreferrer noopener"
                  className="text-slate-300 flex-shrink-0 border-b border-slate-300 link_hover hover:border-primary"
                >
                  بینینی بۆنە
                </a>
              </div>
              <div className="w-full flex__start">
                <p className="paragraph">{data?.data?.description}</p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default EventsDetails;
