import React, { useContext, useEffect, useState } from "react";
import CardDashboard from "./CardDashboard/CardDashboard";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { IoLogoGameControllerA } from "react-icons/io";
import { BiCategory } from "react-icons/bi";
import { FaHistory, FaRegBuilding, FaUsers, FaYoutube } from "react-icons/fa";
import {
  GiBallPyramid,
  GiCharacter,
  GiConsoleController,
} from "react-icons/gi";

import StackChart from "./StackChart/StackChart";
import SparkChart from "./SparkChart/SparkChart";
import { Navbar } from "../../admin-components";
import { MdOutlineRateReview } from "react-icons/md";
import { BiNews } from "react-icons/bi";

const Dashboard = () => {
  const [gamesCount, setGamesCount] = useState(null);
  const [charactersCount, setCharactersCount] = useState(null);
  const [staffCount, setStaffCount] = useState(null);
  const [detailsCount, setDetailsCount] = useState(null);
  const [newsCount, setNewsCount] = useState(null);
  const [genreCount, setGenreCount] = useState(null);
  const [reviewCount, setReviewCount] = useState(null);
  const [companyCount, setCompanyCount] = useState(null);
  const [consoleCount, setConsoleCount] = useState(null);
  const [userCount, setUserCount] = useState(null);
  const [youtuberCount, setYoutuberCount] = useState(null);
  const [gameHistoryCount, setGameHistoryCount] = useState(null);
  useEffect(() => {
    setGamesCount(localStorage.getItem("totalGames"));
    setCharactersCount(localStorage.getItem("totalCharacters"));
    setStaffCount(localStorage.getItem("totalStaff"));
    setDetailsCount(localStorage.getItem("totalDetail"));
    setNewsCount(localStorage.getItem("totalNews"));
    setGenreCount(localStorage.getItem("totalGenre"));
    setReviewCount(localStorage.getItem("totalReviews"));
    setCompanyCount(localStorage.getItem("totalCompany"));
    setConsoleCount(localStorage.getItem("totalConsole"));
    setUserCount(localStorage.getItem("totalUser"));
    setYoutuberCount(localStorage.getItem("totalYoutuber"));
    setGameHistoryCount(localStorage.getItem("totalGameHistory"));
  }, []);
  return (
    <>
      <Navbar
        secondPlace="داشبۆرد"
        exactPlace="داشبۆردی سەرەکی"
        secondPlaceLink="/admin"
      />
      <div className="admin-section">
        <div className="flex__center w-full">
          <div className="grid lg:grid-cols-3 lg:grid-rows-2 md:grid-rows-3 md:grid-cols-2 md:grid-rows grid-cols-1 gap-5">
            <CardDashboard
              title="تەواوی یاریەکان"
              icon={<IoLogoGameControllerA />}
              number={gamesCount}
            />
            <CardDashboard
              title="تەواوی بەکارهێنەران"
              icon={<FaUsers />}
              number={userCount}
            />
            <CardDashboard
              title="تەواوی کەسایەتیەکان"
              icon={<GiCharacter />}
              number={charactersCount}
            />
            <CardDashboard
              title="تەواوی ستاف"
              icon={<AiOutlineUsergroupAdd />}
              number={staffCount}
            />
            <CardDashboard
              title="وردەکاری و بەرزترین"
              icon={<GiBallPyramid />}
              number={detailsCount}
            />
            <CardDashboard
              title="پێداچوونەوە و قوڵبوونەوە"
              icon={<MdOutlineRateReview />}
              number={reviewCount}
            />
            <CardDashboard title="هەواڵ" icon={<BiNews />} number={newsCount} />
            <CardDashboard
              title="ژانراکان"
              icon={<BiCategory />}
              number={genreCount}
            />
            <CardDashboard
              title="کونسوڵ"
              icon={<GiConsoleController />}
              number={consoleCount}
            />
            <CardDashboard
              title="کۆمپانیاکان"
              icon={<FaRegBuilding />}
              number={companyCount}
            />
            <CardDashboard
              title="یوتوبەرەکان"
              icon={<FaYoutube />}
              number={youtuberCount}
            />
            <CardDashboard
              title="مێژووی یاریەکان"
              icon={<FaHistory />}
              number={gameHistoryCount}
            />
          </div>
        </div>
        <div className="flex__center w-full">
          <div className="flex__center w-[95%] lg:flex-row flex-col gap-5 mt-10">
            <div className="flex__start flex-col bg-backgroundSecondary p-4 rounded-3xl gap-4">
              <h1 className="px-3 py-1 bg-background md:text-2xl text-base rounded-lg mx-6">
                زۆرترین سەردانیکەر لە مانگێکدا
              </h1>
              <StackChart />
            </div>
            <div className="flex__start flex-col bg-backgroundSecondary p-4 rounded-3xl gap-4">
              <h1 className="px-3 py-1 bg-background md:text-2xl text-base rounded-lg mx-6">
                زۆرترین سەردانیکەر لە هەفتەیەک
              </h1>
              <SparkChart />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
