import React, { useState } from "react";

const ReadMore = ({ text, maxCharacterCount = 400 }) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const result = isTruncated ? text.slice(0, maxCharacterCount) : text;
  const readMoreHandler = () => {
    setIsTruncated(!isTruncated);
  };
  return (
    <>
      <p className="whitespace-pre-line">
        {result}
        {(result.length === maxCharacterCount) |
        (result.length > maxCharacterCount) ? (
          <button onClick={readMoreHandler} className="text-primary mr-2">
            {isTruncated ? "زیاتر بخوێنەوە" : "زیاتر بخوێنەوە دابخە"}
          </button>
        ) : (
          ""
        )}
      </p>
    </>
  );
};

export default ReadMore;
