import React from "react";
import { Link } from "react-router-dom";
import images from "../../../assets/images";

const ConsoleCard = ({ link, img, year, title, console }) => {
  return (
    <>
      <Link
        to={link}
        title={title}
        className={`flex justify-between p-2 items-center rounded-md relative lg:w-[400px] sm:w-[48%] w-full h-auto ${
          console === "ps"
            ? "bg-indigo-600"
            : console === "xbox"
            ? "bg-green"
            : console === "nintendo"
            ? "bg-red"
            : "blueColor"
        }`}
      >
        <div className="flex justify-start items-start flex-col gap-1">
          <span className="change_font font-bold">{year}</span>
          <h1 className="change_font md:text-2xl sm:text-lg text-2xl font-bold mb-2">
            {title}
          </h1>
          <p className="border border-white sm:text-base text-sm sm:px-6 px-4 sm:py-2 py-1 rounded-md">
            زیاتر ببینە
          </p>
        </div>
        <img
          src={
            console === "ps"
              ? images.playstationLogo
              : console === "xbox"
              ? images.xboxLogo
              : console === "nintendo"
              ? images.nintendoLogo
              : images.sega
          }
          className="w-[50%] h-full opacity-20"
          alt={title}
        />

        <div className="absolute left-0 -top-6 w-[50%] h-full">
          <img src={img} alt={title} className="w-full h-full" />
        </div>
      </Link>
    </>
  );
};

export default ConsoleCard;
