import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import SendData from "../../../hooks/useSendData";
import { CreatedWarning, Navbar } from "../../admin-components";
import {
  AiOutlineCheckCircle,
  AiOutlineClose,
  AiOutlineCloudUpload,
} from "react-icons/ai";
import { MdReportGmailerrorred } from "react-icons/md";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { ButtonLoading, Loading } from "../../../components";
import AddGameVideo from "./AddGameVideo";
import { API_URL, IMG_URL } from "../../../env";

const UpdateGames = () => {
  const { id } = useParams();
  const {
    data: gameData,
    loading: gameLoading,
    error: gameError,
  } = useFetch(`${API_URL}games/view/${id}`);
  const { createData, createError, createLoading, post } = SendData();

  // get staff data
  const {
    data: staffData,
    loading: staffLoading,
    staffError,
    refetchStaff,
  } = useFetch(`${API_URL}staff/list`);
  const [getDataStaff, setGetDataStaff] = useState([]);

  useEffect(() => {
    if (!staffLoading && staffData?.data?.length > 0) {
      setGetDataStaff(staffData.data);
    }
  }, [staffLoading, getDataStaff, staffData]);

  // get characters data
  const {
    data: characterData,
    loading: characterLoading,
    characterError,
    refetchcharacter,
  } = useFetch(`${API_URL}characters/list`);
  const [getDataCharacter, setGetDataCharacter] = useState([]);

  useEffect(() => {
    if (!characterLoading && characterData?.data?.length > 0) {
      setGetDataCharacter(characterData.data);
    }
  }, [characterLoading, getDataCharacter, characterData]);

  // get console data
  const {
    data: consoleData,
    loading: consoleLoading,
    consoleError,
    refetchconsole,
  } = useFetch(`${API_URL}consoles/list`);
  const [getDataConsole, setGetDataConsole] = useState([]);

  useEffect(() => {
    if (!consoleLoading && consoleData?.data?.length > 0) {
      setGetDataConsole(consoleData.data);
    }
  }, [consoleLoading, getDataConsole, consoleData]);

  // get console data
  const {
    data: genreData,
    loading: genreLoading,
    genreError,
    refetchgenre,
  } = useFetch(`${API_URL}genres/list`);
  const [getDataGenre, setGetDataGenre] = useState([]);

  useEffect(() => {
    if (!genreLoading && genreData?.data?.length > 0) {
      setGetDataGenre(genreData.data);
    }
  }, [genreLoading, getDataGenre, genreData]);

  // get company data
  const {
    data: companyData,
    loading: companyLoading,
    companyError,
    refetchCompany,
  } = useFetch(`${API_URL}companies/list`);
  const [getDataCompany, setGetDataCompany] = useState([]);

  useEffect(() => {
    if (!companyLoading && companyData?.data?.length > 0) {
      setGetDataCompany(companyData.data);
    }
  }, [companyLoading, getDataCompany, companyData]);

  const [img, setImg] = useState("");
  const [imgBg, setImgBg] = useState("");

  const [isSent, setIsSent] = useState(false);

  const defaultFormData = {
    title: "",
    kurdish_name: "",
    tag: "",
    quote: "",
    description: "",
    game_developer: "",
    game_publisher: "",
    duration: "",
    ign_rating: "",
    metacritic_rating: "",
    trailer: "",
    revenue: "",
    budget: "",
    designer: [],
    creator: [],
    translator: [],
    genres: [],
    consoles: [],
    characters: [],
    release_date: "",
    mode: "",
    background_image: "",
    poster_image: "",
    popularity: "",
    is_series: "",
    is_translated: "",
  };
  // setTimeout(() => {
  //   console.log(gameData?.game_developer?.id);
  //   gameData?.game_characters.forEach((character) => {
  //     console.log(character);
  //   });
  // }, 2000);
  const [formData, setFormData] = useState(defaultFormData);
  useEffect(() => {
    if (!gameLoading && gameData.data) {
      // Update formData with gameData when available
      setFormData({
        title: gameData?.data?.title || "", // Default title value
        tag: gameData?.data?.tag || "", // Default tag value
        kurdish_name: gameData?.data?.kurdish_name || "", // Default kurdish_name value
        quote: gameData?.data?.quote || "", // Default quote value
        game_developer: gameData?.data?.company_developer?.id || "", // Default game_developer value
        game_publisher: gameData?.data?.company_publisher?.id || "", // Default game_publisher value
        ign_rating: gameData?.data?.ign_rating || "", // Default ign_rating value
        metacritic_rating: gameData?.data?.metacritic_rating || "", // Default metacritic_rating value
        budget: gameData?.data?.budget || "", // Default budget value
        revenue: gameData?.data?.revenue || "", // Default revenue value
        creator: gameData?.data?.creator || "", // Default creator value
        translator: gameData?.data?.translator || "", // Default translator value
        designer: gameData?.data?.designer || "", // Default designer value
        trailer: gameData?.data?.trailer || "", // Default trailer value
        duration: gameData?.data?.duration || "", // Default duration value
        genres: gameData?.data?.genres || "", // Default genres value
        consoles: gameData?.data?.consoles || "", // Default consoles value
        characters: gameData?.data?.characters || "", // Default characters value
        description: gameData?.data?.description || "", // Default description value
        poster_image: "", // Leave empty for the poster_image field, or set it to a default value if needed
        background_image: "", // Leave empty for the background_image field, or set it to a default value if needed
        is_series: gameData?.data?.is_series || "0", // Leave empty for the is_series field, or set it to a default value if needed
        is_translated: gameData?.data?.is_translated || "0", // Leave empty for the is_translated field, or set it to a default value if needed
        release_date: gameData?.data?.release_date || "", // Default release_date value
        mode: gameData?.data?.mode || "", // Default mode value
        popularity: gameData?.data?.popularity || "", // Default popularity value
      });
    }
  }, [gameData, gameLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (createError) {
      setIsSent(false);
    }
  }, [createError]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [consoleOptions, setConsoleOptions] = useState([]);
  const [translatorOptions, setTranslatorOptions] = useState([]);
  const [designerOptions, setDesignerOptions] = useState([]);
  const [creatorOptions, setCreatorOptions] = useState([]);
  const [genreOptions, setGenreOptions] = useState([]);

  useEffect(() => {
    if (!gameLoading && !gameError && gameData) {
      setSelectedOptions(
        gameData?.data?.characters
          ? gameData?.data?.characters.map((character) => ({
              value: character.id,
              label: character.name,
            }))
          : []
      );
      setConsoleOptions(
        gameData?.data?.consoles
          ? gameData?.data?.consoles.map((console) => ({
              value: console.id,
              label: console.name,
            }))
          : []
      );
      setTranslatorOptions(
        gameData?.data?.staff?.translators
          ? gameData?.data?.staff?.translators.map((staff) => ({
              value: staff.id,
              label: staff.name,
            }))
          : []
      );
      setDesignerOptions(
        gameData?.data?.staff?.designers
          ? gameData?.data?.staff?.designers.map((staff) => ({
              value: staff.id,
              label: staff.name,
            }))
          : []
      );
      setCreatorOptions(
        gameData?.data?.staff?.creators
          ? gameData?.data?.staff?.creators.map((staff) => ({
              value: staff.id,
              label: staff.name,
            }))
          : []
      );
      setGenreOptions(
        gameData?.data?.genres
          ? gameData?.data?.genres.map((genre) => ({
              value: genre.id,
              label: genre.title,
            }))
          : []
      );
    }
  }, [gameLoading, gameError, gameData]);

  const optionsGenre = getDataGenre.map((genre) => ({
    value: genre.id,
    label: genre.title,
  }));

  const optionsConsole = getDataConsole.map((console) => ({
    value: console.id,
    label: console.name,
  }));
  const optionsTranslator = getDataStaff.map((staff) => ({
    value: staff.id,
    label: staff.name,
  }));
  const optionsCreator = getDataStaff.map((staff) => ({
    value: staff.id,
    label: staff.name,
  }));
  const optionsDesigner = getDataStaff.map((staff) => ({
    value: staff.id,
    label: staff.name,
  }));
  const options = getDataCharacter.map((character) => ({
    value: character.id,
    label: character.name,
  }));

  // adding the characters array
  const handleSelectChange = (selectedOption) => {
    if (
      !selectedOptions.some((option) => option.value === selectedOption.value)
    ) {
      setSelectedOptions([...selectedOptions, selectedOption]);
    }
    console.log(selectedOptions);
  };
  // removing the characters array
  const handleRemoveOption = (option) => {
    const updatedOptions = selectedOptions.filter((item) => item !== option);
    setSelectedOptions(updatedOptions);
  };
  // adding console to array
  const handleConsoleChange = (consoleOption) => {
    if (
      !consoleOptions.some((option) => option.value === consoleOption.value)
    ) {
      setConsoleOptions([...consoleOptions, consoleOption]);
    }
  };
  // removing the consoles array
  const handleRemoveConsole = (option) => {
    const updatedOptions = consoleOptions.filter((item) => item !== option);
    setConsoleOptions(updatedOptions);
  };
  // Adding genre to the array
  const handleGenreChange = (genreOption) => {
    if (!genreOptions.some((option) => option.value === genreOption.value)) {
      setGenreOptions([...genreOptions, genreOption]);
    }
  };
  // removing the genres array
  const handleRemoveGenre = (option) => {
    const updatedOptions = genreOptions.filter((item) => item !== option);
    setGenreOptions(updatedOptions);
  };
  // adding the translator array
  const handleTranslatorChange = (translatorOption) => {
    if (
      !translatorOptions.some(
        (option) => option.value === translatorOption.value
      )
    ) {
      setTranslatorOptions([...translatorOptions, translatorOption]);
    }
  };
  // removing the translator array
  const handleRemoveTranslator = (option) => {
    const updatedOptions = translatorOptions.filter((item) => item !== option);
    setTranslatorOptions(updatedOptions);
  };
  // adding the designer array
  const handleDesignerChange = (designerOption) => {
    if (
      !designerOptions.some((option) => option.value === designerOption.value)
    ) {
      setDesignerOptions([...designerOptions, designerOption]);
    }
  };
  // removing the translator array
  const handleRemoveDesigner = (option) => {
    const updatedOptions = designerOptions.filter((item) => item !== option);
    setDesignerOptions(updatedOptions);
  };
  const handleCreatorChange = (creatorOption) => {
    if (
      !creatorOptions.some((option) => option.value === creatorOption.value)
    ) {
      setCreatorOptions([...creatorOptions, creatorOption]);
    }
  };
  // removing the creators array
  const handleRemoveCreator = (option) => {
    const updatedOptions = creatorOptions.filter((item) => item !== option);
    setCreatorOptions(updatedOptions);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const characterIds = selectedOptions.map((option) => option.value);
    const consoleIds = consoleOptions.map((option) => option.value);
    const translatorIds = translatorOptions.map((option) => option.value);
    const creatorIds = creatorOptions.map((option) => option.value);
    const designerIds = designerOptions.map((option) => option.value);
    const genreIds = genreOptions.map((option) => option.value);

    const formDataToSend = new FormData();

    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("kurdish_name", formData.kurdish_name);
    formDataToSend.append("tag", formData.tag);
    formDataToSend.append("quote", formData.quote);

    formDataToSend.append("background_image", formData.background_image);
    formDataToSend.append("poster_image", formData.poster_image);
    formDataToSend.append("trailer", formData.trailer);

    formDataToSend.append("popularity", formData.popularity);
    formDataToSend.append("mode", formData.mode);
    formDataToSend.append("revenue", formData.revenue);
    formDataToSend.append("duration", formData.duration);
    formDataToSend.append("budget", formData.budget);
    formDataToSend.append("release_date", formData.release_date);
    formDataToSend.append("is_series", formData.is_series);
    formDataToSend.append("is_translated", formData.is_translated);

    formDataToSend.append("game_developer", formData.game_developer);
    formDataToSend.append("game_publisher", formData.game_publisher);

    formDataToSend.append("ign_rating", formData.ign_rating);
    formDataToSend.append("metacritic_rating", formData.metacritic_rating);

    formDataToSend.append("translator", translatorIds.toString());
    // translatorIds.forEach((translatorId, i) => {
    //   formDataToSend.append(`translator[${i}]`, translatorId);
    // });

    formDataToSend.append("designer", designerIds.toString());
    // designerIds.forEach((designerId, i) => {
    //   formDataToSend.append(`designer[${i}]`, designerId);
    // });

    formDataToSend.append("creator", creatorIds.toString());
    // creatorIds.forEach((creatorId, i) => {
    //   formDataToSend.append(`creator[${i}]`, creatorId);
    // });

    formDataToSend.append("genres", genreIds.toString());
    // genreIds.forEach((genreId, i) => {
    //   formDataToSend.append(`genres[${i}]`, genreId);
    // });
    formDataToSend.append("consoles", consoleIds.toString());
    // consoleIds.forEach((consoleId, i) => {
    //   formDataToSend.append(`consoles[${i}]`, consoleId);
    // });
    formDataToSend.append("characters", characterIds.toString());
    // characterIds.forEach((characterId, i) => {
    //   formDataToSend.append(`characters[${i}]`, characterId);
    // });

    try {
      await post(`${API_URL}games/update/${id}`, formDataToSend);
      setIsSent(true);
    } catch (error) {
      setIsSent(false);
    }
  };

  if (gameLoading || !gameData.data) {
    return <Loading />;
  } else {
    return (
      <>
        <Navbar
          exactPlace="نوێکردنەوەی یاری"
          secondPlace="یاری"
          secondPlaceLink="/admin/games"
        />

        <div className="flex__center flex-col w-full xl:flex-[0.8] mb-10 flex-1 mt-28 gap-8">
          <div className="flex__center md:flex-row flex-col md:gap-10 gap-5 w-full">
            <div className="flex__start flex-col gap-3 sm:w-auto w-full">
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="title" className=" text-lg">
                  ناو
                </label>
                <input
                  type="text"
                  id="title"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formData.title}
                  onChange={handleChange}
                  name="title"
                />
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="kurdish_name" className=" text-lg">
                  ناوی بەکوردی
                </label>
                <input
                  type="text"
                  id="kurdish_name"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formData.kurdish_name}
                  onChange={handleChange}
                  name="kurdish_name"
                />
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="tag" className=" text-lg">
                  تاگ
                </label>
                <input
                  type="text"
                  id="tag"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formData.tag}
                  onChange={handleChange}
                  name="tag"
                />
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="is_series" className=" text-lg">
                  زنجیرە یاریە؟
                </label>
                <select
                  type="text"
                  id="is_series"
                  className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[450px] w-full p-2.5 bg-backgroundSecondary"
                  value={formData.is_series}
                  onChange={handleChange}
                  name="is_series"
                >
                  <option value="0">نەخێر</option>{" "}
                  <option value="1">بەڵێ</option>
                </select>
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="poster_image" className=" text-lg">
                  پۆستەر
                </label>
                <input
                  type="file"
                  id="poster_image"
                  disabled={false}
                  className="hidden"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      poster_image: e.target.files[0],
                    });
                    setImg(e.target.files[0]);
                  }}
                  name="poster_image"
                />
                <div className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent flex items-center justify-between">
                  <div className="mx-3">
                    {img.name || gameData.data.poster_image}
                  </div>
                  <label
                    htmlFor="poster_image"
                    className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                  >
                    <AiOutlineCloudUpload />
                  </label>
                </div>
              </div>
            </div>
            <label
              htmlFor="poster_image"
              className="w-[220px] max-h-[300px] bg-backgroundSecondary rounded-md overflow-hidden"
            >
              <img
                src={
                  img
                    ? URL.createObjectURL(img)
                    : `${IMG_URL}games/poster_image/${gameData.data.poster_image}`
                }
                alt="poster_image"
                className="w-full h-full object-cover"
              />
            </label>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="budget">تێچوو</label>
              <input
                type="text"
                id="budget"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
                value={formData.budget}
                onChange={handleChange}
                name="budget"
              />
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="revenue">داهات</label>
              <input
                type="text"
                id="revenue"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
                value={formData.revenue}
                onChange={handleChange}
                name="revenue"
              />
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="duration">کاتی تەواوکردنی یاری</label>
              <input
                type="text"
                id="duration"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
                value={formData.duration}
                onChange={handleChange}
                name="duration"
              />
            </div>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="popularity">ڕیزبەندی</label>
              <input
                type="text"
                id="popularity"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
                value={formData.popularity}
                onChange={handleChange}
                name="popularity"
              />
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="mode">مۆدی یاری</label>
              <input
                type="text"
                id="mode"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
                value={formData.mode}
                onChange={handleChange}
                name="mode"
              />
            </div>
            <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="background_image">وێنەی باکگراوند</label>
              <input
                type="file"
                id="background_image"
                name="background_image"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    background_image: e.target.files[0],
                  });
                  setImgBg(e.target.files[0]);
                }}
                className="hidden"
              />
              <div className="outline-none border-slate-300 border rounded h-11 sm:w-[230px] w-full bg-transparent flex items-center justify-between">
                <div className="mx-3 whitespace-nowrap overflow-hidden w-full text-ellipsis">
                  {imgBg.name || gameData.data.background_image}
                </div>
                <label
                  htmlFor="background_image"
                  className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                >
                  <AiOutlineCloudUpload />
                </label>
              </div>
            </div>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="quote">وتە</label>
              <input
                id="quote"
                name="quote"
                value={formData.quote}
                onChange={handleChange}
                className="outline-none border-slate-300 border rounded h-11 sm:w-[230px] w-full bg-transparent flex items-center justify-between px-2"
              />
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="release_date">کاتی دەرچوونی</label>
              <input
                type="date"
                id="release_date"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
                value={formData.release_date}
                onChange={handleChange}
                name="release_date"
              />
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="trailer">ترایلەری یاری</label>
              <input
                type="text"
                id="trailer"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
                value={formData.trailer}
                onChange={handleChange}
                name="trailer"
              />
            </div>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="ign_rating">
                نمرەی <span className="change_font">IGN</span>
              </label>
              <input
                id="ign_rating"
                name="ign_rating"
                value={formData.ign_rating}
                onChange={handleChange}
                className="outline-none border-slate-300 border rounded h-11 sm:w-[230px] w-full bg-transparent flex items-center justify-between px-2"
              />
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="metacritic_rating">
                نمرەی <span className="change_font">Metacritic</span>
              </label>
              <input
                id="metacritic_rating"
                name="metacritic_rating"
                value={formData.metacritic_rating}
                onChange={handleChange}
                className="outline-none border-slate-300 border rounded h-11 sm:w-[230px] w-full bg-transparent flex items-center justify-between px-2"
              />
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="is_translated">وەرگێڕدراوە؟</label>
              <select
                id="is_translated"
                name="is_translated"
                value={formData.is_translated}
                onChange={handleChange}
                className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[230px] w-full p-2.5 bg-backgroundSecondary"
              >
                <option value="0" defaultValue="0">
                  وەرگێڕانی نیە
                </option>
                <option value="1">وەرگێرانی هەیە</option>
              </select>
            </div>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-[350px] w-full sm:px-0 px-2">
              <label htmlFor="characters">کەسایەتیەکان</label>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    outline: state.isFocused ? "none" : "none",
                    backgroundColor: state.isFocused ? "#202020" : "#202020",
                  }),
                }}
                options={options}
                isSearchable
                id="characters"
                onChange={handleSelectChange}
                placeholder="بەدوای کەسایەتی بگەڕێ"
              />
              {!gameLoading && gameData && selectedOptions && (
                <div className="flex__start gap-2 w-full">
                  <h3 className="flex-shrink-0">کەسایەتی:</h3>
                  {
                    <ul className="flex__start sm:w-[350px] w-full gap-1 flex-wrap">
                      {selectedOptions.map((option) => (
                        <>
                          <li key={option.value}>{option.label}</li>
                          <button
                            onClick={() => handleRemoveOption(option)}
                            className="bg-backgroundSecondary p-1 rounded-full"
                          >
                            <AiOutlineClose />
                          </button>
                        </>
                      ))}
                    </ul>
                  }
                </div>
              )}
            </div>
            <div className="flex__start gap-2 flex-col sm:w-[350px] w-full sm:px-0 px-2">
              <label htmlFor="consoles">کونسوڵەکان</label>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    outline: state.isFocused ? "none" : "none",
                    backgroundColor: state.isFocused ? "#202020" : "#202020",
                  }),
                }}
                options={optionsConsole}
                isSearchable
                id="consoles"
                onChange={handleConsoleChange}
                placeholder="بەدوای کونسوڵ بگەڕێ"
              />
              {!gameLoading && gameData && consoleOptions && (
                <div className="flex__start gap-2 w-full">
                  <h3 className="flex-shrink-0">کونسوڵ:</h3>
                  <ul className="flex__start sm:w-[350px] w-full gap-1 flex-wrap">
                    {consoleOptions.map((option) => (
                      <>
                        <li key={option.value}>{option.label}</li>
                        <button
                          onClick={() => handleRemoveConsole(option)}
                          className="bg-backgroundSecondary p-1 rounded-full"
                        >
                          <AiOutlineClose />
                        </button>
                      </>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-[350px] w-full sm:px-0 px-2">
              <label htmlFor="genre">ژانرا</label>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    outline: state.isFocused ? "none" : "none",
                    backgroundColor: state.isFocused ? "#202020" : "#202020",
                  }),
                }}
                options={optionsGenre}
                isSearchable
                id="genre"
                onChange={handleGenreChange}
                placeholder="بەدوای ژانرا بگەڕێ"
              />

              {!gameLoading && gameData && genreOptions && (
                <div className="flex__start gap-2 w-full">
                  <h3 className="flex-shrink-0">ژانرا:</h3>
                  <ul className="flex__start sm:w-[350px] w-full gap-1 flex-wrap">
                    {genreOptions.map((option) => (
                      <>
                        <li key={option.value}>{option.label}</li>
                        <button
                          onClick={() => handleRemoveGenre(option)}
                          className="bg-backgroundSecondary p-1 rounded-full"
                        >
                          <AiOutlineClose />
                        </button>
                      </>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="flex__start gap-2 flex-col sm:w-[350px] w-full sm:px-0 px-2">
              <label htmlFor="creator">ئامادەکار</label>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    outline: state.isFocused ? "none" : "none",
                    backgroundColor: state.isFocused ? "#202020" : "#202020",
                  }),
                }}
                options={optionsCreator}
                isSearchable
                id="creator"
                onChange={handleCreatorChange}
                placeholder="بەدوای ئامادەکار بگەڕێ"
              />

              {!gameLoading && gameData && creatorOptions && (
                <div className="flex__start gap-2 w-full">
                  <h3 className="flex-shrink-0">ئامادەکار:</h3>
                  <ul className="flex__start sm:w-[350px] w-full gap-1 flex-wrap">
                    {creatorOptions.map((option) => (
                      <>
                        <li key={option.value}>{option.label}</li>
                        <button
                          onClick={() => handleRemoveCreator(option)}
                          className="bg-backgroundSecondary p-1 rounded-full"
                        >
                          <AiOutlineClose />
                        </button>
                      </>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-[350px] w-full sm:px-0 px-2">
              <label htmlFor="designer">دیزاینەر</label>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    outline: state.isFocused ? "none" : "none",
                    backgroundColor: state.isFocused ? "#202020" : "#202020",
                  }),
                }}
                options={optionsDesigner}
                isSearchable
                id="designer"
                onChange={handleDesignerChange}
                placeholder="بەدوای دیزاینەر بگەڕێ"
              />

              {!gameLoading && gameData && designerOptions && (
                <div className="flex__start gap-2 w-full">
                  <h3 className="flex-shrink-0">دیزاینەر:</h3>
                  <ul className="flex__start sm:w-[350px] w-full gap-1 flex-wrap">
                    {designerOptions.map((option) => (
                      <>
                        <li key={option.value}>{option.label}</li>
                        <button
                          onClick={() => handleRemoveDesigner(option)}
                          className="bg-backgroundSecondary p-1 rounded-full"
                        >
                          <AiOutlineClose />
                        </button>
                      </>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="flex__start gap-2 flex-col sm:w-[350px] w-full sm:px-0 px-2">
              <label htmlFor="translator">وەرگێڕان</label>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    outline: state.isFocused ? "none" : "none",
                    backgroundColor: state.isFocused ? "#202020" : "#202020",
                  }),
                }}
                options={optionsTranslator}
                isSearchable
                id="translator"
                onChange={handleTranslatorChange}
                placeholder="بەدوای وەرگێڕ بگەڕێ"
              />

              {!gameLoading && gameData && translatorOptions && (
                <div className="flex__start gap-2 w-full">
                  <h3 className="flex-shrink-0">وەرگێڕ:</h3>
                  <ul className="flex__start sm:w-[350px] w-full gap-1 flex-wrap">
                    {translatorOptions.map((option) => (
                      <>
                        <li key={option.value}>{option.label}</li>
                        <button
                          onClick={() => handleRemoveTranslator(option)}
                          className="bg-backgroundSecondary p-1 rounded-full"
                        >
                          <AiOutlineClose />
                        </button>
                      </>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="game_publisher">کۆمپانیای بڵاوکەرەوە</label>
              <select
                name="game_publisher"
                id="game_publisher"
                onChange={handleChange}
                className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[350px] w-full p-2.5 bg-backgroundSecondary"
                value={formData.game_publisher}
              >
                <option value="none" defaultValue>
                  کۆمپانیایەک هەڵبژێرە
                </option>
                {getDataCompany.map((company) => (
                  <>
                    <option value={company.id}>{company.name}</option>
                  </>
                ))}
              </select>
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="game_developer">کۆمپانیای پەرەپێدەر</label>
              <select
                name="game_developer"
                id="game_developer"
                onChange={handleChange}
                className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[350px] w-full p-2.5 bg-backgroundSecondary"
                value={formData.game_developer}
              >
                <option value="none" defaultValue>
                  کۆمپانیایەک هەڵبژێرە
                </option>
                {getDataCompany.map((company) => (
                  <>
                    <option value={company.id}>{company.name}</option>
                  </>
                ))}
              </select>
            </div>
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="textarea">پێناسەکردنی</label>
            <textarea
              name="description"
              id="textarea"
              value={formData.description}
              onChange={handleChange}
              className="outline-none border-slate-300 border rounded h-32 md:w-[720px] sm:w-[450px] w-full bg-transparent px-2"
            />
          </div>
          <AddGameVideo key={id} />
          <button
            onClick={handleSubmit}
            type="submit"
            disabled={createLoading}
            className={`sm:w-[450px] w-[90%] relative h-[45px] bg-backgroundSecondary text-xl py-2 hover:bg-primary hover:text-black duration-300 rounded-md ${
              createLoading ? "disabled" : ""
            }`}
          >
            {!createLoading && <span>ناردن</span>}
            {createLoading && <ButtonLoading />}
          </button>
        </div>
        {isSent === true && (
          <CreatedWarning
            icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
            message={"بابەتەکە بەسەرکەوتوویی نوێکرایەوە"}
          />
        )}
        {createError && createError.response && (
          <CreatedWarning
            icon={<MdReportGmailerrorred className="text-9xl text-red" />}
            message={"تکایە لە دڵنیاببەوە هەموو زانیاریەکانت بەڕاستی داناوە"}
            moreInfo={
              createError && createError.response
                ? createError.response.data.message
                : "An error occurred"
            }
          />
        )}
      </>
    );
  }
};

export default UpdateGames;
