import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { GamePoster, Loading } from "../../components";
import { API_URL, IMG_URL } from "../../env";

const Search = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");

  const { data, loading, error } = useFetch(
    `${API_URL}games/all?search=${query}`
  );

  const [getData, setGetData] = useState([]);

  useEffect(() => {
    if (!loading && data && data.data && data.data.length > 0) {
      setGetData(data.data);
      // console.log(getData);
    }
  }, [data, loading]);

  if (loading || !data.data) {
    return <Loading />;
  } else {
    return (
      <section className="section">
        <div className="flex__start gap-10 flex-col w-full max-w-[1250px] xl:px-0 px-3 mb-10">
          <h1 className="sm:text-4xl text-2xl">
            ئەنجامی گەڕان بۆ <span className="change_font">{query}</span>
          </h1>
          <div className="flex-wrap w-full flex items-start justify-center sm:gap-x-5 gap-x-2 gap-y-5">
            {getData && getData.length > 0 ? (
              getData.map((game) => (
                <GamePoster
                  views={game.view_count}
                  rate={game.ign_rating}
                  title={game.title}
                  genre={game.genres}
                  img={`${IMG_URL}games/poster_image/${game.poster_image}`}
                  link={`/game-details/${game.id}`}
                  key={game.id}
                />
              ))
            ) : (
              <p>هیچ یاریەک نەدۆزرایەوە</p>
            )}
          </div>
        </div>
      </section>
    );
  }
};

export default Search;
