import React, { useEffect, useState } from "react";
import { CreatedWarning, Navbar } from "../../admin-components";
import { MdReportGmailerrorred } from "react-icons/md";
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
import images from "../../../assets/images";
import SendData from "../../../hooks/useSendData";
import useFetch from "../../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { ButtonLoading, Loading } from "../../../components";
import { API_URL, IMG_URL } from "../../../env";

const UpdateStaff = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(`${API_URL}staff/view/${id}`);
  const { createData, createError, createLoading, post } = SendData();

  const [img, setImg] = useState("");
  const [imgBg, setImgBg] = useState("");

  const [isSent, setIsSent] = useState(false);

  const defaultFormData = {
    name: "",
    description: "",
    poster: "",
    quote: "",
    background: "",
    instagram_link: "",
    facebook_link: "",
    popularity: "",
    job: "staff",
    role: "creator",
  };

  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    if (!loading && data && data.data) {
      // Update formData with data when available
      setFormData({
        name: data.data.name || "", // Default name value
        description: data.data.description || "", // Default description value
        poster: "", // Leave empty for the image field, or set it to a default value if needed
        quote: data.data.quote || "", // Default quote value
        background: "", // Default gender value
        instagram_link: data.data.instagram_link || "", // Default k_name value
        facebook_link: data.data.facebook_link || "", // Default voice_actor value
        popularity: data.data.popularity || "", // Default popularity value
        job: data.data.job || "", // Default job value
        role: data.data.role || "", // Default nickname value
      });
    }
  }, [data, loading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("name", formData.name);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("quote", formData.quote);
    formDataToSend.append("role", formData.role);

    formDataToSend.append("poster", formData.poster);
    formDataToSend.append("background", formData.background);

    formDataToSend.append("job", formData.job);

    formDataToSend.append("instagram_link", formData.instagram_link);
    formDataToSend.append("facebook_link", formData.facebook_link);
    formDataToSend.append("popularity", formData.popularity);

    try {
      await post(`${API_URL}staff/update/${id}`, formDataToSend);
    } catch (err) {}
    setIsSent(true);
  };
  if (loading || !data || !data.data) {
    return <Loading />;
  } else {
    return (
      <>
        <Navbar
          exactPlace="نوێکردنەوەی ستاف"
          secondPlace="ستاف"
          secondPlaceLink="/admin/staff"
        />

        <div className="flex__center flex-col w-full xl:flex-[0.8] mb-10 flex-1 mt-28 gap-8">
          <div className="flex__center md:flex-row flex-col md:gap-10 gap-5">
            <div className="flex__start flex-col gap-3">
              <div className="flex__start flex-col gap-1">
                <label htmlFor="title" className=" text-lg">
                  ناو
                </label>
                <input
                  type="text"
                  id="title"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-[330px] bg-transparent px-2"
                  value={formData.name}
                  onChange={handleChange}
                  name="name"
                />
              </div>
              <div className="flex__start flex-col gap-1">
                <label htmlFor="img" className=" text-lg">
                  وێنەی پرۆفایل
                </label>
                <input
                  type="file"
                  id="img"
                  disabled={false}
                  className="hidden"
                  onChange={(e) => {
                    setFormData({ ...formData, poster: e.target.files[0] });
                    setImg(e.target.files[0]);
                  }}
                  name="poster"
                />
                <div className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-[330px] bg-transparent flex items-center justify-between">
                  <div className="mx-3 font-normal text-right text-slate-300 whitespace-nowrap">
                    {img.name || data.data.poster}
                  </div>
                  <label
                    htmlFor="img"
                    className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                  >
                    <AiOutlineCloudUpload />
                  </label>
                </div>
              </div>
              <div className="flex justify-between sm:w-[450px] w-[330px]">
                <div className="flex__start flex-col flex-[0.4]">
                  <label
                    htmlFor="countries"
                    className="block mb-2 text-base font-medium "
                  >
                    کاری لە وێبسایت
                  </label>
                  <select
                    id="countries"
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                    className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none w-full p-2.5 bg-backgroundSecondary "
                  >
                    <option value="creator" selected>
                      ئامادەکار
                    </option>
                    <option value="translator">وەرگێر</option>
                    <option value="designer">دیزاینەر</option>
                  </select>
                </div>
                <div className="flex__start flex-col flex-[0.4]">
                  <label
                    htmlFor="countries"
                    className="block mb-2 text-base font-medium "
                  >
                    ڕۆڵ
                  </label>
                  <select
                    name="job"
                    id="countries"
                    className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none w-full p-2.5 bg-backgroundSecondary"
                    value={formData.job}
                    onChange={handleChange}
                  >
                    <option value="manager">بەڕێوەبەر</option>
                    <option value="supervisor">سەرپەرشتیار</option>
                    <option value="staff" selected>
                      ستاف
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <label
              htmlFor="img"
              className="w-[250px] h-[250px] bg-secondary rounded-full overflow-hidden"
            >
              <img
                src={
                  img
                    ? URL.createObjectURL(img)
                    : `${IMG_URL}staff/poster/${data.data.poster}`
                }
                alt=""
                className="w-full h-full object-cover"
              />
            </label>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col">
            <div className="flex__start gap-2 flex-col">
              <label htmlFor="quote">وتە</label>
              <input
                type="text"
                id="quote"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[350px] w-[330px] bg-transparent px-2"
                value={formData.quote}
                onChange={handleChange}
                name="quote"
              />
            </div>
            <div className="flex__start flex-col gap-1">
              <label htmlFor="background">وێنەی کەڤەر</label>
              <input
                type="file"
                id="background"
                name="background"
                onChange={(e) => {
                  setFormData({ ...formData, background: e.target.files[0] });
                  setImgBg(e.target.files[0]);
                }}
                className="hidden"
              />
              <div className="outline-none border-slate-300 border rounded h-11 sm:w-[350px] w-[330px] bg-transparent flex items-center justify-between">
                <div className="mx-3 w-full font-normal text-right text-slate-300 whitespace-nowrap">
                  {imgBg.name || data.data.background}
                </div>
                <label
                  htmlFor="background"
                  className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                >
                  <AiOutlineCloudUpload />
                </label>
              </div>
            </div>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col">
            <div className="flex__start gap-2 flex-col">
              <label htmlFor="fb_link">لینکی فەسبووک</label>
              <input
                type="text"
                id="fb_link"
                value={formData.facebook_link}
                onChange={handleChange}
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-[330px] bg-transparent px-2"
                name="facebook_link"
              />
            </div>
            <div className="flex__start gap-2 flex-col">
              <label htmlFor="ig_link">لینکی ئینستاگرام</label>
              <input
                type="text"
                value={formData.instagram_link}
                onChange={handleChange}
                id="ig_link"
                name="instagram_link"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-[330px] bg-transparent px-2"
              />
            </div>
            <div className="flex__start gap-2 flex-col">
              <label htmlFor="popularity">ڕیزبەندی</label>
              <input
                type="text"
                value={formData.popularity}
                onChange={handleChange}
                id="popularity"
                name="popularity"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-[330px] bg-transparent px-2"
              />
            </div>
          </div>
          <div className="flex__start gap-2 flex-col">
            <label htmlFor="textarea">پێناسەکردنی</label>
            <textarea
              name="description"
              id="textarea"
              value={formData.description}
              onChange={handleChange}
              className="outline-none border-slate-300 border rounded h-32 md:w-[720px] sm:w-[450px] w-[330px] bg-transparent px-2"
            />
          </div>
          <button
            onClick={handleSubmit}
            type="submit"
            disabled={createLoading}
            className={`sm:w-[450px] w-[90%] relative h-[45px] bg-backgroundSecondary text-xl py-2 hover:bg-primary hover:text-black duration-300 rounded-md ${
              createLoading ? "disabled" : ""
            }`}
          >
            {!createLoading && <span>ناردن</span>}
            {createLoading && <ButtonLoading />}
          </button>
        </div>
        {isSent && !createError && (
          <CreatedWarning
            icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
            message={"بابەتەکە بەسەرکەوتوویی نوێکرایەوە"}
          />
        )}
        {createError && createError.response && (
          <CreatedWarning
            icon={<MdReportGmailerrorred className="text-9xl text-red" />}
            message={"تکایە لە دڵنیاببەوە هەموو زانیاریەکانت بەڕاستی داناوە"}
            moreInfo={
              createError && createError.response
                ? createError.response.data.message
                : "An error occurred"
            }
          />
        )}
      </>
    );
  }
};

export default UpdateStaff;
