import React from "react";

import { AiFillHome, AiOutlineLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
const BreadCrumb = ({ exactPlace, secondPlace, secondPlaceLink }) => {
  return (
    <>
      <div className="flex__center sm:gap-5 gap-2">
        <Link to="/" className="text-gray-300 link_hover flex__center">
          <AiFillHome className="ml-3" /> ماڵەوە
        </Link>
        <AiOutlineLeft />
        <Link to={secondPlaceLink} className="text-gray-300 link_hover">
          {secondPlace}
        </Link>
        <AiOutlineLeft />
        <span className="change_font sm:w-auto w-[13ch] overflow-hidden whitespace-nowrap text-ellipsis">
          {exactPlace}
        </span>
      </div>
    </>
  );
};

export default BreadCrumb;
