import React from "react";
import { Link } from "react-router-dom";

const DetailsCard = ({
  link,
  title,
  img,
  person,
  personImg,
  personLink,
  time,
}) => {
  return (
    <>
      <div className="flex__center flex-col w-[380px] overflow-hidden rounded-md">
        <Link to={link} title={title} className="w-full h-[225px]">
          <img src={img} className="w-full h-full" alt={title} />
        </Link>
        <div className="flex__center w-full flex-col gap-2 bg-backgroundSecondary">
          <Link
            title={title}
            to={link}
            className="text-xl py-2 link_hover border-b border-b-backgroundTerinary w-full text-center"
          >
            {title}
          </Link>
          <div className="flex justify-between items-center px-2 pb-2 w-full">
            {personImg && person && (
              <div className="flex__center gap-2">
                <span className="text-slate-300">لەلایەن:</span>
                <Link
                  to={personLink}
                  className="w-[35px] h-[35px] rounded-full overflow-hidden"
                >
                  <img
                    src={personImg}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </Link>
                <Link
                  to={personLink}
                  className="link_hover change_font font-bold"
                >
                  {person}
                </Link>
              </div>
            )}
            <span className="change_font">{time}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsCard;
