import React, { useEffect, useState } from "react";
import images from "../../../assets/images/index";
import { HeaderComponent } from "../../../components/index";
import ComingSoonCard from "../../../pages/ComingSoon/ComingSoonComponent/ComingSoonCard";
import useFetch from "../../../hooks/useFetch";
import { API_URL, IMG_URL } from "../../../env";

const ComingSoonComponent = () => {
  const { data, loading, error } = useFetch(
    `${API_URL}coming-soons/all?perPage=2`
  );

  const [getData, setGetData] = useState([]);

  useEffect(() => {
    if (!loading && data && data.data && data.data.length > 0) {
      setGetData(data.data);
    }
  }, [data, loading]);
  const dateFormat = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  return (
    <>
      <div className="flex__center w-full flex-col gap-5 sm:my-20 my-10">
        <HeaderComponent title="یاریە چاوەڕوانکراوەکان" link="comingsoon" />
        <div className="flex justify-between gap-3 items-center w-full max-w-[1250px] sm:flex-row flex-col xl:px-0 px-3">
          {getData.map((comingSoon) => (
            <ComingSoonCard
              date={comingSoon.release_date}
              img={`${IMG_URL}comingSoons/${comingSoon.background_image}`}
              link={`/comingsoon/${comingSoon.id}`}
              title={comingSoon.title}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ComingSoonComponent;
