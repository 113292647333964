import React, { useState } from "react";
import images from "../../../../assets/images";
import { BsFillPlayFill } from "react-icons/bs";
import useFetch from "../../../../hooks/useFetch";
import { useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { API_URL } from "../../../../env";

const GameDetailsStream = ({ trailer, videoes, isTranslated }) => {
  const token = Cookies.get("token");
  const [vidId, setVidId] = useState();
  // const { data, loading, error, fetchData } = useFetch();
  // // const [getData, setGetData] = useState([]);
  // useEffect(() => {
  //   if (vidId) {
  //     fetchData(`https://gamewebsite.xyz/api/game-videos/${vidId}`);
  //   }
  //   console.log(data);
  // }, [vidId, fetchData]);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchData = async (id) => {
    setLoading(true);
    try {
      const res = await axios.get(`${API_URL}game-videos/view/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(res);
      setLoading(false);
      setData(res.data.data);
    } catch (err) {
      setError(err);
    }
  };
  useEffect(() => {
    if (data) {
      // console.log(data.video_urls[0].url);
    }
  }, [data]);
  const handleVideoPlayer = (id) => {
    setVidId(id);
    fetchData(id);
    document.body.style.overflowY = "hidden";
  };
  const handlecloseVideo = () => {
    setVidId(null);
    document.body.style.overflowY = "auto";
  };
  if (videoes === undefined) {
    return <h1>هەڵە</h1>;
  } else {
    return (
      <>
        <div className="w-full flex-col flex__center gap-5">
          <div className="md:w-[80%] w-full md:h-[500px] h-[300px]">
            <iframe
              src={trailer}
              scrolling="no"
              frameBorder="0"
              allowFullScreen={true}
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              className="w-full h-full rounded-lg"
              title="video"
            ></iframe>
          </div>
          {isTranslated == 1 && videoes.length > 0 && (
            <h2 className="md:text-3xl text-2xl">تەواوی بەشەکان</h2>
          )}
          {isTranslated == 1 && videoes.length > 0 && (
            <div className="w-full flex__center flex-wrap gap-5">
              {videoes.map((vid) => (
                <>
                  <button
                    key={vid.id}
                    onClick={() => handleVideoPlayer(vid.id)}
                    className="flex__center gap-2 flex-col bg-backgroundSecondary duration-300 hover:bg-backgroundTerinary rounded-lg overflow-hidden pb-3"
                  >
                    <div className="relative sm:w-64 w-36 sm:h-36 h-auto flex__center">
                      <img
                        src={vid.background_image}
                        alt={vid.title}
                        className="sm:w-64 w-36 sm:h-36 h-auto object-cover"
                      />
                      <div className="bg-[#00000090] backdrop-blur-sm absolute top-0 left-0 w-full h-full flex__center opacity-0 hover:opacity-100 duration-300">
                        <span className="w-[50px] h-[50px] rounded-full border border-primary flex__center ">
                          <BsFillPlayFill className="text-3xl text-primary" />
                        </span>
                      </div>
                    </div>
                    <h3 className="text-lg">{vid.title}</h3>
                  </button>
                  {vid.id === vidId && (
                    <>
                      <div className="fixed w-full bg-background max-w-[1250px] xl:px-0 px-3 h-[400px] z-20 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
                        <iframe
                          src={data?.video_urls[0]?.url}
                          scrolling="no"
                          frameborder="0"
                          allowfullscreen="true"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          className="w-full h-full rounded-lg"
                          title="video"
                        ></iframe>
                      </div>
                      <button
                        onClick={handlecloseVideo}
                        className="bg-background bg-opacity-90 fixed top-0 left-0 w-full h-full z-10"
                      ></button>
                    </>
                  )}
                </>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
};

export default GameDetailsStream;
