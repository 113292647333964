import React, { useEffect, useState } from "react";
import images from "../../assets/images";
import { GamePoster, Loading } from "../../components/index";
import useFetch from "../../hooks/useFetch";
import { BsArrowLeftShort, BsFilter } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { API_URL, IMG_URL } from "../../env";

const Games = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isTranslated, setIsTranslated] = useState(0);
  const [filterIgn, setFilterIgn] = useState("");
  const [genreFilter, setGenreFilter] = useState("");
  const itemsPerPage = 15;
  const indexOfLastItem = currentPage * itemsPerPage;
  const { data, loading, error, refetchPagination } = useFetch(
    `${API_URL}games/all?page=${currentPage}&perPage=${itemsPerPage}&isTranslated=${isTranslated}&genres=${genreFilter}`
  );
  const {
    data: genreData,
    loading: genreLoading,
    genreError,
    refetchgenre,
  } = useFetch(`${API_URL}genres/list`);
  const [getDataGenre, setGetDataGenre] = useState([]);
  useEffect(() => {
    if (!genreLoading && genreData?.data?.length > 0) {
      setGetDataGenre(genreData.data);
    }
  }, [loading, genreData]);

  const [getData, setGetData] = useState([]);

  const [filterOpen, setFilterOpen] = useState(false);

  const filterOpenHandler = () => {
    setFilterOpen(!filterOpen);
  };
  const totalPageCount = Math.ceil(data?.pagination?.totalCount / itemsPerPage);

  const fetchData = async (page) => {
    try {
      const result = await refetchPagination(
        `${API_URL}games/all?perPage=${page}&page=${itemsPerPage}`
      );
      if (result) {
        setGetData(result.data);
      }
    } catch (error) {}
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage, () => {
      fetchData(newPage);
    });
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let index = currentPage - 5; index < currentPage + 5; index++) {
      if (index > 0 && index <= totalPageCount) {
        pageNumbers.push(index);
      }
    }
    return pageNumbers;
  };

  useEffect(() => {
    if (!loading) {
      setGetData(data?.data || []);
    }
  }, [loading, currentPage, isTranslated, filterIgn, genreFilter]);

  const ignArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  // translate filter
  const getTranslatedData = () => {
    if (isTranslated === 0) {
      setIsTranslated(1);
    } else {
      setIsTranslated(0);
    }
    setCurrentPage((prevPage) => 1);
  };
  const handleIGNFilter = (filter) => {
    setFilterIgn(filter);
    setCurrentPage((prevPage) => 1);
    // console.log(filterIgn);
  };
  const handleGenreFilter = (filter) => {
    setGenreFilter(filter);
    setCurrentPage((prevPage) => 1);
  };
  const removeFilter = () => {
    setGenreFilter("");
    setCurrentPage((prevPage) => 1);
    setIsTranslated(0);
    setFilterIgn("");
    setFilterOpen(false);
  };

  if (loading || !data.data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section">
          {getData.length > 0 && (
            <div className="flex__center gap-10 flex-col w-full max-w-[1250px] xl:px-0 px-3 mb-10">
              <h1 className="sm:text-4xl text-2xl">یاریەکان</h1>
              <div className="flex-wrap w-full flex items-start justify-center sm:gap-x-5 gap-x-2 gap-y-5">
                {getData.map((game) => (
                  <GamePoster
                    views={game.view_count}
                    rate={game.ign_rating}
                    title={game.title}
                    genre={game.genres}
                    img={`${IMG_URL}games/poster_image/${game.poster_image}`}
                    link={`/game-details/${game.id}`}
                    key={game.id}
                  />
                ))}
              </div>
              <nav
                className="flex items-center justify-center w-full pt-4"
                aria-label="Table navigation"
              >
                <ul className="inline-flex w-full items-center justify-center -space-x-px text-sm h-8">
                  <li>
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className={`flex items-center justify-center px-3 h-8 leading-tight bg-background border border-backgroundTerinary rounded-r-lg hover:bg-gray-900 ${
                        currentPage === 1 ? "text-gray-500" : "text-white"
                      }`}
                    >
                      <BsArrowLeftShort className="text-xl rotate-180" />
                      <span>پێشتر</span>
                    </button>
                  </li>
                  <div className="flex overflow-x-auto pagination">
                    {generatePageNumbers().map((pageNumber) => (
                      <li key={pageNumber}>
                        <button
                          onClick={() => handlePageChange(pageNumber)}
                          className={`flex items-center justify-center px-3 h-8 leading-tight change_font bg-background border border-backgroundTerinary ${
                            currentPage === pageNumber
                              ? "text-black bg-primary"
                              : "text-slate-300"
                          }`}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    ))}
                  </div>
                  <li>
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={indexOfLastItem >= data?.pagination?.totalCount}
                      className={`flex items-center justify-center gap-1 px-3 h-8 leading-tight bg-background border border-backgroundTerinary rounded-l-lg hover:bg-gray-900 ${
                        indexOfLastItem >= data?.pagination?.totalCount
                          ? "text-gray-500 "
                          : "text-white"
                      }`}
                    >
                      <span>دواتر</span>
                      <BsArrowLeftShort className="text-xl" />
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          )}
          {getData.length <= 0 && (
            <div className="flex__center w-full sm:mt-0 mt-20 mb-10 max-w-[1250px] xl:px-0 px-3">
              <div className="flex__center flex-col sm:w-[90%] w-full flex-shrink-0 gap-5">
                <div className="md:w-[300px] sm:w-[400px] w-[300px] h-auto overflow-hidden rounded-md">
                  <img
                    src={images.mario}
                    className="w-full h-full"
                    alt="Mario"
                  />
                </div>
                <h1 className="text-2xl">هیچ یاریەک نەدۆزرایەوە</h1>
              </div>
            </div>
          )}
          <button
            className="fixed bottom-5 right-5 bg-backgroundSecondary w-14 h-14 flex__center rounded-full"
            onClick={filterOpenHandler}
          >
            <BsFilter className="text-4xl" />
          </button>
          {/* filter */}
          <div
            className={`fixed right-0 top-0 duration-300 bg-backgroundSecondary overflow-hidden h-[100vh] overflow-y-auto ${
              !filterOpen ? "w-0" : "overflow-y-auto sm:w-[400px] w-full z-50"
            }`}
          >
            <div className="w-full flex justify-between gap-2 items-start p-3">
              <h3 className="text-2xl">پۆلێن کردن</h3>
              <button
                className="text-xl bg-backgroundTerinary rounded-full w-[30px] h-[30px] flex__center"
                onClick={filterOpenHandler}
              >
                <AiOutlineClose />
              </button>
            </div>
            <ul className="flex__start w-full flex-col gap-3 p-3">
              <li className="flex__start w-full flex-col gap-1 border-b-2 border-b-backgroundTerinary pb-3">
                <h3 className="text-xl w-full ">ژانراکان</h3>
                <div className="w-full flex justify-start items-start flex-wrap gap-3">
                  {getDataGenre.map((genre, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        handleGenreFilter(genre.id);
                      }}
                      className={`border p-1 rounded ${
                        genreFilter === genre.id
                          ? "bg-primary text-black border-primary"
                          : "text-slate-300 border-slate-300"
                      }`}
                    >
                      {genre.title}
                    </button>
                  ))}
                </div>
              </li>
              <li className="flex__start w-full flex-col gap-1 border-b-2 border-b-backgroundTerinary pb-3">
                <h3 className="text-xl w-full ">
                  نمرەی <span className="change_font">IGN</span>
                </h3>
                <div className="flex__start gap-3 w-full flex-wrap">
                  {ignArray.map((i, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        handleIGNFilter(i);
                      }}
                      className={`border w-[35px] h-[35px] rounded ${
                        filterIgn === i
                          ? "bg-primary text-black border-primary"
                          : "text-slate-300 border-slate-300"
                      }`}
                    >
                      {i}
                    </button>
                  ))}
                </div>
              </li>
              <li className="flex__start w-full flex-col gap-1 border-b-2 border-b-backgroundTerinary pb-3">
                <h3 className="text-xl w-full ">ئەوانی تر</h3>
                <button
                  className={`border  p-1 rounded ${
                    isTranslated === 1
                      ? "bg-primary text-black border-primary"
                      : "text-slate-300 border-slate-300"
                  }`}
                  onClick={getTranslatedData}
                >
                  وەرگێڕدراوەکان
                </button>
              </li>
              <li className="flex__start w-full flex-col gap-1 border-b-2 border-b-backgroundTerinary pb-3">
                <button onClick={removeFilter} className="primary-button">
                  لابردنی هەمووی
                </button>
              </li>
            </ul>
          </div>
        </section>
      </>
    );
  }
};

export default Games;
