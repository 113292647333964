import React from "react";

import { Link } from "react-router-dom";
const CategoriesCard = ({ title, img, link }) => {
  return (
    <>
      <Link
        title={title}
        to={link}
        className="lg:w-[250px] w-[45%] h-auto sm:w-[180px]  bg-backgroundSecondary p-2 duration-300 hover:bg-backgroundTerinary rounded-lg overflow-hidden animation_cat_card"
      >
        <div className="w-full h-full">
          <img src={img} className="w-full h-full" alt="img" />
          {/* <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-20"></div> */}
        </div>
        {/* <h2 className="text-2xl absolute right-[5%] bottom-[5%]">{title}</h2> */}
      </Link>
    </>
  );
};

export default CategoriesCard;
