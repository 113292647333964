import React, { useEffect, useState } from "react";
import images from "../../assets/images";

import GameSeriesCard from "./GameSeriesComponent/GameSeriesCard";
import useFetch from "../../hooks/useFetch";
import { Loading } from "../../components";
import { BsArrowLeftShort } from "react-icons/bs";
import { API_URL, IMG_URL } from "../../env";

const GameSeries = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const { data, loading, error, refetchPagination } = useFetch(
    `${API_URL}games/series?perPage=${itemsPerPage}&page=${currentPage}`
  );
  const [getData, setGetData] = useState([]);
  const totalPageCount = Math.ceil(data?.pagination?.totalCount / itemsPerPage);
  const fetchData = async (page) => {
    try {
      const result = await refetchPagination(
        `${API_URL}games/series?perPage=${itemsPerPage}&page=${page}`
      );
      if (result) {
        setGetData(result.data);
      }
    } catch (error) {}
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage, () => {
      fetchData(newPage);
    });
  };
  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let index = currentPage - 5; index < currentPage + 5; index++) {
      if (index > 0 && index <= totalPageCount) {
        pageNumbers.push(index);
      }
    }
    return pageNumbers;
  };
  useEffect(() => {
    if (!loading && data?.data?.length > 0) {
      setGetData(data.data);
      console.log(getData);
    }
  }, [loading, data]);
  if (loading || !data.data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section">
          <div className="flex__center flex-col w-full max-w-[1250px] xl:px-0 px-3 mb-10 gap-10">
            <h1 className="sm:text-4xl text-2xl">زنجیرە یاریەکان</h1>
            <div className="w-full flex__center md:flex-row flex-col flex-wrap gap-5">
              {getData.map((series, i) => (
                <GameSeriesCard
                  title={series.tag}
                  link={`/game-series/${series.tag}`}
                  img={`${IMG_URL}games/background_image/${series.background_image}`}
                  // genre={series.game_genres}
                  parts={series.game_count}
                  company={series?.game_publisher}
                  id={i}
                  key={i}
                />
              ))}
            </div>
            <nav
              className="flex items-center justify-center w-full pt-4"
              aria-label="Table navigation"
            >
              <ul className="inline-flex w-full items-center justify-center -space-x-px text-sm h-8">
                <li>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`flex items-center justify-center px-3 h-8 leading-tight bg-background border border-backgroundTerinary rounded-r-lg hover:bg-gray-900 ${
                      currentPage === 1 ? "text-gray-500" : "text-white"
                    }`}
                  >
                    <BsArrowLeftShort className="text-xl rotate-180" />
                    <span>پێشتر</span>
                  </button>
                </li>
                <div className="flex overflow-x-auto pagination">
                  {generatePageNumbers().map((pageNumber) => (
                    <li key={pageNumber}>
                      <button
                        onClick={() => handlePageChange(pageNumber)}
                        className={`flex items-center justify-center px-3 h-8 leading-tight change_font bg-background border border-backgroundTerinary ${
                          currentPage === pageNumber
                            ? "text-black bg-primary"
                            : "text-slate-300"
                        }`}
                      >
                        {pageNumber}
                      </button>
                    </li>
                  ))}
                </div>
                <li>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={indexOfLastItem >= data?.pagination?.totalCount}
                    className={`flex items-center justify-center gap-1 px-3 h-8 leading-tight bg-background border border-backgroundTerinary rounded-l-lg hover:bg-gray-900 ${
                      indexOfLastItem >= data?.pagination?.totalCount
                        ? "text-gray-500 "
                        : "text-white"
                    }`}
                  >
                    <span>دواتر</span>
                    <BsArrowLeftShort className="text-xl" />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </section>
      </>
    );
  }
};

export default GameSeries;
