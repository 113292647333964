import React from "react";
import images from "../../../assets/images";
import { BreadCrumb, Loading } from "../../../components/index";
import FavAndStaffGameDetails from "./GameDetailsComponent/FavAndStaffGameDetails";
import GameDetailsStream from "./GameDetailsComponent/GameDetailsStream";
import InfoGameDetails from "./GameDetailsComponent/InfoGameDetails";
import PosterCharacter from "./GameDetailsComponent/PosterCharacter";
import SimilarGameDetails from "./GameDetailsComponent/SimilarGameDetails";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { API_URL, IMG_URL } from "../../../env";

const GameDetails = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(`${API_URL}games/view/${id}`);
  if (loading || !data.data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="flex__center w-full flex-col mb-10">
          <div className="w-full flex__start relative flex-col">
            <div className="w-full rounded-b-xl h-[80vh] absolute">
              <img
                src={`${IMG_URL}games/background_image/${data.data.background_image}`}
                alt={data.data.title}
                className="w-full h-full object-cover bg-cover opacity-20"
              />
            </div>
          </div>
          <div className="mt-20 z-10 w-full max-w-[1250px] xl:px-0 px-3 flex__start">
            <BreadCrumb
              exactPlace={data.data.title}
              key={data.data.id}
              secondPlace="یاریەکان"
              secondPlaceLink="/games"
            />
          </div>
          <div className="flex items-start lg:gap-10 gap-5 justify-between w-full max-w-[1250px] xl:px-0 px-3 mt-40 z-10 lg:flex-row flex-col">
            <div className="md:flex-[0.3] flex-1 w-full flex md:items-start items-center md:justify-start justify-center flex-col gap-5">
              <PosterCharacter
                poster={`${IMG_URL}games/poster_image/${data.data.poster_image}`}
                key={data.data.id}
                characters={data.data.characters}
              />
            </div>
            <div className="lg:flex-[0.4] md:flex-[0.3] flex-1 w-full">
              <InfoGameDetails
                title={data.data.title}
                ign={data.data.ign_rating}
                mode={data.data.mode}
                duration={data.data.duration}
                kg={data.data.ratings_avg_rating}
                metaCritic={data.data.metacritic_rating}
                relaseDate={data.data.release_date}
                story={data.data.description}
                revenue={data.data.revenue}
                publisherCompany={data?.data?.company_publisher?.name}
                publisherCompanyId={data?.data?.company_publisher?.id}
                devCompany={data?.data?.company_developer?.name}
                devCompanyId={data?.data?.company_developer?.id}
                consoles={data.data.consoles}
                kurdishTitle={data.data.kurdish_name}
                genre={data.data.genres}
                trailer={data.data.trailer}
                id={data.data.id}
                key={data.data.id}
              />
            </div>
            <div className="lg:flex-[0.3] md:flex-[0.4] flex-1 w-full lg:mt-64 mt-5">
              <FavAndStaffGameDetails
                id={data.data.id}
                key={data.data.id}
                creators={data.data.staff.creators}
                designers={data.data.staff.designers}
                translators={data.data.staff.translators}
                isTranslated={data.data.is_translated}
              />
            </div>
          </div>
          {data.data.quote && (
            <div className="mt-10 w-full bg-backgroundSecondary py-5">
              <h1 className="text-xl text-center">"{data.data.quote}"</h1>
            </div>
          )}
          <div className="w-full max-w-[1250px] xl:px-0 px-3 mt-10">
            <GameDetailsStream
              trailer={data.data.trailer}
              videoes={data.data.videos}
              key={data.data.id}
              isTranslated={data.data.is_translated}
            />
          </div>
          <div className="w-full max-w-[1250px] xl:px-0 px-3 mt-10 flex__center">
            <SimilarGameDetails tag={data.data.tag} />
          </div>
        </section>
      </>
    );
  }
};

export default GameDetails;
