import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, createContext, useReducer } from "react";
import { API_URL } from "../env";

const INITIAL_STATE = {
  user: null,
  loading: false,
  error: null,
};

export const AuthContext = createContext(INITIAL_STATE);

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOAGIN_START":
      return {
        user: null,
        loading: true,
        error: null,
      };
    case "LOGIN_SUCCESS":
      return {
        user: action.payload,
        loading: false,
        error: null,
      };
    case "LOGIN_FAILURE":
      return {
        user: null,
        loading: false,
        error: action.payload,
      };
    case "LOGOUT":
      return {
        user: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export const fetchUserFromAPI = async (dispatch) => {
  const token = Cookies.get("token");
  try {
    // Dispatch a "LOGIN_START" action to set loading to true
    dispatch({ type: "LOGIN_START" });

    // Make an Axios request to fetch the user data
    // const response = await axios.get("https://gamewebsite.xyz/api/user");
    const response = await axios.get(`${API_URL}auth/user/info`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const userData = response.data; // Assuming the response contains user data

    // Dispatch a "LOGIN_SUCCESS" action with the user data
    dispatch({ type: "LOGIN_SUCCESS", payload: userData });

    return userData; // Return the user data
  } catch (error) {
    // Dispatch a "LOGIN_FAILURE" action with the error message
    dispatch({ type: "LOGIN_FAILURE", payload: error.message });
    throw error; // Rethrow the error
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
  const updateUserFromAPI = async () => {
    try {
      const token = Cookies.get("token");

      // Dispatch a "LOGIN_START" action to set loading to true
      dispatch({ type: "LOGIN_START" });

      // Make an Axios request to fetch the user data
      const response = await axios.get(`${API_URL}auth/user/info`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const userData = response.data; // Assuming the response contains user data
      // console.log(userData);
      // Log the state changes for debugging
      // console.log("State before update:", state);
      // Dispatch a "LOGIN_SUCCESS" action with the updated user data
      dispatch({ type: "LOGIN_SUCCESS", payload: userData });
      // console.log("State after update:", state);
    } catch (error) {
      // Dispatch a "LOGIN_FAILURE" action with the error message
      dispatch({ type: "LOGIN_FAILURE", payload: error.message });
    }
  };

  // useEffect(() => {
  //   localStorage.setItem("user", JSON.stringify(state.user));
  // }, [state.user]);

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        loading: state.loading,
        error: state.error,
        dispatch,
        updateUserFromAPI,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
