import React, { useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";
import images from "../../assets/images";
import { BreadCrumb, ByWho, Loading } from "../../components";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { API_URL, IMG_URL } from "../../env";

const ReviewsDetail = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(`${API_URL}dives/view/${id}`);

  const dateFormat = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    if (data.data && !loading) {
      setTimeout(() => {
        let circle = document.getElementById("circleValue");
        let angle = 0;
        let review = +data.data.kurd_game_rating || 0;
        let timer = setInterval(() => {
          circle.setAttribute("stroke-dasharray", angle + ", 20000");
          if (angle >= (review / 10) * 360) {
            clearInterval(timer);
          }
          angle += 1;
        }, 15);
      }, 1000);
    }
  }, [data.data, loading]);

  if (loading || !data.data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section">
          <div className="w-full max-w-[1250px] xl:px-0 px-3 flex__center flex-col gap-10 mb-10">
            <div className="flex__start w-full">
              <BreadCrumb
                exactPlace={data.data.title}
                secondPlace="پێداچوونەوە"
                secondPlaceLink="/reviews"
                key={data.data.id}
              />
            </div>
            <div className="w-full flex__center flex-col gap-5">
              <div className="relative w-full">
                <img
                  src={`${IMG_URL}dives/${data.data.background_image}`}
                  alt={data.data.title}
                  className="w-full sm:h-[400px] h-auto rounded-lg object-cover"
                />
                <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
                  <svg viewBox="0 0 200 200" className="w-36 h-36">
                    <circle
                      cx="100"
                      cy="100"
                      r="57"
                      fill="none"
                      className="stroke-background"
                      stroke-width="20"
                      stroke-dasharray="360,20000"
                      transform="rotate(135,100,100)"
                    />
                    <circle
                      cx="100"
                      cy="100"
                      r="57"
                      id="circleValue"
                      fill="none"
                      className="stroke-primary"
                      stroke-width="20"
                      stroke-dasharray="0,20000"
                      transform="rotate(90,100,100)"
                    />
                    <text
                      id="myPoints"
                      text-anchor="middle"
                      x="100"
                      y="115"
                      className="fill-primary text-4xl"
                    >
                      {data.data.kurd_game_rating}
                    </text>
                  </svg>
                </div>
              </div>
              <div className="w-full flex__start flex-wrap sm:gap-x-10 gap-x-5 gap-y-3">
                <ByWho
                  imgPerson={`${IMG_URL}staff/poster/${data.data.staff?.image}`}
                  link={`/staff/${data.data.staff?.id}`}
                  time={dateFormat(data.data.created_at)}
                  title={data.data.staff?.name}
                />
                <div className="flex__center gap-2">
                  <span className="text-gray-300">کاتی خوێندنەوە : </span>
                  <span>
                    <span className="change_font">
                      {data.data.read_duration}
                    </span>
                    خولەک
                  </span>
                </div>
                <div className="flex__center gap-2">
                  <AiOutlineEye className="text-2xl" />
                  <span className="change_font">{data.data.view_count}</span>
                </div>
                <div className="flex__center gap-2">
                  <h3 className="text-gray-300">جۆر :</h3>
                  <h3>{data.data.dive_type}</h3>
                </div>
              </div>
              <div className="w-full border-t border-t-backgroundTerinary pt-5 flex__start flex-col gap-5">
                <h2 className="text-2xl">{data.data.title}</h2>
                <p className="paragraph">{data.data.description}</p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default ReviewsDetail;
