import React, { useState } from "react";
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
import images from "../../../assets/images";
import { CreatedWarning, Navbar } from "../../admin-components";
import SendData from "../../../hooks/useSendData";
import { MdReportGmailerrorred } from "react-icons/md";
import { ButtonLoading } from "../../../components";
import { API_URL } from "../../../env";
const AddStaff = () => {
  const { createData, createError, createLoading, post } = SendData();

  const [img, setImg] = useState("");
  const [imgBg, setImgBg] = useState("");

  const [isSent, setIsSent] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    poster: "",
    quote: "",
    background: "",
    instagram_link: "",
    facebook_link: "",
    popularity: "",
    job: "staff",
    role: "creator",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("name", formData.name);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("quote", formData.quote);
    formDataToSend.append("role", formData.role);

    formDataToSend.append("poster", formData.poster);
    formDataToSend.append("background", formData.background);

    formDataToSend.append("job", formData.job);

    formDataToSend.append("instagram_link", formData.instagram_link);
    formDataToSend.append("facebook_link", formData.facebook_link);
    formDataToSend.append("popularity", formData.popularity);

    try {
      await post(`${API_URL}staff/create`, formDataToSend);
      setIsSent(true);
    } catch (err) {
      setIsSent(false);
    }
  };

  return (
    <>
      <Navbar
        exactPlace="زیادکردنی ستاف"
        secondPlace="ستاف"
        secondPlaceLink="/admin/staff"
      />

      <div className="flex__center flex-col w-full xl:flex-[0.8] mb-10 flex-1 mt-28 gap-8">
        <div className="flex__center md:flex-row flex-col md:gap-10 gap-5">
          <div className="flex__start flex-col gap-3">
            <div className="flex__start flex-col gap-1">
              <label htmlFor="title" className=" text-lg">
                ناو
              </label>
              <input
                type="text"
                id="title"
                className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-[330px] bg-transparent px-2"
                value={formData.name}
                onChange={handleChange}
                name="name"
              />
            </div>
            <div className="flex__start flex-col gap-1">
              <label htmlFor="img" className=" text-lg">
                وێنەی پرۆفایل
              </label>
              <input
                type="file"
                id="img"
                disabled={false}
                className="hidden"
                onChange={(e) => {
                  setFormData({ ...formData, poster: e.target.files[0] });
                  setImg(e.target.files[0]);
                }}
                name="poster"
              />
              <div className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-[330px] bg-transparent flex items-center justify-between">
                <div className="mx-3 font-normal text-right text-slate-300 whitespace-nowrap">
                  {img.name}
                </div>
                <label
                  htmlFor="img"
                  className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                >
                  <AiOutlineCloudUpload />
                </label>
              </div>
            </div>
            <div className="flex justify-between sm:w-[450px] w-[330px]">
              <div className="flex__start flex-col flex-[0.4]">
                <label
                  htmlFor="countries"
                  className="block mb-2 text-base font-medium "
                >
                  کاری لە وێبسایت
                </label>
                <select
                  id="countries"
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                  className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none w-full p-2.5 bg-backgroundSecondary "
                >
                  <option value="creator" defaultValue>
                    ئامادەکار
                  </option>
                  <option value="translator">وەرگێر</option>
                  <option value="designer">دیزاینەر</option>
                </select>
              </div>
              <div className="flex__start flex-col flex-[0.4]">
                <label
                  htmlFor="countries"
                  className="block mb-2 text-base font-medium "
                >
                  ڕۆڵ
                </label>
                <select
                  name="job"
                  id="countries"
                  className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none w-full p-2.5 bg-backgroundSecondary"
                  value={formData.job}
                  onChange={handleChange}
                >
                  <option value="manager">بەڕێوەبەر</option>
                  <option value="supervisor">سەرپەرشتیار</option>
                  <option value="staff" defaultValue>
                    ستاف
                  </option>
                </select>
              </div>
            </div>
          </div>
          <label
            htmlFor="img"
            className="w-[250px] h-[250px] bg-secondary rounded-full overflow-hidden"
          >
            <img
              src={img ? URL.createObjectURL(img) : images.noImg}
              alt=""
              className="w-full h-full object-cover"
            />
          </label>
        </div>
        <div className="flex__center gap-5 md:flex-row flex-col">
          <div className="flex__start gap-2 flex-col">
            <label htmlFor="quote">وتە</label>
            <input
              type="text"
              id="quote"
              className="outline-none border-slate-300 border rounded h-10 sm:w-[350px] w-[330px] bg-transparent px-2"
              value={formData.quote}
              onChange={handleChange}
              name="quote"
            />
          </div>
          <div className="flex__start flex-col gap-1">
            <label htmlFor="background">وێنەی کەڤەر</label>
            <input
              type="file"
              id="background"
              name="background"
              onChange={(e) => {
                setFormData({ ...formData, background: e.target.files[0] });
                setImgBg(e.target.files[0]);
              }}
              className="hidden"
            />
            <div className="outline-none border-slate-300 border rounded h-11 sm:w-[350px] w-[330px] bg-transparent flex items-center justify-between">
              <div className="mx-3 font-normal text-right text-slate-300 whitespace-nowrap">
                {imgBg.name}
              </div>
              <label
                htmlFor="background"
                className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
              >
                <AiOutlineCloudUpload />
              </label>
            </div>
          </div>
        </div>
        <div className="flex__center gap-5 md:flex-row flex-col">
          <div className="flex__start gap-2 flex-col">
            <label htmlFor="fb_link">لینکی فەسبووک</label>
            <input
              type="text"
              id="fb_link"
              value={formData.facebook_link}
              onChange={handleChange}
              className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-[330px] bg-transparent px-2"
              name="facebook_link"
            />
          </div>
          <div className="flex__start gap-2 flex-col">
            <label htmlFor="ig_link">لینکی ئینستاگرام</label>
            <input
              type="text"
              value={formData.instagram_link}
              onChange={handleChange}
              id="ig_link"
              name="instagram_link"
              className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-[330px] bg-transparent px-2"
            />
          </div>
          <div className="flex__start gap-2 flex-col">
            <label htmlFor="popularity">ڕیزبەندی</label>
            <input
              type="text"
              value={formData.popularity}
              onChange={handleChange}
              id="popularity"
              name="popularity"
              className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-[330px] bg-transparent px-2"
            />
          </div>
        </div>
        <div className="flex__start gap-2 flex-col">
          <label htmlFor="textarea">پێناسەکردنی</label>
          <textarea
            name="description"
            id="textarea"
            value={formData.description}
            onChange={handleChange}
            className="outline-none border-slate-300 border rounded h-32 md:w-[720px] sm:w-[450px] w-[330px] bg-transparent px-2"
          />
        </div>
        <button
          onClick={handleSubmit}
          type="submit"
          disabled={createLoading}
          className={`sm:w-[450px] w-[90%] relative h-[45px] bg-backgroundSecondary text-xl py-2 hover:bg-primary hover:text-black duration-300 rounded-md ${
            createLoading ? "disabled" : ""
          }`}
        >
          {!createLoading && <span>ناردن</span>}
          {createLoading && <ButtonLoading />}
        </button>
      </div>
      {isSent && (
        <CreatedWarning
          icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
          message={"بابەتەکە بەسەرکەوتوویی دروستکرا"}
        />
      )}
      {createError && createError.response && (
        <CreatedWarning
          icon={<MdReportGmailerrorred className="text-9xl text-red" />}
          message={"تکایە لە دڵنیاببەوە هەموو زانیاریەکانت بەڕاستی داناوە"}
          moreInfo={
            createError && createError.response
              ? createError.response.data.message
              : "An error occurred"
          }
        />
      )}
    </>
  );
};

export default AddStaff;
