import React, { useEffect, useState } from "react";
import { MdReportGmailerrorred } from "react-icons/md";
import { CreatedWarning, Navbar } from "../../admin-components";
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
import useFetch from "../../../hooks/useFetch";
import SendData from "../../../hooks/useSendData";
import { useParams } from "react-router-dom";
import { ButtonLoading, Loading } from "../../../components";
import { API_URL, IMG_URL } from "../../../env";

const UpdateReviewAndDive = () => {
  const { id } = useParams();
  const {
    data: reviewData,
    loading: reviewLoading,
    error: reviewError,
  } = useFetch(`${API_URL}dives/view/${id}`);
  const { createData, createError, createLoading, post } = SendData();

  const { data, loading, error, refetch } = useFetch(`${API_URL}staff/list`);
  const [getData, setGetData] = useState([]);
  useEffect(() => {
    if (!loading && data?.data?.length > 0) {
      setGetData(data.data);
    }
  }, [loading, getData, data]);

  const [img, setImg] = useState("");

  const [isSent, setIsSent] = useState(false);

  const defaultFormData = {
    title: "",
    description: "",
    background_image: "",
    table_type: "review",
    read_duration: "",
    dive_type: "",
    kurd_game_rating: "",
    popularity: "",
    staff_id: "",
  };

  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    if (!reviewLoading && reviewData.data) {
      setFormData({
        title: reviewData.data.title || "", // Default name value
        description: reviewData.data.description || "", // Default description value
        background_image: "", // Leave empty for the image_text field, or set it to a default value if needed
        table_type: reviewData.data.table_type || "", // Default table_type value
        read_duration: reviewData.data.read_duration || "", // Default read_duration value
        dive_type: reviewData.data.dive_type || "", // Default dive_type value
        kurd_game_rating: reviewData.data.kurd_game_rating || "", // Default kurd_game_rating value
        popularity: reviewData.data.popularity || "", // Default popularity value
        staff_id: reviewData.data?.staff?.id || "", // Default staff_id value
      });
    }
  }, [reviewData, reviewLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("table_type", formData.table_type);

    formDataToSend.append("background_image", formData.background_image);

    formDataToSend.append("read_duration", formData.read_duration);
    formDataToSend.append("dive_type", formData.dive_type);
    formDataToSend.append("kurd_game_rating", formData.kurd_game_rating);

    formDataToSend.append("popularity", formData.popularity);
    formDataToSend.append("staff_id", formData.staff_id);

    try {
      await post(`${API_URL}dives/update/${id}`, formDataToSend);
    } catch (error) {}
    setIsSent(true);
  };
  useEffect(() => {
    if (createError) {
    }
  }, [createError]);
  if (reviewLoading || !reviewData.data) {
    return <Loading />;
  } else {
    return (
      <>
        <Navbar
          exactPlace="نوێکردنەوەی پێداچوونەوە و قوڵبوونەوە"
          secondPlace="پێداچوونەوە و قوڵبوونەوە"
          secondPlaceLink="/admin/review"
        />

        <div className="flex__center flex-col w-full xl:flex-[0.8] mb-10 flex-1 mt-28 gap-8">
          <div className="flex__center md:flex-row flex-col md:gap-10 gap-5 w-full">
            <div className="flex__start flex-col gap-3 sm:w-auto w-full">
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="title" className=" text-lg">
                  ناونیشان
                </label>
                <input
                  type="text"
                  id="title"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formData.title}
                  onChange={handleChange}
                  name="title"
                />
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto  w-full sm:px-0 px-2">
                <label htmlFor="background_image" className=" text-lg">
                  وێنەی سەرەکی
                </label>
                <input
                  type="file"
                  id="background_image"
                  disabled={false}
                  className="hidden"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      background_image: e.target.files[0],
                    });
                    setImg(e.target.files[0]);
                  }}
                  name="background_image"
                />
                <div className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent flex items-center justify-between ">
                  <div className="mx-3 font-normal text-right text-slate-300 whitespace-nowrap w-full overflow-hidden text-ellipsis">
                    {img.name || reviewData.data.background_image}
                  </div>
                  <label
                    htmlFor="background_image"
                    className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                  >
                    <AiOutlineCloudUpload />
                  </label>
                </div>
              </div>
            </div>
            <div className="flex__start gap-3 flex-col sm:w-auto w-full">
              <label
                htmlFor="background_image"
                className="sm:w-[250px] sm:h-[150px] w-full h-auto bg-secondary max-h-[300px] rounded-[5px] overflow-hidden"
              >
                <img
                  src={
                    img
                      ? URL.createObjectURL(img)
                      : `${IMG_URL}dives/${reviewData.data.background_image}`
                  }
                  alt="img"
                  className="w-full h-full object-cover"
                />
              </label>
            </div>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="read_duration">ماوەی خوێندنەوە</label>
              <input
                type="text"
                id="read_duration"
                value={formData.read_duration}
                onChange={handleChange}
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
                name="read_duration"
              />
            </div>
            <div className="flex__start gap-2 flex-col w-full sm:px-0 px-2">
              <label htmlFor="dive_type">تاگ</label>
              <input
                type="text"
                value={formData.dive_type}
                onChange={handleChange}
                id="dive_type"
                name="dive_type"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
              />
            </div>
            <div className="flex__start gap-2 flex-col w-full sm:px-0 px-2">
              <label htmlFor="popularity">ڕیزبەندی</label>
              <input
                type="text"
                value={formData.popularity}
                onChange={handleChange}
                id="popularity"
                name="popularity"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
              />
            </div>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="table_type">جۆری بابەت</label>
              <select
                type="date"
                id="table_type"
                className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[230px] w-full p-2.5 bg-backgroundSecondary"
                value={formData.table_type}
                onChange={handleChange}
                name="table_type"
              >
                <option value="review" defaultValue>
                  پێداچوونەوە
                </option>
                <option value="dive">قوڵبوونەوە</option>
              </select>
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="staff_id">لەلایەن</label>
              <select
                name="staff_id"
                id="staff_id"
                onChange={handleChange}
                className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[230px] w-full p-2.5 bg-backgroundSecondary"
                value={formData.staff_id}
              >
                {getData.map((staff) => (
                  <option value={staff.id} defaultValue>
                    {staff.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex__start gap-2 flex-col w-full sm:px-0 px-2">
              <label htmlFor="kurd_game_rating">نمرەی کوردگەیم</label>
              <input
                type="text"
                value={formData.kurd_game_rating}
                onChange={handleChange}
                id="kurd_game_rating"
                name="kurd_game_rating"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
              />
            </div>
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="textarea">پێناسەکردنی</label>
            <textarea
              name="description"
              id="textarea"
              value={formData.description}
              onChange={handleChange}
              className="outline-none border-slate-300 border rounded h-32 md:w-[720px] sm:w-[450px] w-full bg-transparent px-2"
            />
          </div>
          <button
            onClick={handleSubmit}
            type="submit"
            disabled={createLoading}
            className={`sm:w-[450px] w-[90%] relative h-[45px] bg-backgroundSecondary text-xl py-2 hover:bg-primary hover:text-black duration-300 rounded-md ${
              createLoading ? "disabled" : ""
            }`}
          >
            {!createLoading && <span>ناردن</span>}
            {createLoading && <ButtonLoading />}
          </button>
        </div>
        {isSent && !createError && (
          <CreatedWarning
            icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
            message={"بابەتەکە بەسەرکەوتوویی نوێکرایەوە"}
          />
        )}
        {createError && createError.response && (
          <CreatedWarning
            icon={<MdReportGmailerrorred className="text-9xl text-red" />}
            message={"تکایە لە دڵنیاببەوە هەموو زانیاریەکانت بەڕاستی داناوە"}
            moreInfo={
              createError && createError.response
                ? createError.response.data.message
                : "An error occurred"
            }
          />
        )}
      </>
    );
  }
};

export default UpdateReviewAndDive;
