import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import images from "../../../assets/images/index";
import { ByWho } from "../../../components/index";
import { HeaderComponent } from "../../../components/index";

import NewsCard from "../../../pages/News/NewsComponent/NewsCard";
import useFetch from "../../../hooks/useFetch";
import { API_URL, IMG_URL } from "../../../env";

const NewsComponent = () => {
  const { data, loading, error } = useFetch(
    `${API_URL}news/all?perPage=8&page=1`
  );

  const [getData, setGetData] = useState([]);

  useEffect(() => {
    if (!loading && data && data.data && data.data.length > 0) {
      setGetData(data.data);
      setTab(data.data[0]?.id);
    }
  }, [data, loading]);
  const [tab, setTab] = useState(null);

  const chnageTab = (tab) => {
    setTab(tab);
  };
  const dateFormat = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <div className="flex__center flex-col w-full gap-5 sm:my-20 my-10">
        <HeaderComponent title="نوێترین هەواڵەکان" link="characters" />
        <div className="w-full max-w-[1250px] px-3 xl:px-0 flex__center md:flex-row flex-col">
          {getData.map((news, i) => (
            <>
              {tab === news.id && (
                <div
                  key={i}
                  className="sm:flex justify-center items-center md:gap-5 gap-2 bg-backgroundSecondary md:w-[50%] w-full rounded-tr-xl rounded-br-xl rounded-xl overflow-y-hidden hover:overflow-y-scroll md:flex-col flex-row md:h-[400px] h-auto md:p-0 p-4 !rounded-b-none hidden"
                >
                  <div className="md:w-full w-[240px] flex-shrink-0 md:h-[350px] h-[150px] rounded-lg overflow-hidden">
                    <img
                      src={`${IMG_URL}news/${news.image}`}
                      alt={news.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex__start flex-col w-full gap-3 pb-4 px-4">
                    <Link
                      to={`/news/${news.id}`}
                      title={news.title}
                      className="text-2xl link_hover"
                    >
                      {news.title}
                    </Link>
                    <p className="text-slate-300 font-light text-sm text-start sm:block hidden whitespace-normal">
                      {news.description.substring(0, 150)}...
                    </p>
                    <div className="w-full flex justify-between sm:items-center items-start gap-2 sm:flex-row flex-col-reverse">
                      <Link
                        to={`/news/${news.id}`}
                        title={news.title}
                        className="primary-button"
                      >
                        زیاتر بخوێنەوە
                      </Link>
                      <ByWho
                        title={news?.staff?.name}
                        link={`/staff/${news?.staff?.id}`}
                        imgPerson={news?.staff?.poster_path}
                        time={dateFormat(news.created_at)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}

          <div className="md:w-[50%] w-full p-3 bg-backgroundSecondary md:flex-col flex-row sm:flex justify-start items-start hidden rounded-tl-xl rounded-bl-xl overflow-y-hidden md:hover:overflow-y-scroll md:h-[400px] h-auto border-r border-r-backgroundTerinary !rounded-t-none overflow-x-scroll">
            {getData.map((news, i) => (
              <button
                key={i}
                onClick={() => chnageTab(news.id)}
                id="first-news"
                className={`flex__start lg:gap-5 gap-2 md:w-full w-[250px] flex-shrink-0 md:flex-row flex-col-reverse border-b border-b-backgroundTerinary lg:p-2 p-1 duration-300 newsHovering hover:${
                  tab !== news.id ? "bg-backgroundTerinary" : ""
                } ${tab === news.id ? "bg-primary" : ""} rounded-md`}
              >
                <div className="flex__start flex-col flex-1 gap-2">
                  <h2
                    className={`text-xl text-start link_hover ${
                      tab === news.id ? "text-black" : ""
                    }`}
                  >
                    {news.title}
                  </h2>
                  <p
                    className={` font-light text-sm text-start ${
                      tab === news.id ? "text-black" : "text-slate-300"
                    }`}
                  >
                    {news.description.substring(0, 50)}...
                  </p>
                  <div className="w-full flex justify-between items-center">
                    <ByWho
                      changeText={`${tab === news.id ? "textBlack" : ""}`}
                      title={news?.staff_name}
                      link={`/staff/${news?.staff_id}`}
                      imgPerson={
                        news.staff_image
                          ? `${IMG_URL}staff/poster/${news?.staff?.staff_image}`
                          : null
                      }
                      time={dateFormat(news.created_at)}
                    />
                  </div>
                </div>
                <div className="md:w-[150px] w-full md:h-[120px] h-[150px] overflow-hidden rounded-md newsHovered">
                  <img
                    src={`${IMG_URL}news/${news.image}`}
                    alt={news.title}
                    className="w-full h-full object-cover"
                  />
                </div>
              </button>
            ))}
          </div>
          <div className="sm:hidden flex justify-start items-start gap-3 w-full p-3 bg-backgroundSecondary flex-row rounded-md overflow-x-scroll">
            {getData.map((news) => (
              <NewsCard
                key={news.id}
                img={`${IMG_URL}news/${news.image}`}
                link={`/news/${news.id}`}
                linkPerson={`/staff/${news?.staff_id}`}
                person={news?.staff_name}
                text={news.description}
                time={dateFormat(news.created_at)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsComponent;
