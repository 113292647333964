import React, { useEffect, useState } from "react";
import { CreatedWarning, Navbar } from "../../admin-components";
import SendData from "../../../hooks/useSendData";
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
import images from "../../../assets/images";
import { MdReportGmailerrorred } from "react-icons/md";
import useFetch from "../../../hooks/useFetch";
import { ButtonLoading } from "../../../components";
import { API_URL } from "../../../env";

const AddCharacters = () => {
  const { createData, createError, createLoading, post } = SendData();

  const { data, loading, error, refetch } = useFetch(`${API_URL}staff/list`);
  const [getData, setGetData] = useState([]);
  useEffect(() => {
    if (!loading && data?.data?.length > 0) {
      setGetData(data.data);
    }
  }, [loading, getData, data.data]);

  const [img, setImg] = useState("");

  const [isSent, setIsSent] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: "",
    quote: "",
    gender: "male",
    k_name: "",
    voice_actor: "",
    popularity: "",
    job: "",
    nickname: "",
    age: "",
    staff_id: "",
    game_name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("name", formData.name);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("quote", formData.quote);
    formDataToSend.append("age", formData.age);

    formDataToSend.append("image", formData.image);

    formDataToSend.append("job", formData.job);

    formDataToSend.append("k_name", formData.k_name);
    formDataToSend.append("gender", formData.gender);
    formDataToSend.append("popularity", formData.popularity);
    formDataToSend.append("nickname", formData.nickname);
    formDataToSend.append("voice_actor", formData.voice_actor);
    formDataToSend.append("staff_id", formData.staff_id);
    formDataToSend.append("game_name", formData.game_name);

    try {
      await post(`${API_URL}characters/create`, formDataToSend);
      setIsSent(true);
    } catch (error) {
      setIsSent(false);
    }
  };
  useEffect(() => {
    if (createError) {
    }
  }, [createError]);
  return (
    <>
      <Navbar
        exactPlace="زیادکردنی کەسایەتی"
        secondPlace="کەسایەتی"
        secondPlaceLink="/admin/characters"
      />

      <div className="flex__center flex-col w-full xl:flex-[0.8] mb-10 flex-1 mt-28 gap-8">
        <div className="flex__center md:flex-row flex-col md:gap-10 gap-5 w-full">
          <div className="flex__start flex-col gap-3 sm:w-auto w-full">
            <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="title" className=" text-lg">
                ناو
              </label>
              <input
                type="text"
                id="title"
                className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                value={formData.name}
                onChange={handleChange}
                name="name"
              />
            </div>
            <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="img" className=" text-lg">
                وێنە
              </label>
              <input
                type="file"
                id="img"
                disabled={false}
                className="hidden"
                onChange={(e) => {
                  setFormData({ ...formData, image: e.target.files[0] });
                  setImg(e.target.files[0]);
                }}
                name="image"
              />
              <div className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent flex items-center justify-between">
                <div className="mx-3">{img.name}</div>
                <label
                  htmlFor="img"
                  className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                >
                  <AiOutlineCloudUpload />
                </label>
              </div>
            </div>
            <div className="flex justify-between sm:w-[450px] gap-3 w-full sm:px-0 px-2">
              <div className="flex__start flex-col sm:flex-[0.4] flex-[0.5]">
                <label
                  htmlFor="countries"
                  className="block mb-2 text-base font-medium "
                >
                  ڕەگەز
                </label>
                <select
                  id="countries"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none w-full p-2.5 bg-backgroundSecondary "
                >
                  <option value="male" defaultValue>
                    نێر
                  </option>
                  <option value="female">مێ</option>
                  <option value="not_human">مرۆڤ نیە</option>
                </select>
              </div>
              <div className="flex__start flex-col sm:flex-[0.4] flex-[0.5]">
                <label
                  htmlFor="countries"
                  className="block mb-2 text-base font-medium "
                >
                  پیشەی
                </label>
                <input
                  name="job"
                  id="countries"
                  className="outline-none border-slate-300 border rounded w-full h-10 bg-transparent px-2"
                  value={formData.job}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <label
            htmlFor="img"
            className="w-[220px] h-[300px] bg-secondary rounded-md overflow-hidden"
          >
            <img
              src={img ? URL.createObjectURL(img) : images.noImg}
              alt="img"
              className="w-full h-full object-cover"
            />
          </label>
        </div>
        <div className="flex__center gap-5 md:flex-row flex-col  w-full">
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="quote">وتە</label>
            <input
              type="text"
              id="quote"
              className="outline-none border-slate-300 border rounded h-10 sm:w-[350px] w-full bg-transparent px-2"
              value={formData.quote}
              onChange={handleChange}
              name="quote"
            />
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="nickname">نازناوی</label>
            <input
              type="text"
              id="nickname"
              className="outline-none border-slate-300 border rounded h-10 sm:w-[350px] w-full bg-transparent px-2"
              value={formData.nickname}
              onChange={handleChange}
              name="nickname"
            />
          </div>
        </div>
        <div className="flex__center gap-5 md:flex-row flex-col w-full">
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="age">تەمەنی</label>
            <input
              type="text"
              id="age"
              value={formData.age}
              onChange={handleChange}
              className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
              name="age"
            />
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="k_name">ناوی بە کوردی</label>
            <input
              type="text"
              value={formData.k_name}
              onChange={handleChange}
              id="k_name"
              name="k_name"
              className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
            />
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="popularity">ڕیزبەندی</label>
            <input
              type="text"
              value={formData.popularity}
              onChange={handleChange}
              id="popularity"
              name="popularity"
              className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
            />
          </div>
        </div>
        <div className="flex__center gap-5 md:flex-row flex-col w-full">
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="voice_actor">ئەکتەری دەنگی</label>
            <input
              type="text"
              id="voice_actor"
              className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
              value={formData.voice_actor}
              onChange={handleChange}
              name="voice_actor"
            />
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="game_name">ناوی یاری</label>
            <input
              type="text"
              id="game_name"
              className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
              value={formData.game_name}
              onChange={handleChange}
              name="game_name"
            />
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="staff_id">لەلایەن</label>

            <select
              name="staff_id"
              id="staff_id"
              onChange={handleChange}
              className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[230px] w-full p-2.5 bg-backgroundSecondary"
              value={formData.staff_id}
            >
              <option value="0">ستاف هەڵبژێرە</option>
              {getData.map((staff) => (
                <option value={staff.id} selected>
                  {staff.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
          <label htmlFor="textarea">پێناسەکردنی</label>
          <textarea
            name="description"
            id="textarea"
            value={formData.description}
            onChange={handleChange}
            className="outline-none border-slate-300 border rounded h-32 md:w-[720px] w-full bg-transparent px-2"
          />
        </div>
        <button
          disabled={createError}
          onClick={handleSubmit}
          type="submit"
          className={`sm:w-[450px] w-[90%] relative h-[45px] bg-backgroundSecondary text-xl py-2 hover:bg-primary hover:text-black duration-300 rounded-md ${
            createLoading ? "disabled" : ""
          }`}
        >
          {!createLoading && <span>ناردن</span>}
          {createLoading && <ButtonLoading />}
        </button>
      </div>
      {isSent && (
        <CreatedWarning
          icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
          message={"بابەتەکە بەسەرکەوتوویی دروستکرا"}
        />
      )}
      {createError && createError.response && (
        <CreatedWarning
          icon={<MdReportGmailerrorred className="text-9xl text-red" />}
          message={"تکایە لە دڵنیاببەوە هەموو زانیاریەکانت بەڕاستی داناوە"}
          moreInfo={
            createError && createError.response
              ? createError.response.data.message
              : "An error occurred"
          }
        />
      )}
    </>
  );
};

export default AddCharacters;
