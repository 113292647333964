import React from "react";

import images from "../../assets/images/index";
import { FaFacebookF, FaTiktok } from "react-icons/fa";
import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai";
import { BreadCrumb, Loading } from "../../components";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { API_URL, IMG_URL } from "../../env";

const YoutubersDetails = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(`${API_URL}youtubers/view/${id}`);

  const dateFormat = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  if (loading || !data.data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section">
          <div className="w-full max-w-[1250px] xl:px-0 px-3 flex__center flex-col gap-10 mb-10">
            <div className="w-full flex__start">
              <BreadCrumb
                exactPlace={data.data.channel}
                secondPlace="یوتوبەرەکان"
                secondPlaceLink="/youtubers"
              />
            </div>
            <div className="relative w-full">
              <img
                src={`${IMG_URL}youtubers/background/${data.data.background_path}`}
                alt="name"
                className="w-full h-[180px] object-cover rounded-md"
              />
              <img
                src={`${IMG_URL}youtubers/logo/${data.data.logo}`}
                alt={data.data.channel}
                className="w-[110px] h-[110px] rounded-full object-cover border-8 border-background absolute sm:right-16 right-[3%] sm:-bottom-16 -bottom-11"
              />
            </div>
            <div className="flex__start w-[90%] flex-col sm:mt-14 mt-5 gap-y-2">
              <h1 className="text-3xl">{data.data.name}</h1>
              <div className="flex__center gap-2">
                <span className="text-gray-300 text-sm">ناوی چەناڵ : </span>
                <a
                  href={data.data.youtube_link}
                  target="_blank"
                  title={data.data.channel}
                  rel="noopener noreferrer"
                  className="change_font link_hover text-lg"
                >
                  {data.data.channel}
                </a>
              </div>
              <div className="flex__center gap-2">
                <span className="text-gray-300 text-sm">دەست پێکردن : </span>
                <p className="change_font link_hover text-lg">
                  {dateFormat(data.data.started_at)}
                </p>
              </div>
              <div className="flex__center gap-2">
                <span className="text-gray-300 text-sm">
                  تۆرە کۆمەڵایەتیەکان :{" "}
                </span>
                <div className="flex__center gap-2">
                  {data.data.facebook_link && (
                    <a
                      target="_blank"
                      href={data.data.facebook_link}
                      rel="noopener noreferrer"
                      className="bg-white w-9 h-9 flex__center rounded"
                    >
                      <FaFacebookF fontSize={20} className="text-black" />
                    </a>
                  )}
                </div>
              </div>
              <div className="flex__start flex-col gap-1">
                <h3 className="text-lg">باسێکی کورت:</h3>
                <p className="paragraph">{data.data.description} </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default YoutubersDetails;
