import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  AddAdmins,
  AddCharacters,
  AddComingSoon,
  AddCompany,
  AddConsole,
  AddDetail,
  AddEvent,
  AddGameHistory,
  AddGameVideos,
  AddGames,
  AddGenre,
  AddNews,
  AddReviewAndDive,
  AddStaff,
  AddYoutuber,
  AdminLogin,
  Dashboard,
  UpdateCharacters,
  UpdateComingSoon,
  UpdateCompany,
  UpdateConsole,
  UpdateDetail,
  UpdateEvent,
  UpdateGameHistory,
  UpdateGames,
  UpdateGenre,
  UpdateNews,
  UpdateReviewAndDive,
  UpdateStaff,
  UpdateYoutuber,
  ViewAdmins,
  ViewCharacters,
  ViewComingSoon,
  ViewCompany,
  ViewConsole,
  ViewDetail,
  ViewEvent,
  ViewGameHistory,
  ViewGameVideos,
  ViewGames,
  ViewGenre,
  ViewNews,
  ViewReviewAndDive,
  ViewStaff,
  ViewUsers,
  ViewYoutuber,
} from "./admin-pages";
import { Sidebar } from "./admin-components";
import { AdminContextProvider } from "./AdminAuth/AdminAuth";
import Cookies from "js-cookie";
import useFetch from "../hooks/useFetch";
import { Loading } from "../components";
import { API_URL } from "../env";

const Admin = () => {
  const location = useLocation();
  const isLogin = location.pathname.includes("login");
  const token = Cookies.get("token");
  const { data, loading, error } = useFetch(`${API_URL}auth/admins/info`);
  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <AdminContextProvider>
          <div className="flex__start w-full">
            {!isLogin && token !== undefined && data?.data?.id && !error && (
              <Sidebar />
            )}
            <Routes>
              {token !== undefined && data?.data?.id && !error && (
                <Route path="dashboard" element={<Dashboard />} />
              )}
              {/* staff routes */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="staff" element={<ViewStaff />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="staff/new" element={<AddStaff />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="staff/:id" element={<UpdateStaff />} />
              )}

              {/* characters route */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="characters" element={<ViewCharacters />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="characters/:id" element={<UpdateCharacters />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="characters/new" element={<AddCharacters />} />
              )}

              {/* news routes */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="news" element={<ViewNews />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="news/new" element={<AddNews />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="news/:id" element={<UpdateNews />} />
              )}

              {/* review and dive routes */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="review" element={<ViewReviewAndDive />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="review/new" element={<AddReviewAndDive />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="review/:id" element={<UpdateReviewAndDive />} />
              )}

              {/* Genre routes */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="genre" element={<ViewGenre />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="genre/new" element={<AddGenre />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="genre/:id" element={<UpdateGenre />} />
              )}

              {/* Console routes */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="console" element={<ViewConsole />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="console/new" element={<AddConsole />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="console/:id" element={<UpdateConsole />} />
              )}

              {/* Company routes */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="company" element={<ViewCompany />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="company/new" element={<AddCompany />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="company/:id" element={<UpdateCompany />} />
              )}

              {/* Youtube routes */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="youtuber" element={<ViewYoutuber />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="youtuber/new" element={<AddYoutuber />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="youtuber/:id" element={<UpdateYoutuber />} />
              )}

              {/* Coming soon routes */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="coming-soon" element={<ViewComingSoon />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="coming-soon/new" element={<AddComingSoon />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="coming-soon/:id" element={<UpdateComingSoon />} />
              )}

              {/* Game history routes */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="games-history" element={<ViewGameHistory />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="games-history/new" element={<AddGameHistory />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route
                  path="games-history/:id"
                  element={<UpdateGameHistory />}
                />
              )}

              {/* game routes */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="games" element={<ViewGames />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="games/new" element={<AddGames />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="games/:id" element={<UpdateGames />} />
              )}
              {/* game video route */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="game-videos" element={<ViewGameVideos />} />
              )}

              {/* admin routes */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="admins" element={<ViewAdmins />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="admins/new" element={<AddAdmins />} />
              )}

              {/* details and top routes */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="detail" element={<ViewDetail />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="detail/new" element={<AddDetail />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="detail/:id" element={<UpdateDetail />} />
              )}
              {/* evennt routes */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="events" element={<ViewEvent />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="events/new" element={<AddEvent />} />
              )}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="events/:id" element={<UpdateEvent />} />
              )}

              {/* users routes */}
              {token !== undefined && data?.data?.id && !error && (
                <Route path="users" element={<ViewUsers />} />
              )}

              {/* login routes */}
              <Route path="login" element={<AdminLogin />} />
            </Routes>
          </div>
        </AdminContextProvider>
      </>
    );
  }
};

export default Admin;
