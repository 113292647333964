import React, { useEffect, useState } from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { BreadCrumb, Loading } from "../../components";
import StaffWorks from "./StaffComponent/StaffWorks";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { API_URL, IMG_URL } from "../../env";

const StaffDetails = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(`${API_URL}staff/view/${id}`);
  const [getData, setGetData] = useState([]);
  useEffect(() => {
    if (!loading) {
      setGetData(data.data);
    }
  }, [loading, getData, data]);
  if (loading || !data.data || !getData) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section">
          <div className="mb-10 flex__center flex-col w-full max-w-[1250px] xl:px-0 px-3 md:gap-10 gap-5">
            <div className="w-full flex__start">
              <BreadCrumb
                exactPlace={getData.name}
                secondPlace="ستاف"
                secondPlaceLink="/staff"
                key={id}
              />
            </div>
            <div className="w-full relative flex__center gap-10 flex-col mb-10">
              <div className="w-full md:h-[450px] sm:h-[300px] h-[250px] rounded-lg overflow-hidden">
                <img
                  src={`${IMG_URL}/staff/background/${getData.background}`}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
              <div className="flex__center w-full flex-col gap-3 absolute md:-bottom-[70px] -bottom-[60px]">
                <div className="w-[150px] h-[150px] rounded-full overflow-hidden p-2 bg-gradient-to-r from-[#ffffff20] to-[#ffffff70] duration-300 hover:from-[#ffffff80] hover:to-[#ffffff20]">
                  <img
                    src={`${IMG_URL}/staff/poster/${getData.poster}`}
                    alt={getData.name}
                    className="w-full h-full rounded-full object-cover"
                  />
                </div>
              </div>
            </div>
            <div className="flex__center flex-col gap-5 w-full">
              <h2 className="text-2xl change_font">{getData.name}</h2>
              <h3 className="text-xl md:w-[60%] w-full text-center">
                "{getData.quote}"
              </h3>
              <div className="flex__center gap-3">
                <a
                  href={getData.facebook_link}
                  title={getData.name}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="border-2  text-2xl p-2 rounded-full duration-300 ease-out hover:bg-primary hover:text-black"
                >
                  <FaFacebookF />
                </a>
                <a
                  href={getData.instagram_link}
                  title={getData.name}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="border-2  text-2xl p-2 rounded-full duration-300 ease-out hover:bg-primary hover:text-black"
                >
                  <FaInstagram />
                </a>
              </div>
              <div className="flex__center gap-3">
                {/* {getData.job.map((job) => (
                <> */}
                <span className="border border-backgroundTerinary bg-backgroundSecondary text-center rounded-xl w-32 py-2">
                  {getData.job === "manager"
                    ? "بەڕێوەبەر"
                    : getData.job === "supervisor"
                    ? "سەرپەرشتیکار"
                    : "ستاف"}
                </span>
                <span className="border border-backgroundTerinary bg-backgroundSecondary text-center rounded-xl w-32 py-2">
                  {getData.role === "translator"
                    ? "وەرگێڕ"
                    : getData.role === "designer"
                    ? "دیزاینەر"
                    : "ئامادەکار"}
                </span>
                {/* </>
              ))} */}
              </div>
              <p className="paragraph md:w-[80%] w-full text-center">
                {getData.description}
              </p>
            </div>
            {/* <StaffWorks /> */}
          </div>
        </section>
      </>
    );
  }
};

export default StaffDetails;
