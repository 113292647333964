import React, { useEffect, useRef, useState } from "react";
import { BreadCrumb, Loading } from "../../components";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { API_URL, IMG_URL } from "../../env";

const ComingSoonDetails = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(
    `${API_URL}coming-soons/view/${id}`
  );

  const calculateTimeLeft = () => {
    if (!data.data || !data.data.release_date) {
      return { days: "00", hours: "00", minutes: "00", seconds: "00" };
    }

    const releaseDate = new Date(data.data.release_date).getTime();
    const now = new Date().getTime();
    const distance = releaseDate - now;

    if (distance <= 0) {
      return { days: "00", hours: "00", minutes: "00", seconds: "00" };
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  let interval = useRef();

  useEffect(() => {
    interval.current = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearInterval(interval.current);
    };
  }, [data.data]);

  const { days, hours, minutes, seconds } = timeLeft;

  if (loading || !data.data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section">
          {loading && <h1>چاوەڕێکە ....</h1>}
          {!loading && (
            <div className="w-full max-w-[1250px] xl:px-0 px-3 flex__center flex-col gap-10 mb-10">
              <div className="flex__start w-full">
                <BreadCrumb
                  exactPlace={data.data.title}
                  secondPlace="بەمزوانە"
                  secondPlaceLink="/comingsoon"
                  key={data.data.id}
                />
              </div>
              <div className="relative w-full">
                <img
                  src={`${IMG_URL}comingSoons/${data.data.background_image}`}
                  alt={data.data.title}
                  className="rounded-lg w-full lg:h-96 md:h-auto h-56"
                />
                <div className="flex md:items-center md:justify-center items-start justify-start flex-col gap-5 absolute top-[50%] md:right-14 right-5 -translate-y-[50%]">
                  <h3 className="md:text-4xl text-xl">کاتی ماوە</h3>
                  <h1 className="change_font md:text-6xl text-2xl">
                    {days}.{hours}.{minutes}.{seconds}
                  </h1>
                  <div className="flex md:items-center md:justify-center items-start justify-start gap-2 sm:flex-row flex-col">
                    <span className="text-gray-300">بەردەست دەبێت بۆ:</span>
                    <ul className="flex__center gap-1 change_font">
                      <li>{data.data.console_names}</li>
                    </ul>
                  </div>
                  <a
                    href={data.data.trailer_link}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="primary-button text-lg"
                  >
                    بینینی ترایلەر
                  </a>
                </div>
              </div>
              <div className="w-full flex__start flex-col gap-2">
                <h3 className="text-lg">زانیاری زیاتر:</h3>
                <p className="paragraph">{data.data.description}</p>
              </div>
            </div>
          )}
        </section>
      </>
    );
  }
};

export default ComingSoonDetails;
