import React, { useEffect, useState } from "react";
import images from "../../assets/images";
import TopCard from "./TopComponents/TopCard";
import useFetch from "../../hooks/useFetch";
import { Loading } from "../../components";
import { BsArrowLeftShort } from "react-icons/bs";
import { API_URL } from "../../env";
import { IMG_URL } from "../../env";

const Top = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const indexOfLastItem = currentPage * itemsPerPage;
  const { data, loading, error, refetchPagination } = useFetch(
    `${API_URL}details/all?type=TOP&perPage=${itemsPerPage}&page=${currentPage}`
  );
  const [getData, setGetData] = useState([]);
  const totalPageCount = Math.ceil(data?.pagination?.totalCount / itemsPerPage);

  const fetchData = async (page) => {
    try {
      const result = await refetchPagination(
        `${API_URL}details/all?type=TOP&perPage=${itemsPerPage}&page=${page}`
      );
      if (result) {
        setGetData(result.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let index = currentPage - 5; index < currentPage + 5; index++) {
      if (index > 0 && index <= totalPageCount) {
        pageNumbers.push(index);
      }
    }
    return pageNumbers;
  };

  if (loading || !data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section">
          <div className="w-full max-w-[1250px] xl:px-0 px-3 flex__center flex-col gap-10 mb-10">
            <h1 className="sm:text-4xl text-2xl">بەرزترینەکان</h1>
            <div className="w-full flex__center flex-wrap gap-x-5 gap-y-10">
              {getData.map((data) => (
                <TopCard
                  img={`${IMG_URL}/details/${data.image}`}
                  link={`/top/${data.id}`}
                  linkPerson={`/staff/${data.staff_id}`}
                  person={data.staff_name || ""}
                  title={data.title}
                  key={data.id}
                />
              ))}
            </div>
            <nav
              className="flex items-center justify-center w-full pt-4"
              aria-label="Table navigation"
            >
              <ul className="inline-flex w-full items-center justify-center -space-x-px text-sm h-8">
                <li>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`flex items-center justify-center px-3 h-8 leading-tight bg-background border border-backgroundTerinary rounded-r-lg hover:bg-gray-900 ${
                      currentPage === 1 ? "text-gray-500" : "text-white"
                    }`}
                  >
                    <BsArrowLeftShort className="text-xl rotate-180" />
                    <span>پێشتر</span>
                  </button>
                </li>
                <div className="flex overflow-x-auto pagination">
                  {generatePageNumbers().map((pageNumber) => (
                    <li key={pageNumber}>
                      <button
                        onClick={() => handlePageChange(pageNumber)}
                        className={`flex items-center justify-center px-3 h-8 leading-tight change_font bg-background border border-backgroundTerinary ${
                          currentPage === pageNumber
                            ? "text-black bg-primary"
                            : "text-slate-300"
                        }`}
                      >
                        {pageNumber}
                      </button>
                    </li>
                  ))}
                </div>
                <li>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={indexOfLastItem >= data?.pagination?.totalCount}
                    className={`flex items-center justify-center gap-1 px-3 h-8 leading-tight bg-background border border-backgroundTerinary rounded-l-lg hover:bg-gray-900 ${
                      indexOfLastItem >= data?.pagination?.totalCount
                        ? "text-gray-500 "
                        : "text-white"
                    }`}
                  >
                    <span>دواتر</span>
                    <BsArrowLeftShort className="text-xl" />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </section>
      </>
    );
  }
};

export default Top;
