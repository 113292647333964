import React from "react";
import { BreadCrumb, ByWho, Loading } from "../../components";
import images from "../../assets/images";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import ConsoleCard from "./ConsoleComponents/ConsoleCard";
import SimilarConsole from "./ConsoleComponents/SimilarConsole";
import { API_URL, IMG_URL } from "../../env";

const ConsoleDetails = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(`${API_URL}consoles/view/${id}`);

  const dateFormat = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  if (loading || !data.data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section">
          <div className="w-full max-w-[1250px] xl:px-0 px-3 flex__center flex-col gap-10 mb-10">
            <div className="flex__start w-full">
              <BreadCrumb
                exactPlace={data.data.name}
                secondPlace="کونسوڵ"
                secondPlaceLink="/console"
                key={data.data.id}
              />
            </div>
            <div className="w-full flex sm:items-start sm:justify-start justify-center items-center gap-10 sm:flex-row flex-col border-b border-b-backgroundTerinary pb-5">
              <div className="w-[250px] h-[250px] rounded-lg overflow-hidden">
                <img
                  src={`${IMG_URL}consoles/${data.data.image}`}
                  alt={data.data.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex__start sm:w-auto w-full flex-col gap-4">
                <h2 className="change_font font-semibold text-4xl tracking-wider">
                  {data.data.name}
                </h2>
                <div className="flex__center gap-2">
                  <h3 className="text-gray-300">بڵاوکەرەوە : </h3>
                  <h3 className="">{data.data.tag}</h3>
                </div>
                <div className="flex__center gap-2">
                  <h3 className="text-gray-300">بڵاوبوونەوە : </h3>
                  <h3 className="">
                    <span className="change_font">
                      {dateFormat(data.data.release_date)}
                    </span>
                  </h3>
                </div>
                <div className="flex__center gap-2">
                  <h3 className="text-gray-300">زنجیرە : </h3>
                  <h3 className="change_font">
                    {data.data.tag === "ps"
                      ? "Play Station"
                      : data.data.tag === "xbox"
                      ? "Xbox"
                      : data.data.tag === "nintendo"
                      ? "Nintendo"
                      : "Others"}
                  </h3>
                </div>
                <ByWho
                  imgPerson={
                    data.data.staff.image
                      ? `${IMG_URL}staff/poster/${data?.data?.staff?.image}`
                      : null
                  }
                  link={`/staff/${data?.data?.staff?.id}`}
                  time={dateFormat(data.data.created_at)}
                  title={data?.data?.staff?.name}
                  key={data?.data?.staff?.id}
                />
              </div>
            </div>
            <div className="w-full flex__start flex-col gap-2">
              <h3 className="text-lg">زانیاری زیاتر</h3>
              <p className="paragraph">{data.data.description}</p>
            </div>
            <SimilarConsole id={id} key={id} />
          </div>
        </section>
      </>
    );
  }
};

export default ConsoleDetails;
