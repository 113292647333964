import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SendData from "../../../hooks/useSendData";
import useFetch from "../../../hooks/useFetch";
import { CreatedWarning, Navbar } from "../../admin-components";
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
import images from "../../../assets/images";
import { MdReportGmailerrorred } from "react-icons/md";
import { ButtonLoading, Loading } from "../../../components";
import { API_URL, IMG_URL } from "../../../env";

const UpdateComingSoon = () => {
  const { id } = useParams();
  const {
    data: comingSoonData,
    loading: comingSoonLoading,
    error: comingSoonError,
  } = useFetch(`${API_URL}coming-soons/view/${id}`);

  const { createData, createError, createLoading, post } = SendData();

  const { data, loading, error, refetch } = useFetch(`${API_URL}staff/list`);
  const [getData, setGetData] = useState([]);
  useEffect(() => {
    if (!loading && data?.data?.length > 0) {
      setGetData(data.data);
    }
  }, [loading, getData, data]);

  const [img, setImg] = useState("");

  const [isSent, setIsSent] = useState(false);

  const defaultFormData = {
    title: "",
    description: "",
    background_image: "",
    console_names: "",
    trailer_link: "",
    release_date: "",
    staff_id: "",
  };

  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    if (!comingSoonLoading && comingSoonData.data) {
      // Update formData with data when available
      setFormData({
        title: comingSoonData.data.title || "", // Default title value
        description: comingSoonData.data.description || "", // Default description value
        background_image: "", // Leave empty for the image field, or set it to a default value if needed
        console_names: comingSoonData.data.console_names || "", // Default console_names value
        release_date: comingSoonData.data.release_date || "", // Default release_date value
        trailer_link: comingSoonData.data.trailer_link || "", // Default trailer_link value
        staff_id: comingSoonData.data?.staff?.id || "", // Default staff value
      });
    }
  }, [comingSoonData, comingSoonLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("release_date", formData.release_date);

    formDataToSend.append("background_image", formData.background_image);
    formDataToSend.append("console_names", formData.console_names);

    formDataToSend.append("trailer_link", formData.trailer_link);
    formDataToSend.append("staff_id", formData.staff_id);

    try {
      await post(`${API_URL}coming-soons/update/${id}`, formDataToSend);
    } catch (error) {}
    setIsSent(true);
  };
  useEffect(() => {
    if (createError) {
    }
  }, [createError]);
  if (comingSoonLoading || !comingSoonData.data) {
    return <Loading />;
  } else {
    return (
      <>
        <Navbar
          exactPlace="نوێکردنەوەی بەمزوانە"
          secondPlace="بەمزوانە"
          secondPlaceLink="/admin/coming-soon"
          key={comingSoonData.data.id}
        />

        <div className="flex__center flex-col w-full xl:flex-[0.8] mb-10 flex-1 mt-28 gap-8">
          <div className="flex__center md:flex-row flex-col md:gap-10 gap-5 w-full">
            <div className="flex__start flex-col gap-3 sm:w-auto w-full">
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="name" className=" text-lg">
                  ناو
                </label>
                <input
                  type="text"
                  id="name"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formData.title}
                  onChange={handleChange}
                  name="title"
                />
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="console_names" className=" text-lg">
                  کونسوڵەکان
                </label>
                <input
                  type="text"
                  id="console_names"
                  className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent px-2"
                  value={formData.console_names}
                  onChange={handleChange}
                  name="console_names"
                />
              </div>
              <div className="flex__start flex-col gap-1 sm:w-auto w-full sm:px-0 px-2">
                <label htmlFor="img" className=" text-lg">
                  وێنە
                </label>
                <input
                  type="file"
                  id="img"
                  disabled={false}
                  className="hidden"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      background_image: e.target.files[0],
                    });
                    setImg(e.target.files[0]);
                  }}
                  name="background_image"
                />
                <div className="outline-none border-slate-300 border rounded h-11 sm:w-[450px] w-full bg-transparent flex items-center justify-between">
                  <div className="mx-3 w-full font-normal text-right text-slate-300 whitespace-nowrap">
                    {img.name || comingSoonData.data.background_image}
                  </div>
                  <label
                    htmlFor="img"
                    className="text-2xl font-bold bg-primary text-black h-full flex__center w-[50px] cursor-pointer"
                  >
                    <AiOutlineCloudUpload />
                  </label>
                </div>
              </div>
            </div>
            <label
              htmlFor="img"
              className="sm:w-[350px] sm:h-[150px] w-full h-auto bg-backgroundSecondary rounded-xl overflow-hidden"
            >
              <img
                src={
                  img
                    ? URL.createObjectURL(img)
                    : `${IMG_URL}comingSoons/${comingSoonData.data.background_image}`
                }
                alt="img"
                className="w-full h-full object-cover"
              />
            </label>
          </div>
          <div className="flex__center gap-5 md:flex-row flex-col sm:w-auto w-full">
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="trailer_link">لینکی ترایلەر</label>
              <input
                id="trailer_link"
                name="trailer_link"
                value={formData.trailer_link}
                onChange={handleChange}
                className="outline-none border-slate-300 border rounded h-11 sm:w-[230px] w-full bg-transparent flex items-center justify-between px-2"
              />
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="release_date">کاتی بڵاوبوونەوەی</label>
              <input
                type="date"
                id="release_date"
                className="outline-none border-slate-300 border rounded h-10 sm:w-[230px] w-full bg-transparent px-2"
                value={formData.release_date}
                onChange={handleChange}
                name="release_date"
              />
            </div>
            <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
              <label htmlFor="staff_id">لەلایەن</label>
              <select
                name="staff_id"
                id="staff_id"
                onChange={handleChange}
                className="border cursor-pointer border-slate-300 text-base rounded-lg focus:ring-primary focus:border-primary block outline-none sm:w-[230px] w-full p-2.5 bg-backgroundSecondary"
                value={formData.staff_id}
              >
                {getData.map((staff) => (
                  <option value={staff.id}>{staff.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex__start gap-2 flex-col sm:w-auto w-full sm:px-0 px-2">
            <label htmlFor="textarea">پێناسەکردنی</label>
            <textarea
              name="description"
              id="textarea"
              value={formData.description}
              onChange={handleChange}
              className="outline-none border-slate-300 border rounded h-32 md:w-[720px] sm:w-[450px] w-full bg-transparent px-2"
            />
          </div>
          <button
            onClick={handleSubmit}
            type="submit"
            disabled={createLoading}
            className={`sm:w-[450px] w-[90%] relative h-[45px] bg-backgroundSecondary text-xl py-2 hover:bg-primary hover:text-black duration-300 rounded-md ${
              createLoading ? "disabled" : ""
            }`}
          >
            {!createLoading && <span>ناردن</span>}
            {createLoading && <ButtonLoading />}
          </button>
        </div>
        {isSent === true && !createError && (
          <CreatedWarning
            icon={<AiOutlineCheckCircle className="text-9xl text-green" />}
            message={"بابەتەکە بەسەرکەوتوویی نوێکرایەوە"}
          />
        )}
        {createError && createError.response && (
          <CreatedWarning
            icon={<MdReportGmailerrorred className="text-9xl text-red" />}
            message={"تکایە لە دڵنیاببەوە هەموو زانیاریەکانت بەڕاستی داناوە"}
            moreInfo={
              createError && createError.response
                ? createError.response.data.message
                : "An error occurred"
            }
          />
        )}
      </>
    );
  }
};

export default UpdateComingSoon;
