import React, { useContext, useState } from "react";
import axios from "axios";
import { AdminContext } from "../../AdminAuth/AdminAuth";
import images from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ButtonLoading } from "../../../components";
import { CreatedWarning } from "../../admin-components";
import { MdReportGmailerrorred } from "react-icons/md";
import { API_URL } from "../../../env";

const AdminLogin = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const { dispatch } = useContext(AdminContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loginLoading, setLoginLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginLoading(true);

    try {
      // Send a login request to your server with the formData
      const response = await axios.post(
        `${API_URL}auth/admins/login`,
        formData
      );
      const adminData = response.data; // Assuming the response contains admin data
      const token = adminData.adminToken;
      Cookies.set("token", token, { expires: 365 });
      setLoginLoading(false);
      dispatch({ type: "LOGIN_SUCCESS", payload: adminData });
      navigate("/admin/dashboard");
    } catch (error) {
      // Dispatch a "LOGIN_FAILURE" action with the error message
      // console.log(error.response.data.message);
      setErrorMessage(error.response.data.message);
      dispatch({ type: "LOGIN_FAILURE", payload: "Login failed" });
      setLoginLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <section className="w-full">
        <div className="w-full flex__center gap-10 h-[100vh]">
          <img
            src={images.auth}
            alt="auth"
            className="absolute top-0 left-0 w-full h-full object-cover "
          />
          <div className="flex sm:w-auto w-[95%] md:items-start sm:justify-start justify-center items-center flex-col gap-4 relative backdrop-blur-md bg-black bg-opacity-30 p-5 rounded-lg">
            <h1 className="text-3xl">چوونە ژوورەوە</h1>
            <span className="text-slate-400 text-sm">
              تکایە هەموو وردەکاریەکان دابنێ
            </span>
            <form
              onSubmit={handleLogin}
              className="flex__start flex-col w-full gap-5"
            >
              <div className="flex__start w-full relative flex-col gap-y-1">
                <label htmlFor="email">ئیمەیڵ:</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  placeholder="ئیمەیڵ"
                  onChange={handleChange}
                  className="border-b outline-none bg-transparent sm:w-[350px] w-full"
                />
              </div>
              <div className="flex__start w-full relative flex-col gap-y-1">
                <label htmlFor="password">تێپەرە وشە:</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={formData.password}
                  placeholder="تێپەرە وشە"
                  onChange={handleChange}
                  className="border-b outline-none bg-transparent sm:w-[350px] w-full"
                />
              </div>
              <button
                type="submit"
                className="sm:w-[350px] w-full relative h-[45px] duration-300 transition-colors hover:bg-backgroundTerinary bg-backgroundSecondary text-base rounded-md px-2 py-2"
              >
                {!loginLoading && <span>چوونە ژوورەوە</span>}
                {loginLoading && <ButtonLoading />}
              </button>
            </form>
          </div>
        </div>
        {errorMessage && (
          <CreatedWarning
            icon={<MdReportGmailerrorred className="text-9xl text-red" />}
            message={"هەڵە ڕوویدا"}
            moreInfo={errorMessage}
          />
        )}
      </section>
    </>
  );
};

export default AdminLogin;
