import React, { useContext, useEffect, useState } from "react";
import { BreadCrumb, GamePoster, Loading } from "../../components";
import images from "../../assets/images";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { fetchUserFromAPI } from "../../context/authContext";
import useFetch from "../../hooks/useFetch";
import { AiFillEye, AiFillStar } from "react-icons/ai";
import { MdFavorite } from "react-icons/md";
import useDelete from "../../hooks/useDelete";
import { API_URL, IMG_URL } from "../../env";
import { BsDot } from "react-icons/bs";

const Profile = () => {
  const { deleteRequest, deleteLoading } = useDelete();
  const { data, loading, error, refetch } = useFetch(
    `${API_URL}auth/user/favorites`
  );

  const [getData, setGetData] = useState([]);
  useEffect(() => {
    if (!loading && data?.data && data && data?.data?.length > 0) {
      setGetData(data.data);
    }
  }, [loading, getData, data]);

  const handleRemoveFavorite = async (id) => {
    try {
      await deleteRequest(`${API_URL}auth/user/favorite/delete/${id}`);
      refetch();
    } catch (err) {}
  };

  const authContext = useContext(AuthContext);
  const { dispatch, user } = authContext;
  const [profileLoading, setProfileLoading] = useState(null);

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    setProfileLoading(true);
    // Call the fetchUserFromAPI function when the component mounts
    fetchUserFromAPI(dispatch)
      .then((userData) => {
        // Set the user data to the state variable
        setUserData(userData);
        setProfileLoading(false);
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error fetching user data:", error);
      });
  }, [dispatch]);
  const dateFormat = (date) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  // let color;
  const [color, setColor] = useState("زەرد");
  useEffect(() => {
    if (localStorage.getItem("primaryColor") == "#fff200") {
      setColor("زەرد");
    } else if (localStorage.getItem("primaryColor") == "#66c0f4") {
      setColor("شین");
    } else if (localStorage.getItem("primaryColor") == "#ff2e2e") {
      setColor("سوور");
    } else if (localStorage.getItem("primaryColor") == "#20e87d") {
      setColor("سەوز");
    }
  }, []);

  if (!userData || profileLoading || loading) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="w-full flex__center mb-20 flex-col gap-10">
          <div className="w-full absolute top-0 left-0">
            <img
              src={
                userData?.data?.background
                  ? `${IMG_URL}users/background/${userData?.data?.background}`
                  : images.cover
              }
              alt={userData?.data?.name}
              className="w-full lg:h-[500px] h-[350px] object-cover opacity-25"
            />
          </div>
          <div className="lg:mt-20 mt-14 flex__center flex-col gap-10 w-full max-w-[1250px] xl:px-0 px-3 z-10">
            <div className="flex__start w-full mt-5">
              <BreadCrumb
                exactPlace={userData?.data?.name}
                secondPlaceLink="/"
                secondPlace="بەکارهێنەر"
              />
            </div>
            <div className="flex justify-between items-center sm:flex-row flex-col w-full gap-5 lg:mt-60 mt-24 bg-[#00000050] backdrop-blur-sm px-3 py-1 rounded-lg">
              <div className="flex items-center sm:justify-center justify-between sm:w-auto w-full gap-3">
                <div className="md:w-[150px] md:h-[150px] w-[90px] h-[90px] flex__center rounded-full overflow-hidden bg-background">
                  <img
                    src={
                      userData?.data?.profile_pic
                        ? `${IMG_URL}users/profile_pic/${userData?.data?.profile_pic}`
                        : images.ghostProfile
                    }
                    className="w-full h-full object-cover"
                    alt="profile pic"
                  />
                </div>
                <div className="flex__start flex-col gap-3">
                  <h3 className="md:text-xl text-base change_font">
                    {userData?.data?.name}
                  </h3>
                  <Link to="/settings" className="primary-button">
                    ڕێکخستنی هەژمارەکەم
                  </Link>
                </div>
              </div>
              <div className="flex items-center sm:justify-center justify-between md:gap-3 gap-1 sm:w-auto w-full">
                <div className="flex__center flex-col">
                  <h3 className="text-slate-300 md:text-lg text-base">
                    دڵخوازەکانم
                  </h3>
                  <h3 className="text-white">
                    {getData?.length > 0 ? getData?.length + 1 : 0}
                  </h3>
                </div>
                <span className="w-[1px] h-11 bg-backgroundTerinary"></span>
                <div className="flex__center flex-col">
                  <h3 className="text-slate-300 md:text-lg text-base">
                    ڕەنگی دڵخواز
                  </h3>
                  <h3 className="text-white">{color}</h3>
                </div>
                <span className="w-[1px] h-11 bg-backgroundTerinary"></span>
                <div className="flex__center flex-col">
                  <h3 className="text-slate-300 md:text-lg text-base">
                    کاتی خۆتۆمارکردن
                  </h3>
                  <h3 className="text-white">
                    {dateFormat(userData.data.created_at)}
                  </h3>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-start items-start flex-col gap-10">
              <h1 className="sm:text-4xl text-2xl text-center w-full">
                دڵخوازەکانم
              </h1>
              <div className="flex-wrap w-full flex items-start justify-center sm:gap-x-5 gap-x-2 gap-y-5">
                {getData.map((game, i) => (
                  <div
                    key={i}
                    className="flex__center sm:w-[220px] w-[48%] h-auto flex-col gap-0 rounded-md poster"
                  >
                    <div className="w-full h-auto rounded-lg overflow-hidden relative">
                      <img
                        title={game?.title}
                        src={`${IMG_URL}games/poster_image/${game?.poster_image}`}
                        className="w-full h-[300px] poster-img"
                        alt={game?.title}
                      />
                      <div className="absolute flex-col duration-300 poster-content opacity-0 p-3 top-0 left-0 w-full h-full backdrop-blur flex justify-between items-start">
                        <div className="w-full flex justify-between items-start gap-2">
                          <div className="flex__center gap-1">
                            {game?.ign_rating}{" "}
                            <AiFillStar className="text-xl text-primary" />
                          </div>
                          <button
                            type="button"
                            onClick={() => handleRemoveFavorite(game?.id)}
                            className="text-primary text-2xl"
                          >
                            <MdFavorite />
                          </button>
                        </div>
                        <Link
                          to={`/game-details/${game?.id}`}
                          title={game?.title}
                          className="w-full text-center primary-button"
                        >
                          بینینی زیاتر
                        </Link>
                        <div className="flex__center gap-1">
                          {game?.view_count} <AiFillEye className="text-xl" />
                        </div>
                      </div>
                    </div>
                    <Link
                      to={`/game-details/${game?.id}`}
                      className="sm:text-xl text-base font-medium w-full text-ellipsis overflow-hidden change_direction whitespace-nowrap text-center change_font link_hover mt-2"
                    >
                      {game?.title}
                    </Link>
                    <div className="flex__center gap-1 w-full overflow-hidden">
                      {game.genres.map((g, i) => (
                        <>
                          <span className="max-w-[12ch] text-ellipsis overflow-hidden whitespace-nowrap sm:text-base text-sm">
                            {g.title}
                          </span>
                          {i < game.genres.length - 1 && (
                            <BsDot className="text-[#a4a4a4] text-xl" />
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                ))}
                {getData.length === 0 && (
                  <div className="w-full flex justify-center items-center flex-col gap-3">
                    <img
                      src={images.mario}
                      className="max-w-[300px]"
                      alt="mario"
                    />
                    <h1 className="sm:text-4xl text-2xl">هیچ دڵخوازێکت نیە</h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default Profile;
