import React from "react";
import { Link } from "react-router-dom";

const CompanyCard = ({ title, img, year, link, type }) => {
  return (
    <>
      <div className="sm:w-[400px] w-full duration-300 hover:bg-background hover:shadow-md shadow-black flex__start gap-5 bg-backgroundSecondary rounded-xl p-3">
        <Link
          to={link}
          className="w-[90px] shrink-0 h-[90px] rounded-md overflow-hidden"
        >
          <img src={img} alt="title" className="w-full h-full" />
        </Link>
        <div className="flex__start flex-col flex-1 gap-1">
          <div className="w-full flex justify-between items-start">
            <Link to={link} className="change_font link_hover">
              {title}
            </Link>
            <span className="text-sm bg-backgroundTerinary p-1 rounded-sm">
              {year}
            </span>
          </div>
          <span className="text-sm text-slate-400">{type}</span>
          <Link
            to={link}
            className="bg-gradient-to-r from-[#ffffff20] to-[#ffffff70] text-white px-2 py-1 rounded-md duration-300 hover:from-[#ffffff80] hover:to-[#ffffff20]"
          >
            زیاتر بخوێنەوە
          </Link>
        </div>
      </div>
    </>
  );
};

export default CompanyCard;
