import React, { useState } from "react";

import UpdateDetailPoint from "./UpdateDetailPoint";
import useDelete from "../../../hooks/useDelete";
import { useParams } from "react-router-dom";
import { API_URL } from "../../../env";

const ViewDetailPoints = ({ data }) => {
  const { id } = useParams();
  const [pointUpdate, setPointUpdate] = useState(null);
  const [pointId, setPointId] = useState("");
  const handleClick = (point) => {
    // console.log(point.id);
    setPointId(point.id);
    setPointUpdate(point);
  };
  const { deleteRequest, deleteLoading, deleteError } = useDelete();
  const [isSent, setIsSent] = useState(false);
  const handleDelete = async (idDelete) => {
    setShowModal(!showModal);
    try {
      await deleteRequest(`${API_URL}detail-points/delete/${idDelete}`);
      setIsSent(true);
      window.location.reload();
    } catch (err) {
      setIsSent(false);
    }
  };
  // remove and show modal
  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState(false);

  const handleModal = (id) => {
    setShowModal(!showModal);
    setModalId(id);
  };

  return (
    <>
      <div className="fixed z-40 max-w-[800px] w-full left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]">
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-slate-300">
            <thead className="text-xs bg-backgroundSecondary">
              <tr>
                <th scope="col" className="px-6 py-3 rounded-s-lg text-start">
                  ناونیشان
                </th>
                <th scope="col" className="px-6 py-3">
                  ڕیزبەندی
                </th>
                <th scope="col" className="px-6 py-3 rounded-e-lg text-center">
                  کردەکان
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((point, i) => (
                <>
                  <tr
                    key={i}
                    className="bg-backgroundTerinary border-b border-backgroundSecondary  hover:bg-gray-700"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap text-start"
                    >
                      {point.title}
                    </th>
                    <td className="px-6 py-4">{point.number}</td>
                    <td className="px-6 py-4 flex justify-center items-center gap-2 text-right">
                      <button
                        onClick={() => handleClick(point)}
                        className="font-medium  hover:underline"
                      >
                        گۆڕین
                      </button>
                      <button
                        onClick={() => handleModal(point.id)}
                        className="font-medium text-red hover:underline"
                      >
                        ڕەشکردنەوە
                      </button>
                    </td>
                  </tr>
                  {point.id === modalId && showModal === true && (
                    <>
                      <div className="max-w-[500px] w-[90%] h-auto flex justify-center items-center flex-col gap-5 p-5 rounded-xl bg-backgroundSecondary fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-20">
                        <h1 className="sm:text-2xl text-lg">
                          دڵنیای لە ڕەشکردنەوەی ئەم بابەتە؟
                        </h1>
                        <span className="text-slate-300">
                          دوای ڕەشکردنەوەی توانای ئەوەت نابێت بیگەڕێنیتەوە
                        </span>
                        <div className="flex__center gap-5">
                          <button
                            className="primary-button !text-white !bg-backgroundTerinary"
                            onClick={() => handleModal(point.id)}
                          >
                            ڕەتکردنەوە
                          </button>
                          <button
                            className="primary-button !bg-red"
                            onClick={() => handleDelete(point.id)}
                          >
                            ڕەشکردنەوە
                          </button>
                        </div>
                      </div>
                      <button
                        className="bg-black bg-opacity-50 z-10 fixed top-0 left-0 w-full h-full"
                        onClick={() => handleModal(point.id)}
                      ></button>
                    </>
                  )}
                  {point.id === pointId && (
                    <UpdateDetailPoint data={pointUpdate} id={id} />
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ViewDetailPoints;
